import React from 'react';
import PropTypes from 'prop-types';

import CategoryItem from './CategoryItem';

const CategoryList = ({filters, selectedFilters, onSelectedFilter}) => {
  const handleselectedIds = ids => onSelectedFilter(ids);
  return <>
    {
      filters
        .filter(filter => filter.values.length)
        .map(({key, label, values}, index) => (
          <CategoryItem
            key={index}
            label={label}
            categoryId={key}
            values={values}
            selectedIds={selectedFilters}
            onSelectionChange={handleselectedIds}
          />
        ))
    }
  </>;
};

CategoryList.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      label: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.number,
          label: PropTypes.string
        })
      ),
    })
  ).isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.number),
  onSelectedFilter: PropTypes.func.isRequired,
};

export default CategoryList;
