import React, {useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

//BIKENTA
import DialogPanel from '../../DialogPanel';
import EditGeometryMap from './EditGeometryMap';

//STYLES
const container = {
  height: '60vh',
  margin: 2
};

const EditExecutionGeometryMenu = ({onAcceptFeature, onCancel, ...otherProps}) => {
  const [feature, setFeature] = useState(null);
  const handleModalClose = () => {};
  const handleCancel = () => onCancel();
  const handleSave = () => onAcceptFeature(feature);
  const handleFeatureInput = (feat) => setFeature(feat);

  return <DialogPanel
    isOpen={true}
    label='DEFINIR GEOMETRÍA DE LA EJECUCIÓN PARCIAL'
    fullWidth
    onClose={handleModalClose}
  >
    <Box sx={container}>
      <EditGeometryMap onFeatureInput={handleFeatureInput} {...otherProps} />
    </Box>
    <Box display='flex' flexDirection='row' justifyContent='flex-end' m={2} sx={{gap: 2}}>
      <Button variant='outlined' color='secondary' sx={{color: 'grey.400'}} onClick={handleCancel}>CANCELAR</Button>
      <Button color='secondary' variant={!feature ? 'outlined' : 'contained'} disabled={!feature} onClick={handleSave}>GUARDAR</Button>
    </Box>
  </DialogPanel>;
};

EditExecutionGeometryMenu.propTypes = {
  ...EditGeometryMap.propTypes,
  onAcceptFeature: PropTypes.func,
  onCancel: PropTypes.func
};

export default EditExecutionGeometryMenu;