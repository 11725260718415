import * as React from 'react';

function IcTreeBikenta() {
  return (
    <svg x="0px" y="0px" width="100%" height="100%" viewBox="0 0 572.3 480.733" enableBackground="new 0 0 572.3 480.733">
      <g>
        <g>
          <g>
            <g>
              <rect x="251.813" y="320.489" fill="#CDCCCC" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="320.489" fill="#CDCCCC" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="320.489" fill="#CDCCCC" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="320.489" fill="#696862" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="320.489" fill="#696862" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="343.381" fill="#303220" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="343.381" fill="#36321E" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="343.381" fill="#36321E" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="343.381" fill="#696862" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="343.381" fill="#696862" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="366.272" fill="#2E4029" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="274.704" y="366.272" fill="#36321E" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="297.595" y="366.272" fill="#36321E" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="320.488" y="366.272" fill="#696862" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="343.38" y="366.272" fill="#817F70" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="251.813" y="389.165" fill="#2E4029" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="389.165" fill="#36321E" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="389.165" fill="#36321E" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="389.165" fill="#696862" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="389.165" fill="#817F70" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="412.057" fill="#2E4029" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="274.704" y="412.057" fill="#36321E" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="297.595" y="412.057" fill="#2E4029" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="320.488" y="412.057" fill="#696862" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="343.38" y="412.057" fill="#696862" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="251.813" y="434.949" fill="#2E4029" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="434.949" fill="#36321E" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="434.949" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="434.949" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="434.949" fill="#696862" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="457.841" fill="#80A82C" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="206.027" y="457.841" fill="#35471D" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="228.92" y="457.841" fill="#80A82C" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="251.813" y="457.841" fill="#81B72B" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="274.704" y="457.841" fill="#80A82C" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="297.595" y="457.841" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="320.488" y="457.841" fill="#0F7540" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="343.38" y="457.841" fill="#80A82C" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="366.273" y="457.841" fill="#81B72B" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="389.164" y="457.841" fill="#A7C56C" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="412.056" y="457.841" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="434.949" y="457.841" fill="#E4E7CC" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="320.488" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="457.839" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="22.892" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="22.892" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="22.892" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="22.892" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="45.784" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="45.784" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="45.784" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="45.784" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="45.784" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="480.732" y="45.784" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="503.625" y="45.784" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="68.676" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="68.676" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="68.676" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="68.676" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="68.676" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="68.676" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="457.839" y="68.676" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="91.568" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="91.568" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="91.568" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="91.568" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="91.568" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="480.732" y="91.568" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="503.625" y="91.568" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="114.46" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="114.46" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="114.46" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="114.46" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="114.46" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="114.46" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="114.46" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="457.839" y="114.46" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="526.517" y="114.46" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="137.353" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="137.353" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="137.353" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="137.353" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="137.353" fill="#35471D" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="137.353" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="457.839" y="137.353" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="480.732" y="137.353" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="549.408" y="137.353" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="160.244" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="160.244" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="160.244" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="160.244" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="160.244" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="160.244" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="160.244" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="503.625" y="160.244" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="526.517" y="160.244" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="183.136" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="183.136" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="183.136" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="183.136" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="183.136" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="183.136" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="480.732" y="183.136" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="526.517" y="183.136" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="206.029" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="206.029" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="206.029" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="206.029" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="206.029" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="206.029" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="206.029" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="206.029" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="457.839" y="206.029" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="480.732" y="206.029" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="228.92" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="228.92" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="228.92" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="228.92" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="228.92" fill="#35471D" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="228.92" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="457.839" y="228.92" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="251.813" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="251.813" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="251.813" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="251.813" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="251.813" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="480.732" y="251.813" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="503.625" y="251.813" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="274.705" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="274.705" fill="#35471D" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="274.705" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="297.597" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="366.273" y="297.597" fill="#81B72B" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="389.164" y="297.597" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="434.949" y="297.597" fill="#0F7540" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="457.839" y="297.597" fill="#0F7540" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="366.273" y="320.489" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="320.489" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="22.892" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="22.892" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="22.892" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="22.892" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="45.784" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="45.784" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="45.784" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="45.784" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="45.784" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="45.784" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="45.784" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="68.676" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="68.676" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="68.676" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="68.676" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="68.676" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="68.676" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="68.676" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="160.243" y="91.568" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="91.568" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="91.568" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="91.568" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="91.568" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="91.568" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="91.568" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="91.568" y="114.46" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="114.46" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="114.46" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="114.46" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="114.46" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="114.46" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="114.46" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="114.46" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="114.46" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="91.568" y="137.353" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="137.353" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="137.353" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="137.353" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="137.353" fill="#35471D" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="137.353" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="137.353" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="137.353" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="137.353" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="91.568" y="160.244" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="160.244" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="160.243" y="160.244" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="160.244" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="160.244" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="160.244" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="160.244" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="160.244" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="160.244" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="91.568" y="183.136" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="183.136" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="183.136" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="183.136" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="183.136" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="183.136" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="183.136" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="183.136" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="206.029" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="206.029" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="160.243" y="206.029" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="206.029" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="206.029" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="206.029" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="206.029" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="206.029" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="206.029" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="206.029" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="228.92" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="160.243" y="228.92" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="228.92" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="228.92" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="228.92" fill="#35471D" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="228.92" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="228.92" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="91.568" y="251.813" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="251.813" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="251.813" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="251.813" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="251.813" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="251.813" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="251.813" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="274.705" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="274.705" fill="#35471D" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="274.705" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="297.597" fill="#81B72B" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="228.92" y="297.597" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="251.813" y="297.597" fill="#81B72B" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="297.595" y="297.597" fill="#0F7540" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="320.488" y="297.597" fill="#0F7540" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="228.92" y="320.489" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="320.489" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="320.489" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="320.489" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="320.489" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="297.597" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="389.164" y="297.597" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="343.38" y="297.597" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="320.488" y="297.597" fill="#81B72B" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="480.732" y="274.705" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="274.705" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="274.705" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="274.705" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="274.705" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="274.705" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="274.705" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="503.625" y="251.813" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="503.625" y="228.92" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="480.732" y="251.813" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="251.813" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="251.813" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="251.813" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="251.813" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="251.813" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="457.839" y="228.92" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="228.92" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="228.92" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="228.92" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="228.92" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="228.92" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="228.92" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="526.517" y="206.029" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="503.625" y="206.029" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="480.732" y="206.029" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="206.029" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="206.029" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="206.029" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="206.029" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="206.029" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="206.029" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="526.517" y="183.136" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="480.732" y="183.136" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="183.136" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="183.136" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="183.136" fill="#35471D" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="183.136" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="183.136" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="183.136" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="183.136" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="526.517" y="160.244" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="480.732" y="160.244" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="457.839" y="160.244" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="160.244" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="160.244" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="160.244" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="160.244" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="160.244" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="160.244" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="526.517" y="137.353" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="503.625" y="137.353" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="137.353" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="137.353" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="137.353" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="137.353" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="137.353" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="137.353" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="503.625" y="114.46" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="480.732" y="114.46" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="457.839" y="114.46" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="114.46" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="114.46" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="114.46" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="114.46" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="114.46" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="114.46" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="114.46" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="503.625" y="91.568" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="457.839" y="91.568" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="91.568" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="91.568" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="91.568" fill="#35471D" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="91.568" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="91.568" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="526.517" y="68.676" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="480.732" y="68.676" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="68.676" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="68.676" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="68.676" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="68.676" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="68.676" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="45.784" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="45.784" fill="#35471D" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="45.784" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="22.892" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="22.892" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="22.892" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="22.892" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="22.892" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="320.489" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="320.489" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="160.243" y="320.489" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="297.597" fill="#81B72B" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="251.813" y="297.597" fill="#81B72B" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="206.027" y="297.597" fill="#81B72B" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="183.135" y="297.597" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="343.38" y="274.705" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="274.705" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="274.705" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="274.705" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="274.705" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="274.705" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="274.705" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="251.813" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="251.813" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="251.813" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="251.813" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="251.813" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="251.813" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="160.243" y="251.813" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="228.92" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="228.92" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="228.92" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="228.92" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="228.92" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="228.92" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="228.92" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="206.029" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="206.029" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="206.029" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="206.029" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="206.029" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="206.029" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="206.029" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="160.243" y="206.029" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="91.568" y="206.029" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="183.136" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="183.136" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="183.136" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="183.136" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="183.136" fill="#35471D" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="183.136" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="160.243" y="183.136" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="183.136" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="68.676" y="183.136" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="160.244" fill="#A7C56C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="160.244" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="160.244" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="160.244" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="160.244" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="160.244" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="160.244" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="160.244" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="91.568" y="160.244" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="137.353" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="137.353" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="137.353" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="137.353" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="137.353" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="137.353" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="137.353" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="91.568" y="137.353" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="114.46" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="114.46" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="114.46" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="114.46" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="114.46" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="114.46" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="114.46" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="114.46" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="160.243" y="114.46" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="114.46" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="91.568" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="91.568" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="91.568" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="91.568" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="91.568" fill="#35471D" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="91.568" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="160.243" y="91.568" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="68.676" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="68.676" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="68.676" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="68.676" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="68.676" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="68.676" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="68.676" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="45.784" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="45.784" fill="#35471D" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="45.784" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="22.892" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="22.892" fill="#A7C56C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="22.892" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="22.892" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="160.243" y="22.892" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="68.676" y="160.244" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="160.244" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="160.244" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="91.568" y="183.137" fill="#81B72B" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="114.46" y="183.137" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="160.244" y="183.137" fill="#81B72B" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="183.135" y="183.137" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="22.892" y="206.029" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="68.676" y="206.029" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="91.568" y="206.029" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="206.029" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="160.244" y="206.029" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="206.029" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="206.029" fill="#A7C56C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect y="228.92" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="22.892" y="228.92" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="68.676" y="228.92" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="228.92" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="228.92" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="228.92" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="228.92" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="45.783" y="251.813" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="68.676" y="251.813" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="251.813" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="251.813" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="160.244" y="251.813" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="251.813" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="251.813" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="251.813" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="68.676" y="274.705" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="91.568" y="274.705" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="274.705" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="160.244" y="274.705" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="274.705" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="274.705" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="91.568" y="297.597" fill="#80A82C" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="114.46" y="297.597" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="137.353" y="297.597" fill="#35471D" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="183.135" y="297.597" fill="#0F7540" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="206.027" y="297.597" fill="#0F7540" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="228.92" y="297.597" fill="#80A82C" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="297.595" y="297.597" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="45.783" y="320.489" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="91.568" y="320.489" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="320.489" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="320.489" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="160.244" y="320.489" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="320.489" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="320.489" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="343.381" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="343.381" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="91.568" y="366.272" fill="#81B72B" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="114.46" y="366.272" fill="#0F7540" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="137.353" y="366.272" fill="#80A82C" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="160.244" y="366.272" fill="#81B72B" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="206.027" y="160.244" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="160.244" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="160.244" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="183.137" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="251.813" y="183.137" fill="#81B72B" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="297.595" y="183.137" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="320.488" y="183.137" fill="#81B72B" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="160.244" y="206.029" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="206.029" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="206.029" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="206.029" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="206.029" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="206.029" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="206.029" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="228.92" fill="#E4E7CC" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="457.839" y="251.813" fill="#E4E7CC" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="206.029" fill="#E4E7CC" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="160.244" fill="#E4E7CC" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="160.243" y="137.353" fill="#E4E7CC" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="137.353" fill="#E4E7CC" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="91.568" fill="#E4E7CC" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="45.784" fill="#E4E7CC" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="114.46" fill="#E4E7CC" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="45.784" fill="#E4E7CC" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="434.949" y="160.244" fill="#E4E7CC" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="457.839" y="183.136" fill="#E4E7CC" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="228.921" fill="#E4E7CC" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="320.488" y="274.705" fill="#E4E7CC" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="503.625" y="183.136" fill="#E4E7CC" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="274.705" fill="#E4E7CC" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="320.489" fill="#E4E7CC" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="160.244" y="228.92" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="228.92" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="228.92" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="228.92" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="228.92" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="228.92" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="251.813" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="251.813" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="251.813" fill="#81B72B" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="251.813" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="320.488" y="251.813" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="366.273" y="251.813" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="251.813" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="274.705" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="274.705" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="160.244" y="274.705" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="206.027" y="274.705" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="274.705" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="274.705" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="274.705" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="343.38" y="274.705" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="274.705" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="297.597" fill="#80A82C" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="160.244" y="297.597" fill="#80A82C" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="228.92" y="297.597" fill="#80A82C" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="251.813" y="297.597" fill="#81B72B" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="274.704" y="297.597" fill="#35471D" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="320.488" y="297.597" fill="#0F7540" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="343.38" y="297.597" fill="#0F7540" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="366.273" y="297.597" fill="#80A82C" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="434.949" y="297.597" fill="#81B72B" width="22.891" height="22.893"/>
            </g>
            <g>
              <rect x="114.46" y="320.489" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="160.244" y="320.489" fill="#80A82C" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="183.135" y="320.489" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="228.92" y="320.489" fill="#81B72B" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="251.813" y="320.489" fill="#0F7540" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="274.704" y="320.489" fill="#80A82C" width="22.891" height="22.892"/>
            </g>
            <g>
              <rect x="297.595" y="320.489" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="389.164" y="320.489" fill="#0F7540" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="412.056" y="320.489" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="114.46" y="343.381" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="343.381" fill="#81B72B" width="22.892" height="22.892"/>
            </g>
            <g>
              <rect x="137.353" y="366.272" fill="#80A82C" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="160.244" y="366.272" fill="#81B72B" width="22.892" height="22.893"/>
            </g>
            <g>
              <rect x="457.839" y="343.381" fill="#80A82C" width="22.893" height="22.892"/>
            </g>
            <g>
              <rect x="503.625" y="297.597" fill="#0F7540" width="22.893" height="22.893"/>
            </g>
            <g>
              <rect x="526.517" y="274.705" fill="#0F7540" width="22.891" height="22.892"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IcTreeBikenta;