import React from 'react';
import PropTypes from 'prop-types';

//MUI
import {useTheme} from '@mui/styles';
import {useMediaQuery} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

//BIKENTA
import AccountBook from '../components/AccountBook/AccountBook';

//UTILS
import {MINI_SIDE_PANEL_WIDTH} from '../config';

const AccountBookView = ({
  accountEntries,
  selectedCommunity,
  onAccountEntryAdd,
  onAccountBookClose,
  onAccountEntryDelete,
  onAccountEntryUpdate
}) => {
  const theme = useTheme();
  const widescreen = useMediaQuery(theme.breakpoints.up('lg'), {noSsr: true});
  return <Dialog
    elevation={0}
    fullScreen
    TransitionComponent={Slide}
    sx={{
      top: widescreen ? 62 : 0,
      left: widescreen ? MINI_SIDE_PANEL_WIDTH-2 : 0,
      right: widescreen ? -4 : 0,
      zIndex: 200
    }}
    open={true}>
    <AccountBook
      accountEntries={accountEntries}
      selectedCommunity={selectedCommunity}
      onAccountEntryAdd={onAccountEntryAdd}
      onAccountEntryDelete={onAccountEntryDelete}
      onAccountBookClose={onAccountBookClose}
      onAccountEntryUpdate={onAccountEntryUpdate}
    />
  </Dialog>;
};

AccountBookView.propTypes = {
  accountEntries: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    payment: PropTypes.number.isRequired,
    deposit: PropTypes.number.isRequired
  })),
  selectedCommunity: PropTypes.object,
  onAccountEntryAdd: PropTypes.func,
  onAccountEntryDelete: PropTypes.func,
  onAccountEntryUpdate: PropTypes.func,
  onAccountBookClose: PropTypes.func,
};

export default AccountBookView;