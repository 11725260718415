import DownloadIcon from '@mui/icons-material/Download';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import SectionTitle from '../Layout/SectionTitle';

import SubTitle from '../Layout/SubTitle';
import CommunityDownloadAlert from '../Layout/CommunityDownloadAlert';

const InterventionFilterSidePanel = ({
  isCommunitySelected,
  mainFilters,
  interventionFilters,
  onExportFilterResult
}) => {

  const [isCommunityDownloadDialogOpen, setCommunityDownloadDialogOpen] = useState(false);

  const handleCommunityDownload = () => {
    setCommunityDownloadDialogOpen(true);
    onExportFilterResult();
  };

  return <>
    <SectionTitle title="FILTROS">
      {
        isCommunitySelected ? <DownloadIcon
          color="secondary"
          onClick={handleCommunityDownload}
          sx={{cursor: 'pointer', '&:hover': {color: 'primary.main'}}}
        /> : <></>
      }
    </SectionTitle>
    <CommunityDownloadAlert
      isCommunityDownloadDialogOpen={isCommunityDownloadDialogOpen}
      onClose={() => setCommunityDownloadDialogOpen(false)}
    />
    {
      (mainFilters.length || interventionFilters.length)?
        <>
          {
            mainFilters.length && <>
              <SubTitle title="Generales"/>
              <Stack ml={1}>{mainFilters}</Stack>
            </>
          }
          {
            interventionFilters.length ? <>
              <SubTitle title="Intervenciones"/>
              <Stack ml={1}>{interventionFilters}</Stack>
            </> : undefined
          }
        </> :
        <Typography sx={{color: 'error.main'}}>No existe ninguna comunidad con los filtros aplicados.</Typography>
    }
  </>;
};

InterventionFilterSidePanel.propTypes = {
  isCommunitySelected: PropTypes.bool.isRequired,
  mainFilters: PropTypes.arrayOf(PropTypes.element),
  interventionFilters: PropTypes.arrayOf(PropTypes.element),
  onExportFilterResult: PropTypes.func,
};

InterventionFilterSidePanel.defaultProps = {};

export default InterventionFilterSidePanel;