import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';

//MUI
import {useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/styles';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SaveIcon from '@mui/icons-material/Save';

//BIKENTA
import AnnualPlanDownloadAlert from './AnnualPlanDownloadAlert';
import AnnualPlanInProcedureAlert from './AnnualPlanInProcedureAlert';
import AnnualPlanCompleteAlert from './AnnualPlanCompleteAlert';
import SectionTitle from '../Layout/SectionTitle';

//GEOCOMPONENTS
import BottomSheet from '@geomatico/geocomponents/BottomSheet';

//UTILS
import {labelStyle, valueStyle} from '../Cards/InterventionZoneCard';
import {annualPlanSummary} from '../proptypes/annualPlanSummary';
import {annualPlan} from '../proptypes/annualPlan';
import {ANNUAL_PLAN_STATUS, ROLES_LABEL, VIEW_PERMISSIONS} from '../../config';
import round from '../../utils/round';
import AlertDeleteDialog from '../AlertDeleteDialog';
import {hasViewPermissions} from '../../modules/useCases';

const annualPlanActionsSx = {
  mt: 2,
  flexGrow: 1,
  '&.Mui-disabled': {
    color: '#fff'
  }
};

const container = {
  position: 'relative',
  height: '40vh'
};

const AnnualPlanDetails = ({
  annualPlanSummary,
  role,
  isOffline,
  isAnnualPlanDetailsDialogOpen,
  selectedAnnualPlan,
  onAnnualPlanDelete,
  onAnnualPlanComplete,
  onAnnualPlanDownload,
  onAnnualPlanInProcedure,
  onAnnualPlanSave,
  onAnnualPlanDetailsDialogClose
}) => {
  const theme = useTheme();
  const widescreen = useMediaQuery(theme.breakpoints.up('lg'), {noSsr: true});
  const [isAnnualPlanCompleteMenuOpen, setAnnualPlanCompleteMenuOpen] = useState(false);
  const [isAnnualPlanInProcedureMenuOpen, setAnnualPlanInProcedureMenuOpen] = useState(false);
  const [isAnnualPlanDeleteDialogOpen, setAnnualPlanDeleteDialogOpen] = useState(false);
  const [isAnnualPlanDownloadDialogOpen, setAnnualPlanDownloadDialogOpen] = useState(false);
  const handleAnnualPlanCompleteMenuOpen = () => setAnnualPlanCompleteMenuOpen(true);
  const handleAnnualPlanInProcedureMenuOpen = () => setAnnualPlanInProcedureMenuOpen(true);
  const handleAnnualPlanCompleteCancel = () => setAnnualPlanCompleteMenuOpen(false);
  const handleAnnualPlanInProcedureCancel = () => setAnnualPlanInProcedureMenuOpen(false);
  const handleAnnualPlanComplete = () => {
    onAnnualPlanComplete(selectedAnnualPlan.id);
    setAnnualPlanCompleteMenuOpen(false);
  };

  const handleAnnualPlanInProcedure = () => {
    onAnnualPlanInProcedure(selectedAnnualPlan.id);
    setAnnualPlanInProcedureMenuOpen(false);
  };

  const handleAnnualPlanDeleteDialogOpen = () => setAnnualPlanDeleteDialogOpen(true);

  const handleAnnualPlanDelete = () => {
    onAnnualPlanDelete(selectedAnnualPlan.id);
    setAnnualPlanDeleteDialogOpen(false);
  };

  const handleAnnualPlanDownload = () => {
    setAnnualPlanDownloadDialogOpen(true);
    onAnnualPlanDownload(selectedAnnualPlan.id);
  };

  const annualPlanDetailsContent = useMemo(() => (
    <>
      <Stack direction="row" alignItems="center">
        <Typography sx={labelStyle}>Nº de intervenciones:</Typography>
        <Typography sx={valueStyle}>{annualPlanSummary.interventions}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Typography sx={labelStyle}>Nº de intervenciones ejecutadas:</Typography>
        <Typography sx={valueStyle}>{annualPlanSummary.done}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Typography sx={labelStyle}>Valor de inversión:</Typography>
        <Typography sx={valueStyle}>{round(annualPlanSummary.planned_invest)} €</Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Typography sx={labelStyle}>Valor de retorno:</Typography>
        <Typography sx={valueStyle}>{round(annualPlanSummary.planned_income)} €</Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Typography sx={labelStyle}>Valor de inversión ejecutada:</Typography>
        <Typography sx={valueStyle}>{round(annualPlanSummary.executed_invest)} €</Typography>
      </Stack><Stack direction="row" alignItems="center">
        <Typography sx={labelStyle}>Valor retorno ejecutada:</Typography>
        <Typography sx={valueStyle}>{round(annualPlanSummary.executed_income)} €</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" flexWrap='noWrap' sx={{gap: 1}}>
        <Button variant="contained" color="primary" sx={annualPlanActionsSx} onClick={onAnnualPlanSave}
          startIcon={
            isOffline || !widescreen || !hasViewPermissions(VIEW_PERMISSIONS.EDIT_ANNUAL_PLAN, role)
              ? <ContentPasteGoIcon/>
              : selectedAnnualPlan.status !== ANNUAL_PLAN_STATUS.COMPLETED
                ? <SaveIcon/>
                : <ContentPasteGoIcon/>
          }
        >
          {
            /*si no hay red, no es móvil, y no tiene permiso para editar Planes Anuales --> BOTÓN SALIR*/
            isOffline || !widescreen || !hasViewPermissions(VIEW_PERMISSIONS.EDIT_ANNUAL_PLAN, role)
              ? 'SALIR'
              : selectedAnnualPlan.status !== ANNUAL_PLAN_STATUS.COMPLETED
                ? 'GUARDAR'
                : 'SALIR'
          }
        </Button>
        {
          /*si no es móvil, está en ejecución y tiene permiso para editar Planes Anuales --> BOTÓN FINALIZAR PLAN ANUAL*/
          widescreen && selectedAnnualPlan.status === ANNUAL_PLAN_STATUS.IN_PROCEDURE && hasViewPermissions(VIEW_PERMISSIONS.EDIT_ANNUAL_PLAN, role) &&
          <Button variant="contained" color="secondary" onClick={handleAnnualPlanCompleteMenuOpen} disabled={isOffline}
            startIcon={<CheckCircleIcon/>} sx={annualPlanActionsSx}>
              FINALIZAR PLAN ANUAL
          </Button>
        }
        {
          widescreen && selectedAnnualPlan.status === ANNUAL_PLAN_STATUS.DRAFT && hasViewPermissions(VIEW_PERMISSIONS.EDIT_ANNUAL_PLAN, role) && <>
            <Button variant="contained" color="primary" onClick={handleAnnualPlanDeleteDialogOpen} disabled={isOffline}
              startIcon={<DeleteIcon/>} sx={annualPlanActionsSx}>
                ELIMINAR
            </Button>
            <Button variant="contained" color="secondary" onClick={handleAnnualPlanInProcedureMenuOpen} disabled={isOffline}
              startIcon={<PlayCircleOutlineIcon/>} sx={annualPlanActionsSx}>
                PASAR A EJECUCIÓN
            </Button>
          </>
        }
      </Stack>
    </>
  ), [annualPlanSummary, widescreen, selectedAnnualPlan, isOffline]);

  const annualDetailsTitle = `DETALLES PLAN ANUAL ${selectedAnnualPlan.year}`;

  return <>
    <Hidden lgDown>{/*DESKTOP*/}
      <SectionTitle title={annualDetailsTitle}>
        <Button variant="text" startIcon={<FileDownloadIcon/>} onClick={handleAnnualPlanDownload}>DESCARGAR</Button>
      </SectionTitle>
      {annualPlanDetailsContent}
      <AnnualPlanCompleteAlert
        isOpenCompleteMenu={isAnnualPlanCompleteMenuOpen}
        onCancelAnnualPlan={handleAnnualPlanCompleteCancel}
        onClose={handleAnnualPlanCompleteCancel}
        onAnnualPlanComplete={handleAnnualPlanComplete}
      />
      <AnnualPlanInProcedureAlert
        isOpenInProcedureMenu={isAnnualPlanInProcedureMenuOpen}
        onCancelAnnualPlan={handleAnnualPlanInProcedureCancel}
        onClose={handleAnnualPlanInProcedureCancel}
        onAnnualPlanInProcedure={handleAnnualPlanInProcedure}
      />
      <AnnualPlanDownloadAlert
        selectedAnnualPlan={selectedAnnualPlan}
        isAnnualPlanDownloadDialogOpen={isAnnualPlanDownloadDialogOpen}
        onClose={() => setAnnualPlanDownloadDialogOpen(false)}
      />
      <AlertDeleteDialog
        isOpen={isAnnualPlanDeleteDialogOpen}
        title='Eliminar Plan Anual'
        description='No se podrá recuperar el Plan Anual, ¿Estás seguro?'
        onCancel={() => setAnnualPlanDeleteDialogOpen(false)}
        onAccept={handleAnnualPlanDelete}
      />
    </Hidden>
    <Hidden lgUp>{/*MOBILE*/}
      <BottomSheet
        closedHeight={120}
        openHeight={'80vh'}
        onToggle={onAnnualPlanDetailsDialogClose && onAnnualPlanSave}
        isOpen={isAnnualPlanDetailsDialogOpen}
      >
        <Box sx={container}>
          <Typography sx={{color: 'secondary.main', fontWeight: 'bold'}}>{annualDetailsTitle}</Typography>
          <Divider sx={{borderColor: 'secondary.main', bgcolor: 'secondary.main', borderWidth: 1, mb: 2}}/>
          {annualPlanDetailsContent}
        </Box>
      </BottomSheet>
    </Hidden>
  </>;
};

AnnualPlanDetails.propTypes = {
  annualPlanSummary: annualPlanSummary,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)).isRequired,
  isOffline: PropTypes.bool,
  isAnnualPlanDetailsDialogOpen: PropTypes.bool,
  selectedAnnualPlan: annualPlan,
  onAnnualPlanComplete: PropTypes.func,
  onAnnualPlanDelete: PropTypes.func,
  onAnnualPlanDownload: PropTypes.func,
  onAnnualPlanInProcedure: PropTypes.func,
  onAnnualPlanSave: PropTypes.func,
  onAnnualPlanDetailsDialogClose: PropTypes.func,
};

export default AnnualPlanDetails;