import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import round from '../../utils/round';

//STYLES
const HEIGHT = 240;
const WIDTH = 30;
const INVEST_COLOR = '#fc5252';
const INCOME_COLOR = '#69bdaf';

const BarChart = ({label, height, color}) => {
  return <Stack direction='column' alignItems='center' sx={{mr: 1}}>
    <Typography variant='body2' gutterBottom>{label}</Typography>
    <Box sx={{width: WIDTH, bgcolor: color, height: height}}/>
  </Stack>;
};

const IndicatorMoney = ({data}) => {
  const higherValue = Math.max(...Object.values(data));
  const getSize = (param) => (param*HEIGHT)/higherValue;

  return <Card sx={{height: 325, minWidth: 350, flexGrow: 1, maxWidth: { xs: '100%', lg: 350}, mb: 2}}>
    <Stack direction='row' justifyContent='space-around' alignItems='flex-end' sx={{height: '100%', gap:4, py: 2}}>
      <Stack directon='column' alignItems='center'>
        <Stack direction='row' alignItems='flex-end'>{/*flex-end hace que la barra salga desde abajo*/}
          <BarChart label={`${round(data.plannedInvest)} €`} height={getSize(data.plannedInvest)} color={INVEST_COLOR}/>
          <BarChart label={`${round(data.plannedIncome)} €`} height={getSize(data.plannedIncome)} color={INCOME_COLOR}/>
        </Stack>
        <Typography variant='body2' sx={{mt:1, fontWeight: 'bolder'}}>PLANEADO</Typography>
      </Stack>
      <Stack directon='column' alignItems='center'>
        <Stack direction='row' alignItems='flex-end'>{/*flex-end hace que la barra salga desde abajo*/}
          <BarChart label={`${round(data.executedInvest)} €`} height={getSize(data.executedInvest)} color={INVEST_COLOR}/>
          <BarChart label={`${round(data.executedIncome)} €`} height={getSize(data.executedIncome)} color={INCOME_COLOR}/>
        </Stack>
        <Typography variant='body2' sx={{mt:1, fontWeight: 'bolder'}}>EJECUTADO</Typography>
      </Stack>
    </Stack>
  </Card>;
};

IndicatorMoney.propTypes = {
  data: PropTypes.shape({
    plannedInvest: PropTypes.number.isRequired,
    plannedIncome: PropTypes.number.isRequired,
    executedInvest: PropTypes.number.isRequired,
    executedIncome: PropTypes.number.isRequired
  })
};

BarChart.propTypes = {
  label: PropTypes.string,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default IndicatorMoney;