import {handleActions} from 'redux-actions';

import ActionTypes, {activateOffline} from './actions';
import {apiActionTypes} from './modules/api/actions';
import {MAP_STYLES} from './config';
import {getActiveFilters} from './services/localStorage';

const savedActivedFilters = getActiveFilters();

export const initialState = {
  selectedCommunity: undefined,
  carouselCommunityId: undefined,
  selectedInterventionId: undefined,
  selectedInterventionZone: undefined,
  carouselInterventionZoneId: undefined,
  selectedAnnualPlan: undefined,
  activeFilters: savedActivedFilters ? savedActivedFilters : [],
  searchedText: '',
  i18n: 'es',
  offline: false,
  loading: 0,
  map: {
    viewport: {
      width: window.innerWidth,
      height: window.innerHeight,
      latitude: 42.75,
      longitude: -7.38,
      zoom: 6,
      bearing: 0,
      pitch: 0
    },
    basemap: MAP_STYLES[1].id
  },
  fileDetail: {}
};

const updateMapViewport = (state, action) => ({
  ...state,
  map: {
    ...state.map,
    viewport: {
      ...state.map.viewport,
      ...action.payload
    }
  }
});

const setBaseMap = (state, {payload}) => ({
  ...state,
  map: {
    ...state.map,
    basemap: payload
  }
});

const setSelectedCommunity = (state, {payload}) => {
  return {
    ...state,
    selectedCommunity: payload,
    selectedInterventionZone: undefined,
    carouselInterventionZoneId: undefined,
    selectedInterventionId: undefined
  };
};

const setSelectedCommunityOnCarousel = (state, action) => ({
  ...state,
  carouselCommunityId: action.payload
});

const setSelectedInterventionZone = (state, action) => ({
  ...state,
  selectedInterventionZone: action.payload,
  carouselInterventionZoneId: action.payload || state.carouselInterventionZoneId,
  selectedInterventionId: undefined
});

const setSelectedInterventionZoneOnCarousel = (state, action) => ({
  ...state,
  carouselInterventionZoneId: action.payload
});

const setActiveFilters = (state, {payload}) => ({
  ...state,
  activeFilters: (payload || {...initialState.activeFilters})
});

const setActiveCategoryFilters = (state, {payload}) => ({
  ...state,
  activeFilters:[
    ...state.activeFilters.filter(filter => filter.key !== 'categories'),
    (payload && {
      'key': 'categories',
      'values': payload
    })
  ]
});

const setSelectedIntervention = (state, action) => ({
  ...state,
  selectedInterventionId: action.payload,
});

//ANNUAL PLANS
const setSelectedAnnualPlan = (state, {payload}) => ({
  ...state,
  selectedAnnualPlan: payload,
});

const resetSelectedAnnualPlan = (state) => {
  return {
    ...state,
    selectedAnnualPlan: undefined
  };
};

//LOADING
const setLoading = (state) => {
  return {
    ...state,
    loading: state.loading + 1
  };
};

const unSetLoading = (state) => {
  return {
    ...state,
    loading: state.loading ? state.loading -1 : 0
  };
};

const setOfflineStatus = (state, action) => ({
  ...state,
  offline: action.type === activateOffline().type
});


//SEARCH BOX
const updateSearchedText = (state, {payload}) => {
  return {
    ...state,
    searchedText: payload,
  };
};

//COMMUNITY FILES

const setFileDetail = (state, {payload}) => {
  return {
    ...state,
    fileDetail: payload,
  };
};


const reducer = handleActions({
  [apiActionTypes.API_CALL_STARTED]: setLoading,
  [apiActionTypes.API_CALL_ENDED]: unSetLoading,

  [ActionTypes.MOVE_MAP]: updateMapViewport,
  [ActionTypes.SET_BASEMAP]: setBaseMap,

  [ActionTypes.SELECT_COMMUNITY]: setSelectedCommunity,
  [ActionTypes.SELECT_COMMUNITY_ON_CAROUSEL]: setSelectedCommunityOnCarousel,

  [ActionTypes.SELECT_INTERVENTION_ZONE]: setSelectedInterventionZone,
  [ActionTypes.SELECT_INTERVENTION_ZONE_ON_CAROUSEL]: setSelectedInterventionZoneOnCarousel,

  [ActionTypes.FILTERS_ACTIVED]: setActiveFilters,
  [ActionTypes.CATEGORY_FILTERS_ACTIVED]: setActiveCategoryFilters,

  [ActionTypes.SELECT_INTERVENTION]: setSelectedIntervention,

  [apiActionTypes.API_GET_FILE_DETAIL_SUCCESS]: setFileDetail,

  [ActionTypes.SELECT_ANNUAL_PLAN]: setSelectedAnnualPlan,
  [ActionTypes.RESET_SELECTED_ANNUAL_PLAN]: resetSelectedAnnualPlan,

  [apiActionTypes.API_CREATE_ANNUAL_PLAN_SUCCESS]: setSelectedAnnualPlan,
  [apiActionTypes.API_DELETE_ANNUAL_PLAN_SUCCESS]: resetSelectedAnnualPlan,
  [apiActionTypes.API_UPDATE_INTERVENTION_ANNUAL_PLAN_SUCCESS]: setSelectedAnnualPlan,
  [apiActionTypes.API_COMPLETE_ANNUAL_PLAN_SUCCESS]: resetSelectedAnnualPlan,
  [apiActionTypes.API_IN_PROCEDURE_ANNUAL_PLAN_SUCCESS]: resetSelectedAnnualPlan,

  [ActionTypes.OFFLINE_ACTIVATE]: setOfflineStatus,
  [ActionTypes.OFFLINE_DEACTIVATE]: setOfflineStatus,

  [ActionTypes.UPDATE_SEARCHED_TEXT]: updateSearchedText,

  [apiActionTypes.API_GET_COMMUNITY_DETAILS_SUCCESS]: setSelectedCommunity,

}, initialState);

export default reducer;
