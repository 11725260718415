import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import LoginForm from './LoginForm';

import {apiLogin, apiLoginSuccess} from '../../modules/api/actions';
import {getUsername, isLoggedIn} from '../../modules/api/selectors';
import {getCredentials as getStorageCredentials} from '../../services/localStorage';
import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';

export const PureLogin = ({
  isLoggedIn,
  initialUsername,
  loginErrorMessage,
  onApiLogin,
  onApiLoginSuccess,

}) => {
  const [username, setUsername] = useState(initialUsername);
  const [password, setPassword] = useState('');

  let {from} = location.state || {from: {pathname: '/'}};

  let history = useHistory();

  // When mounting, if username and token are available on localStorage, we use them to emulate a successful login
  useEffect(() => {
    const {username, token} = getStorageCredentials();
    if (username && token) {
      setUsername(username);
      onApiLoginSuccess({username, token});
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      history.replace(from);
    }
  }, [isLoggedIn]);

  const handleLogin = () => {
    onApiLogin({username, password});
    setPassword('');
  };
  const container = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
  };
  const login = {
    padding: 5,
    width: {
      xs: '80vw',
      md: '50vw',
      lg: '40vw'
    },
    display: 'flex',
    justifyContent: 'center'
  };
  const ImgLogo = styled('img')({
    marginTop: '10vh',
    maxWidth: '800px',
    marginBottom: '5vh',
  });
  const imgFooter = {
    height: {
      xs: '200px',
      md: '150px',
      lg: '100px'
    },
    backgroundImage: 'url(\'assets/img_tree@1x.svg\')',
    backgroundRepeat: 'repeat-x',
    position: 'relative',
    width: '100vw',
  };
  return isLoggedIn ? null :
    (
      <Box sx={container}>
        <ImgLogo src='assets/logo-bikenta-login@1x.svg' alt='Logo Bikenta' width='400px'/>
        <Paper sx={login} elevation={10}>
          <LoginForm
            username={username}
            password={password}
            onUsernameChanged={setUsername}
            onPasswordChanged={setPassword}
            onLoginButtonClicked={handleLogin}
            loginErrorMessage={loginErrorMessage}
          />
        </Paper>
        <Box sx={imgFooter}/>
      </Box>
    );
};

PureLogin.propTypes = {
  isLoggedIn: PropTypes.bool,
  initialUsername: PropTypes.string,
  loginErrorMessage: PropTypes.string,
  onApiLogin: PropTypes.func.isRequired,
  onApiLoginSuccess: PropTypes.func.isRequired,
};

PureLogin.defaultProps = {
  isLoggedIn: false,
  initialUsername: '',
  loginErrorMessage: ''
};

const stateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
  initialUsername: getUsername(state) || '',
  loginErrorMessage: state.api.loginError,
});

const actionCreators = {
  onApiLogin: apiLogin,
  onApiLoginSuccess: apiLoginSuccess,
};

export default connect(stateToProps, actionCreators)(PureLogin);