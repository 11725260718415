import React from 'react';
import PropTypes from 'prop-types';

import {styled} from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import {grey} from '@mui/material/colors';

const Image = styled('img',
  {shouldForwardProp: (prop) => prop !== 'thumbnailHeight'}
)(({thumbnailHeight}) => ({
  aspectRatio: '4/3',
  height: thumbnailHeight,
  objectFit: 'cover',
}));

const Thumbnail = ({thumbnail_url, id, name, content_type, thumbnailHeight, isDeletable, isLoading, onDelete, onDownloadFile}) => {
  const defaultImage = 'assets/defaultImage.jpg';
  const spinner = {
    bgcolor: 'primary.main',
    borderRadius: '100%',
    color: grey[50],
    padding: '2px',
    border: `2px solid ${grey[50]}`,
    boxSizing: 'content-box',
    zIndex: 20000,
    position: 'relative',
    left: -8,
    top: -8,
  };
  const deleteIcon = {
    color: grey[50],
    height: 18,
    width: 18,
    position: 'relative',
    left: -8,
    top: -8,
    border: `2px solid ${grey[50]}`,
    backgroundColor: 'primary.main',
    zIndex: 2000,
    '&:hover': {
      backgroundColor: grey[50],
      color: 'primary.main',
    }
  };

  return <Box display='flex' alignItems='flex-start' mt={1} thumbnailHeight={thumbnailHeight}>
    <Image
      alt={name}
      height={thumbnailHeight}
      src={isLoading ? defaultImage : thumbnail_url}
      onClick={() => onDownloadFile(id, content_type)}
    />
    {
      isLoading ?
        <CircularProgress size={10} sx={spinner}/> :
        isDeletable ?
          <IconButton size='small' onClick={() => onDelete(id)} sx={deleteIcon}>
            <CloseIcon style={{fontSize: 14}} color='inherit'/>
          </IconButton>
          : undefined
    }
  </Box>;
};

Thumbnail.propTypes = {
  thumbnailHeight: PropTypes.number,
  thumbnail_url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  content_type: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isDeletable: PropTypes.bool,
  id: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDownloadFile: PropTypes.func.isRequired
};

Thumbnail.defaultProps = {
  thumbNailHeight: 60,
  isLoading: false,
  isDeletable: false,
};

export default Thumbnail;