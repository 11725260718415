import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputNumber from '../../AddInterventionMenu/InputNumber';

const StartEndYear = ({executed_start_year, executed_end_year, isOffline, onStartYearChange, onEndYearChange, disabled}) => {

  const subTitleColor = (isOffline || disabled) ? 'grey.500' : 'secondary';

  const currentYear = new Date().getFullYear();
  const maxYear = currentYear + 30;
  const minYear = currentYear - 30;

  const validYears = () => Number(executed_end_year) >= Number(executed_start_year);

  return <Box display='flex' flexDirection='column'>
    <Typography variant='overline' component='p' color={subTitleColor}
      sx={{fontWeight: 'bolder', ml: 0.5}}>FECHAS</Typography>
    <Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='flex-end' ml={2} mb={1}>
      <InputNumber label='Inicio' max={maxYear} min={minYear} disabled={isOffline || disabled}
        value={Number(executed_start_year)}
        onChange={(value) => onStartYearChange({executed_start_year: value.toString()})}
      />
      <InputNumber label='Fin' max={maxYear} min={minYear} disabled={isOffline || disabled}
        value={Number(executed_end_year)}
        onChange={(value) => onEndYearChange({executed_end_year: value.toString()})}
      />
      {
        !disabled && !validYears() && <Typography variant='caption' color='error'>
          La fecha de inicio no puede ser mayor que la fecha de fin.
        </Typography>
      }
    </Box>
  </Box>;
};

StartEndYear.propTypes = {
  executed_start_year: PropTypes.string.isRequired,
  executed_end_year: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool,
  onStartYearChange: PropTypes.func,
  onEndYearChange: PropTypes.func,
};

export default StartEndYear;