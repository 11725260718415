import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const SectionTitle = ({title, children, sx}) => {
  return <Box mt={0} mb={1} sx={sx}>
    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
      <Typography
        variant="subtitle1"
        sx={{fontWeight: 'bold', color: 'secondary.main'}}
      >
        {title}
      </Typography>
      {children}
    </Box>
    <Divider sx={{borderColor: 'secondary.main', bgcolor: 'secondary.main', borderWidth: 1}}/>
  </Box>;
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element,
  sx: PropTypes.object
};

export default SectionTitle;