import React, {useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

//UTILS
import {getIconByCategory, hasEditPermissions} from '../../../modules/useCases';
import {intervention} from '../../proptypes/intervention';
import {PRIORITIES, ROLES_LABEL} from '../../../config';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import {styled} from '@mui/styles';
import Divider from '@mui/material/Divider';
import grey from '@mui/material/colors/grey';
import {categoryFilters} from '../../proptypes/categoryFilters';
import Categories from '../../icons/Categories';

//STYLES
const titleStyle = {
  fontWeight: 'bold'
};

const interventionLabelSx = {
  ...titleStyle,
  mt: 0.5
};

const iconSx = {
  '&.MuiSvgIcon-root': {
    color: 'white'
  }
};

const categoryIconContainer = {
  width: 64,
};

const CustomPopper = styled('div')(() => ({
  marginTop: '52px',
  position: 'absolute',
  zIndex: 100000
}));

const InterventionHeader = ({intervention, categoryFilters, batches, role, isOffline, multiselect, onAlertDeleteIntervention, onUpdateIntervention}) => {

  const getBatchObject = (intervention) => intervention && intervention.batch;

  const subTitleColor = isOffline ? 'grey.500' : 'secondary';

  const [editingIntervention, setEditingIntervention] = useState(intervention);

  const handleBatchChange = (e, value) => {
    setEditingIntervention({
      ...editingIntervention,
      batch: value
    });
    if(value) {
      onUpdateIntervention({batch: value.id});
    } else {
      onUpdateIntervention({batch: null});
    }
  };

  const handleCommentsChange = (value) => {
    setEditingIntervention({
      ...editingIntervention,
      comments: value
    });
  };

  const handleCommentsUpdate = () => onUpdateIntervention({comments: editingIntervention.comments});

  const priority = intervention.priority && PRIORITIES.find(priority => priority.id === intervention.priority);

  //si una es true el resultado es true
  const isBatchInputDisabledGeneral = isOffline || !(intervention.categories?.includes(1) || intervention.categories.includes(2));
  {/*INFO------------------------------------------------------------------------------------------------------------*/}
  return <Box display='flex' flexDirection='column' flexWrap='noWrap' justifyContent='space-between' ml={0.5} >
    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
      <Box display='flex' flexDirection='row' alignItems='flex-start' sx={{maxWidth: '70%'}}>
        {
          intervention.categories[0] && <Box sx={categoryIconContainer}>
            <Categories categoryId={getIconByCategory(categoryFilters, intervention.categories[0])}/>
          </Box>
        }
        <Box display='flex' flexDirection='column' justifyContent='flex-start' alignContent='start' ml={intervention.categories[0] ? 2 : 0}>

          <Typography sx={titleStyle} color='textPrimary'>
            {intervention.type.name.toUpperCase()}
          </Typography>
          {
            !multiselect && <Typography variant='body2' sx={{color: grey[700]}} gutterBottom>GEOUA: {intervention.geoua}</Typography>
          }
        </Box>
      </Box>

      {!multiselect && 
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Typography sx={interventionLabelSx} color='textSecondary'>
            {intervention.is_annual_planned ? '(Planificada)' : '(No planificada)'}
          </Typography>
          {
            intervention.priority && <Chip
              size='small'
              label={priority.label}
              avatar={
                priority.id === 1 ? <KeyboardDoubleArrowUpIcon sx={iconSx}/> :
                  priority.id === 3 ? <KeyboardDoubleArrowDownIcon sx={iconSx}/> :
                    undefined
              }
              sx={{maxWidth: 100, bgcolor: priority.color, fontWeight: 700, color: 'common.white', ml: 1}}
            />

          }
          {
            hasEditPermissions(role) && !intervention.is_annual_planned &&
          <IconButton size='medium' onClick={onAlertDeleteIntervention} sx={{color: 'grey.400'}} disabled={isOffline}>
            <DeleteIcon color='inherit'/>
          </IconButton>
          }
        </Box>
      }
    </Box>
    
    <Divider sx={{my: 1}}/>

    {/*COMMENTS------------------------------------------------------------------------------------------------------*/}
    <Typography variant='overline' component='p' color={subTitleColor} sx={{fontWeight: 'bolder', mb: 0.5, ml: 0.5}}>COMENTARIOS</Typography>
    <TextField
      inputProps={{ onBlur: handleCommentsUpdate }}
      sx={{px: 1}}
      fullWidth
      multiline
      rows={2}
      type='text'
      InputLabelProps={{shrink: true}}
      variant='outlined'
      value={editingIntervention.comments}
      onChange={(e) => handleCommentsChange(e.target.value)}
      disabled={isOffline}
    />

    {/*LOTE----------------------------------------------------------------------------------------------------------*/}
    {
      !(intervention.never_do || editingIntervention.never_do) && <>
        <Typography variant="overline" component='p' color={isBatchInputDisabledGeneral ? 'grey.500' : 'secondary'} sx={{fontWeight: 'bolder', mt: 1, mb: 0.5, ml: 0.5}}>
          LOTE {isBatchInputDisabledGeneral}
        </Typography>
        <Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='center' mb={2}>
          <FormControl fullWidth sx={{px: 1}}>
            <Autocomplete
              sx={{position: 'relative'}}
              defaultValue={getBatchObject(editingIntervention)}
              disabled={isBatchInputDisabledGeneral}
              PopperComponent={CustomPopper}
              getOptionLabel={(option) => {
                return option.name;
              }}
              options={batches}
              onChange={(e, value) => handleBatchChange(e, value)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )
              }
            />
          </FormControl>
        </Box>
      </>
    }
  </Box>;
};

InterventionHeader.propTypes = {
  batches: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.number, name: PropTypes.string })),
  categoryFilters: categoryFilters,
  isOffline: PropTypes.bool,
  intervention: intervention,
  multiselect: PropTypes.bool.isRequired,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)),
  onAlertDeleteIntervention: PropTypes.func,
  onUpdateIntervention: PropTypes.func.isRequired
};

InterventionHeader.defaultProps = {
};

export default InterventionHeader;

