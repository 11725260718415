import {BUSINESS_CATEGORIES, CATEGORY_GROUPS, MAP_DETAIL_STYLES, MAP_DETAILS} from '../config';

const layers_id_selector = ['get', 'id'];

const baseLayers = [
  {
    'id': 'community',
    'type': 'line',
    'source': 'xorfor',
    'source-layer': 'community',
    'paint': {
      'line-color': 'rgb(0,0,0)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 13, 2, 16, 5]
    }
  }
];

const layerConstructorMaderaPlus = [{
  'id': 'maderaPlusParams',
  'type': 'raster',
  'source': 'maderaPlusParams',
  'paint': {
    'raster-opacity': 0.5
  }
}];

const normalLayers = [{
  'id': 'interventionZone',
  'type': 'fill',
  'source': 'xorfor',
  'source-layer': 'interventionzone',
  'paint': {
    'fill-color': 'rgba(230, 7, 7, 1)',
    'fill-opacity': 0.5
  },
  'layout': {'visibility': 'visible'}
},
{
  'id': 'interventionZone-line',
  'type': 'line',
  'source': 'xorfor',
  'source-layer': 'interventionzone',
  'paint': {
    'line-color': 'rgba(0, 0, 0, 1)',
    'line-width': ['interpolate', ['linear'], ['zoom'], 13, 1, 15, 2, 16, 3]
  },
  'layout': {'visibility': 'visible'}
}];
const baseInterventionZonesLayers = [
  {
    'id': 'interventionZone',
    'type': 'fill',
    'source': 'xorfor',
    'source-layer': 'interventionzone',
    'paint': {
      'fill-color': 'rgb(6,56,182)',
      'fill-opacity': 0.2
    },
    'layout': {'visibility': 'visible'}
  },
  {
    'id': 'interventionZone-line',
    'type': 'line',
    'source': 'xorfor',
    'source-layer': 'interventionzone',
    'paint': {
      'line-color': 'rgba(0, 0, 0, 1)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 13, 1, 15, 2, 16, 3]
    },
    'layout': {'visibility': 'visible'}
  }];
const selectionLayers = [
  {
    'id': 'interventionZone-selected',
    'type': 'fill',
    'source': 'xorfor',
    'source-layer': 'interventionzone',
    'paint': {
      'fill-color': 'rgba(230, 7, 7, 1)',
      'fill-opacity': 0.5
    },
    'layout': {'visibility': 'visible'}
  },
  {
    'id': 'interventionZone-line-selected',
    'type': 'line',
    'source': 'xorfor',
    'source-layer': 'interventionzone',
    'paint': {
      'line-color': 'rgb(241,213,14)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 13, 3, 16, 5]
    },
    'layout': {'visibility': 'visible'}
  }];

const selectionLayersInMode = [
  {
    'id': 'interventionZone-selected-inMode',
    'type': 'fill',
    'source': 'xorfor',
    'source-layer': 'interventionzone',
    'paint': {
      'fill-color': 'rgb(6,56,182)',
      'fill-opacity': 0.5
    },
    'layout': {'visibility': 'visible'}
  },
  {
    'id': 'interventionZone-line-selected-inMode',
    'type': 'line',
    'source': 'xorfor',
    'source-layer': 'interventionzone',
    'paint': {
      'line-color': 'rgb(241,213,14)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 13, 3, 16, 5]
    },
    'layout': {'visibility': 'visible'}
  }];

const preselectionLayers = [{
  'id': 'interventionZone-preselected-line',
  'type': 'line',
  'source': 'xorfor',
  'source-layer': 'interventionzone',
  'paint': {
    'line-color': 'rgb(241,213,14)',
    'line-width': ['interpolate', ['linear'], ['zoom'], 13, 3, 16, 5]
  },
  'layout': {'visibility': 'visible'}
}];

//ESPECIES
const especiesColors = MAP_DETAIL_STYLES.find(map => map.id === MAP_DETAILS.especies).colors;
const especiesFillColors = [
  ['==', ['get', 'has_others'], 1], especiesColors.find(especie => especie.id === 999).paint.color,
  ['==', ['get', 'has_raso'], 1], especiesColors.find(especie => especie.id === 0).paint.color,
  ['==', ['get', 'gender_id'], 1], especiesColors.find(especie => especie.id === 1).paint.color,
  ['==', ['get', 'gender_id'], 2], especiesColors.find(especie => especie.id === 2).paint.color,
  ['==', ['get', 'gender_id'], 3], especiesColors.find(especie => especie.id === 3).paint.color,
  ['==', ['get', 'gender_id'], 4], especiesColors.find(especie => especie.id === 4).paint.color,
];
const especiesLayers = [
  {
    'id': 'interventionZone',
    'type': 'fill',
    'source': 'xorfor',
    'source-layer': 'interventionzone',
    'paint': {
      'fill-color': [
        'case',
        ...especiesFillColors,
        '#151414'
      ]
    },
    'layout': {'visibility': 'visible'}
  },
  {
    'id': 'interventionZone-species-line',
    'type': 'line',
    'source': 'xorfor',
    'source-layer': 'interventionzone',
    'paint': {
      'line-color': 'rgba(0, 0, 0, 1)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 13, 1, 15, 2, 16, 3]
    },
    'layout': {'visibility': 'visible'}
  }
];
const especiesLayersSelection = [
  {
    'id': 'interventionZone-species-selection',
    'type': 'fill',
    'source': 'xorfor',
    'source-layer': 'interventionzone',
    'paint': {
      'fill-color': [
        'case',
        ...especiesFillColors,
        'rgba(255,255,255,0)'
      ],
      'fill-opacity': 0.5
    },
    'layout': {'visibility': 'visible'}
  },
  {
    'id': 'interventionZone-selected-line',
    'type': 'line',
    'source': 'xorfor',
    'source-layer': 'interventionzone',
    'paint': {
      'line-color': 'rgb(241,213,14)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 13, 3, 16, 5]
    },
    'layout': {'visibility': 'visible'}
  },
];

//CATEGORYGROUPS
const categoryGroupsLayers = CATEGORY_GROUPS
  .filter(({id}) => id !== 7)
  .reverse()
  .map(group => ({
    'id': `${group.id}`,
    'type': 'fill',
    'source': 'xorfor',
    'source-layer': 'interventionzone',
    'paint': {
      'fill-color': group.color,
      'fill-opacity': 0.9
    },
    'layout': {'visibility': 'visible'}
  }));

const layerConstructorNormal = (communityId, interventionZonesIds, interventionZoneId, interventionZoneCarouselId) => {
  const newLayers = baseLayers.map(layer => ({
    ...layer,
    'filter': ['==', layers_id_selector, communityId]
  }));

  newLayers.push(...normalLayers.map(layer => ({
    ...layer,
    'paint': {
      ...layer.paint,
      ...(interventionZoneId && layer.type === 'fill' ? {'fill-opacity': 0.15} : {})
    },
    'filter': ['in', layers_id_selector, ['literal', interventionZonesIds]]
  })));

  if (interventionZoneId) {
    newLayers.push(...selectionLayers.map(layer => ({
      ...layer,
      'filter': ['==', layers_id_selector, interventionZoneId]
    })));
  }
  if (interventionZoneCarouselId) {
    newLayers.push(...preselectionLayers.map(layer => ({
      ...layer,
      'filter': ['==', layers_id_selector, interventionZoneCarouselId]
    })));
  }
  return newLayers;
};

const layerConstructorSpecies = (communityId, interventionZonesIds, interventionZoneId, interventionZoneCarouselId, interventionZoneIdsFromAnnualPlan) => {
  const newLayers = baseLayers.map(layer => ({
    ...layer,
    'filter': ['==', layers_id_selector, communityId]
  }));

  newLayers.push(...especiesLayers.map(layer => ({
    ...layer,
    'filter': ['in', layers_id_selector, ['literal', interventionZoneIdsFromAnnualPlan || interventionZonesIds]],
    'paint': {
      ...layer.paint,
      ...(layer.type === 'fill' ? {
        'fill-opacity': [
          'case',
          ['==', ['get', 'has_frondosas'], 1], interventionZoneId ? 0.15 : 0.5,
          ['==', ['get', 'has_eucaliptus'], 1], interventionZoneId ? 0.15 : 0.5,
          ['==', ['get', 'has_pinus'], 1], interventionZoneId ? 0.15 : 0.5,
          ['==', ['get', 'has_raso'], 1], interventionZoneId ? 0.15 : 0.5,
          ['==', ['get', 'has_acacias'], 1], interventionZoneId ? 0.15 : 0.5,
          ['==', ['get', 'has_others'], 1], interventionZoneId ? 0.15 : 0.5,
          0
        ],
      } : {})
    }
  })));

  if (interventionZoneId) {
    newLayers.push(...especiesLayersSelection.map(layer => ({
      ...layer,
      'filter': ['==', layers_id_selector, interventionZoneId]
    })));
  }
  if (interventionZoneCarouselId) {
    newLayers.push(...preselectionLayers.map(layer => ({
      ...layer,
      'filter': ['==', layers_id_selector, interventionZoneCarouselId]
    })));
  }
  return newLayers;
};

const layerConstructorAnnualPlan = (communityId, interventionZonesIds, interventionZoneId, annualPlanInterventionZoneIdsByCategoryGroup) => {
  const interventionsByCategory = Object.keys(annualPlanInterventionZoneIdsByCategoryGroup)
    .map(key => annualPlanInterventionZoneIdsByCategoryGroup[key])
    .flatMap(x => x);

  //Filter community selected
  const newLayers = baseLayers.map(layer => ({
    ...layer,
    'filter': ['==', layers_id_selector, communityId]
  }));

  newLayers.push(
    {
      'id': 'interventionZone',
      'type': 'fill',
      'source': 'xorfor',
      'source-layer': 'interventionzone',
      'filter': ['in', layers_id_selector, ['literal', interventionZonesIds]],
      'paint': {
        'fill-color': 'rgb(178,178,178)',
        'fill-opacity': ['case',
          ['in', layers_id_selector, ['literal', interventionsByCategory]], 0,
          0.25]
      },
      'layout': {'visibility': 'visible'}
    },
    {
      'id': 'interventionZone-line',
      'type': 'line',
      'source': 'xorfor',
      'source-layer': 'interventionzone',
      'filter': ['in', layers_id_selector, ['literal', interventionZonesIds]],
      'paint': {
        'line-color': 'rgba(0, 0, 0, 1)',
        'line-width': ['interpolate', ['linear'], ['zoom'], 13, 1, 15, 2, 16, 3]
      },
      'layout': {'visibility': 'visible'}
    }
  );

  newLayers.push(...categoryGroupsLayers.map(layer => ({
    ...layer,
    'filter': ['all',
      ['in', layers_id_selector, ['literal', annualPlanInterventionZoneIdsByCategoryGroup[layer.id]]],
      ['in', layers_id_selector, ['literal', interventionZonesIds]]
    ], //Filter interventionZoneIds from community selected and filtered interventionZones
  })));

  if (interventionZoneId) {
    newLayers.push(...especiesLayersSelection.map(layer => ({
      ...layer,
      'filter': ['==', layers_id_selector, interventionZoneId]
    })));
  }
  return newLayers;
};

const layerConstructorBusiness = () => {
  return {
    'id': 'resfor',
    'type': 'circle',
    'source': 'resfor',
    'paint': {
      'circle-color': [
        'case',
        ['==', ['get', 'type'], BUSINESS_CATEGORIES.FURNITURE], '#074F57',
        ['==', ['get', 'type'], BUSINESS_CATEGORIES.FORESTRY], '#00C49A',
        ['==', ['get', 'type'], BUSINESS_CATEGORIES.PAPER], '#F7996E',
        ['==', ['get', 'type'], BUSINESS_CATEGORIES.SAWMILL], '#0E9AAA',
        ['==', ['get', 'type'], BUSINESS_CATEGORIES.BOARD], '#F45B69',
        ['==', ['get', 'type'], BUSINESS_CATEGORIES.OTHER], '#976391',
        '#973572'
      ],
      'circle-radius': ['interpolate', ['linear'], ['zoom'], 10, 3, 15, 15],
      'circle-stroke-color': 'rgb(255,255,255)',
      'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], 10, 0.5, 15, 2],
    },
    'layout': {'visibility': 'visible'}
  };
};

const layerConstructorMaderaPlusProspectingPoints = (/*maderaPlusMode*/) => {
  return [
    {
      'id': 'maderaPlus',
      'type': 'circle',
      'source': 'maderaPlus',
      'paint': {
        'circle-color': '#fae71b',
        'circle-radius': ['interpolate', ['linear'], ['zoom'], 10, 3, 15, 15],
        'circle-stroke-color': 'rgb(255,255,255)',
        'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], 10, 0.5, 15, 2],
      },
    //'layout': {'visibility': maderaPlusMode ? 'visible' : 'none'}
    },
    {
      'id': 'maderaPlus-label',
      'type': 'symbol',
      'source': 'maderaPlus',
      'layout': {
        'text-field': ['id']
      }
    }
  ];
};

const layerConstructorForestal = () => ([
  {
    'id': '03ACS_202212_Forestal_MVMC',
    'type': 'fill',
    'source': 'afecciones',
    'source-layer': '03ACS_202212_Forestal_MVMC',
    'paint': {
      'fill-color': '#5cb816',
      'fill-opacity': 0.3
    },
    'layout': {
      'visibility': 'visible'
    }
  }
]);

const layerConstructorPatrimonioCultural = (/*maderaPlusMode*/) => {
  const layers = MAP_DETAIL_STYLES
    .find(({id}) => id === MAP_DETAILS.patrimonio)
    .colors
    .map(color => ({
      'id': color.id,
      'type': color.type !== 'symbol' ? color.type : 'circle',
      'source': 'afecciones',
      'source-layer': color.id,
      'maxzoom': 23,
      'paint': color.type !== 'symbol' ? 
        {
          [`${color.type}-color`]: color.paint.color,
          [`${color.type}-opacity`]: color.paint.opacity
        } : 
        {
          'circle-color': color.paint.color,
          'circle-opacity': [ 'interpolate',
            ['linear'], ['zoom'],
            14, color.paint.opacity,
            17, 1
          ],
          'circle-radius': [ 'interpolate',
            ['linear'], ['zoom'],
            7, 0.1,
            15, 5
          ]
        },
      'layout': {
        'visibility': 'visible'
      }
    }));
  
  return layers;
};

const layerConstructorMultiselect = (communityId, interventionZonesIds, multiselectionIds, interventionZoneId) => {
  const newLayers = baseLayers.map(layer => ({
    ...layer,
    'filter': ['==', layers_id_selector, communityId]
  }));

  //Intervention Zones
  newLayers.push(...baseInterventionZonesLayers.map(layer => ({
    ...layer,
    'paint': {
      ...layer.paint,
      ...(interventionZoneId && layer.type === 'fill' ? {'fill-opacity': 0.15} : {})
    },
    'filter': ['in', layers_id_selector, ['literal', interventionZonesIds]]
  })));

  newLayers.push(...selectionLayersInMode.map(layer => ({
    ...layer,
    'paint': {
      ...layer.paint,
      ...(interventionZoneId && layer.type === 'fill' ? {'fill-opacity': 0.15} : {})
    },
    'filter': ['in', layers_id_selector, ['literal', multiselectionIds]]
  })));

  return newLayers;
};

const layerConstructorBatch = (communityId, interventionZonesIds, interventionZoneIdsWithBatch, interventionZoneId) => {
  const newLayers = baseLayers.map(layer => ({
    ...layer,
    'filter': ['==', layers_id_selector, communityId]
  }));

  newLayers.push(...baseInterventionZonesLayers.map(layer => ({
    ...layer,
    'paint': {
      ...layer.paint,
      ...(interventionZoneId && layer.type === 'fill' ? {'fill-opacity': 0.15} : {})
    },
    'filter': ['in', layers_id_selector, ['literal', interventionZonesIds]]
  })));

  newLayers.push(...selectionLayersInMode.map(layer => ({
    ...layer,
    'paint': {
      ...layer.paint,
      ...(interventionZoneId && layer.type === 'fill' ? {'fill-opacity': 0.15} : {})
    },
    'filter': ['in', layers_id_selector, ['literal', interventionZoneIdsWithBatch]]
  })));

  return newLayers;

};

const layerConstructor = {
  normal: layerConstructorNormal,
  multiselect: layerConstructorMultiselect,
  species: layerConstructorSpecies,
  annualPlan: layerConstructorAnnualPlan,
  business: layerConstructorBusiness,
  maderaPlusProspectingPoints: layerConstructorMaderaPlusProspectingPoints,
  maderaPlusParamsMap: layerConstructorMaderaPlus,
  forestal: layerConstructorForestal,
  patrimonio: layerConstructorPatrimonioCultural,
  batch: layerConstructorBatch
};
export default layerConstructor;
