import keyMirror from 'keymirror';
import {createAction} from 'redux-actions';

const ActionTypes = keyMirror({
  MOVE_MAP: null,
  SET_BASEMAP: null,
  SELECT_COMMUNITY: null,
  SELECT_COMMUNITY_ON_CAROUSEL: null,
  SELECT_INTERVENTION_ZONE: null,
  SELECT_INTERVENTION_ZONE_ON_CAROUSEL: null,
  FILTERS_ACTIVED: null,
  CATEGORY_FILTERS_ACTIVED: null,
  SELECT_INTERVENTION: null,
  SELECT_ANNUAL_PLAN: null,
  RESET_SELECTED_ANNUAL_PLAN: null,
  OFFLINE_ACTIVATE: null,
  OFFLINE_DEACTIVATE: null,
  UPDATE_SEARCHED_TEXT: null,
});

export default ActionTypes;

export const selectCommunity = createAction(ActionTypes.SELECT_COMMUNITY);
export const selectCommunityOnCarousel = createAction(ActionTypes.SELECT_COMMUNITY_ON_CAROUSEL);
export const selectInterventionZone = createAction(ActionTypes.SELECT_INTERVENTION_ZONE);
export const selectInterventionZoneOnCarousel = createAction(ActionTypes.SELECT_INTERVENTION_ZONE_ON_CAROUSEL);
export const moveMap = createAction(ActionTypes.MOVE_MAP);
export const setBaseMap = createAction(ActionTypes.SET_BASEMAP);
export const activeFilters = createAction(ActionTypes.FILTERS_ACTIVED);
export const activeCategoryFilters = createAction(ActionTypes.CATEGORY_FILTERS_ACTIVED);
export const selectIntervention = createAction(ActionTypes.SELECT_INTERVENTION);
export const selectAnnualPlan = createAction(ActionTypes.SELECT_ANNUAL_PLAN);
export const resetSelectedAnnualPlan = createAction(ActionTypes.RESET_SELECTED_ANNUAL_PLAN);
export const activateOffline = createAction(ActionTypes.OFFLINE_ACTIVATE);
export const deactivateOffline = createAction(ActionTypes.OFFLINE_DEACTIVATE);
export const updateSearchedText = createAction(ActionTypes.UPDATE_SEARCHED_TEXT);

