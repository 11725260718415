import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

//BIKENTA
import DialogPanel from './DialogPanel';

const LazyLoading = ({isOpen, message}) => {
  return <DialogPanel
    id="lazy-loading"
    onClose={() => {}}
    label={message}
    isOpen={isOpen}
  >
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 100,
      width: 300
    }}>
      <CircularProgress sx={{mb: 2}}/>
    </Box>
  </DialogPanel>;
};

LazyLoading.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
};

LazyLoading.defaultProps = {
  isOpen: false,
  message: 'Cargando...'
};

export default LazyLoading;