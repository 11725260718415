import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import AdvancedFilters from '../components/AdvancedFilters/AdvancedFilters';
import {categoryFilters} from '../components/proptypes/categoryFilters';

const AdvancedFiltersView = ({isAdvanceFilterOpen, categoryFilters, selectedCategoryFilters, ...rest }) => {
  return <Dialog
    fullScreen
    open={isAdvanceFilterOpen}
    TransitionComponent={Slide}
    sx={{zIndex: 2000}}
  >
    <AdvancedFilters filters={categoryFilters} selectedFilters={selectedCategoryFilters} {...rest} />
  </Dialog>;
};

AdvancedFiltersView.propTypes = {
  isAdvanceFilterOpen: PropTypes.bool.isRequired,
  categoryFilters: categoryFilters,
  selectedCategoryFilters: PropTypes.arrayOf(PropTypes.number),
  onSaveFilter: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default AdvancedFiltersView;