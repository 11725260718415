import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

//UTILS
import {intervention} from '../proptypes/intervention';
import {lighten} from '@mui/material/styles';
import {ROLES_LABEL} from '../../config';

const headCells = [
  {
    id: 'name',
    label: 'Intervenciones',
    minWidth: '70px'
  }
];

const tableHeader = {
  m: 0,
  p: (0, 0, 0, 1),
  bgcolor: theme => lighten(theme.palette.primary.main, 0.80),
  borderBottom: theme => `1.5px solid ${theme.palette.primary.main}`
};

const CommonInterventionList = ({commonInterventions, onInterventionSelect}) => {

  const handleSelectIntervention = (ids, categories, done, never_do) => onInterventionSelect && onInterventionSelect(ids, categories, done, never_do);

  const tableIntervention = {
    backgroundColor: intervention.done ? 'grey.100' : undefined,
    '&:last-child td': {
      borderBottom: 0,
    },
  };

  return <TableContainer sx={{overflowY: 'auto', width: '100%', mt: 2, mb: 2}}>
    <Table size="small" stickyHeader sx={{border: theme => `1.5px solid ${theme.palette.primary.main}`}}>
      <TableHead sx={{height: '38px'}}>
        <TableRow>
          {
            headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sx={tableHeader}
              >
                <TableSortLabel
                  sx={{minWidth: headCell.minWidth}}
                >
                  {headCell.label.toUpperCase()}
                </TableSortLabel>
              </TableCell>
            ))
          }
        </TableRow>
      </TableHead>
      <TableBody sx={{border: 2}}>
        {commonInterventions
          .map((intervention, index) => {
            return <TableRow
              hover
              key={index}
              onClick={() => handleSelectIntervention(intervention.ids, intervention.categories, intervention.done, intervention.never_do)}
              sx={tableIntervention}
            >
              <TableCell align="left" sx={{pl: 1, textOverflow: 'ellipsis'}} width="65%" >
                {intervention.type.name}
              </TableCell>
            </TableRow>;
          })}
      </TableBody>
    </Table>
  </TableContainer>;
};

CommonInterventionList.propTypes = {
  commonInterventions: PropTypes.arrayOf(PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.number),
    type: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  })),
  isOffline: PropTypes.bool,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)),
  onInterventionSelect: PropTypes.func,
};

export default CommonInterventionList;
