import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const AreaIndicator = ({label, style, sublabels}) => {
  const getCategorySummary = sublabels && sublabels
    .filter(sublabel => sublabel.value !== undefined)
    .reduce((prev, curr) => prev + (curr.value), 0).toFixed(2);

  return <Card sx={{width: {xs: '100%', sm: '48%', md: '30%'}, flexGrow: 1}}>
    <CardHeader
      title={
        <Stack direction='column' alignItems='flex-start' sx={{m: 1}}>
          <Typography variant='body2' sx={{fontWeight: 'bold', color: style.color, ml: 1, mb: 1}}>{label.toUpperCase()}</Typography>
          <Stack direction='row' justifyContent='center' alignItems='center' sx={{ml: 1}}>
            <Box sx={{width: 30}}>
              {style.icon}
            </Box>
            <Typography variant='h5' sx={{color: style.color, fontWeight: 'bold', ml: 1}}>{getCategorySummary} Ha</Typography>
          </Stack>
        </Stack>
      }
      sx={{bgcolor: style.background, m:0, p: 1}}
    />
    <CardContent sx={{m: 0, display: 'flex', flexDirection: 'column'}}>
      {
        sublabels.map((cat, index) => (
          <Stack key={index} direction="row" sx={{alignItems: 'flex-start', justifyContent: 'flex-start'}}>
            <Typography variant="body2" sx={{
              fontWeight: 'bold',
              ml: 1,
              minWidth: 50,
              textAlign: 'right',
              letterSpacing: 1.25,
              color: !cat.value ? 'grey.400' : undefined
            }}>{!cat.value ? '-' : cat.value.toFixed(2)}</Typography>
            <Typography variant="body2"
              sx={{fontWeight: 'bold', ml: 1, color: !cat.value ? 'grey.400' : undefined}}>Ha</Typography>
            <Typography variant="body2" sx={{ml: 1, color: !cat.value ? 'grey.400' : undefined}}>
              {cat.label}
            </Typography>
          </Stack>
        )
        )
      }
    </CardContent>
  </Card>;
};

AreaIndicator.propTypes = {
  label: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  sublabels: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  }))
};

export default AreaIndicator;