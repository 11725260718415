import Intervention from '../../../utils/InterventionClass';
import round from '../../../utils/round';

const indicatorInterventionsEconomic = (filteredInterventionZones) => {

  return filteredInterventionZones
    .map(iZone => iZone.interventions)
    .flat()
    .reduce((acc, int) => {
      const intervention = new Intervention(int);
      return {
        plannedInvest: round(acc.plannedInvest + intervention.planned_invest),
        plannedIncome: round(acc.plannedIncome + intervention.planned_income),
        executedInvest: round(acc.executedInvest + intervention.executed_invest),
        executedIncome: round(acc.executedIncome + intervention.executed_income),
      };
    },
    {
      plannedInvest: 0,
      plannedIncome: 0,
      executedInvest: 0,
      executedIncome: 0
    }
    );

};

export default indicatorInterventionsEconomic;