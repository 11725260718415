import React, {useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';

//UTILS
import {annualPlan} from '../../proptypes/annualPlan';
import {basemap} from '../../proptypes/basemap';
import {intervention} from '../../proptypes/intervention';
import {viewport} from '../../proptypes/viewport';
import {ROLES_LABEL} from '../../../config';
import {roles} from '../../proptypes/roles';
import {commoners} from '../../proptypes/commoners';
import {fileDetail} from '../../proptypes/fileDetail';

//BIKENTA
import AnnualPlanPanel from './AnnualPlanPanel';
import AlertDeleteDialog from '../../AlertDeleteDialog';
import DialogPanel from '../../DialogPanel';
import DocumentationPanel from './DocumentationPanel';
import ExecutionPanel from './ExecutionPanel/ExecutionPanel';
import PlanningPanel from './PlanningPanel';
import InterventionHeader from './InterventionHeader';
import {categoryFilters} from '../../proptypes/categoryFilters';

const InterventionMenu = ({
  annualPlansAssignedInSelectedIntervention,
  basemap,
  batches,
  categoryFilters,
  commoners,
  fileDetail,
  intervention,
  interventionZone,
  interventionZoneDetail,
  isInterventionMenuOpen,
  isOffline,
  multiselect,
  role,
  rolesList,
  selectedAnnualPlan,
  viewport,
  onCreateExecution,
  onAnnualPlanUpdateIntervention,
  onCloseInterventionMenu,
  onEditEnd,
  onDelete,
  onDeleteExecution,
  onEditPlannedIntervention,
  onFileDetailOpen,
  onUpdateExecution,
  onUpdateIntervention,
  onUploadFile,
  onDeleteFile,
  onDownloadFile,
  onInputInterventionZoneGeometryStarts,
  onInputInterventionZoneGeometryEnds,
  onNeverDo
}) => {
  
  const [isExecutionEditable, setIsExecutionEditable] = useState(!intervention.done);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

  /*PLANIFICACIÓN-----------------------------------------------------------------------------------------------------*/
  const handleEditPlannedYears = (intervention) => onEditPlannedIntervention && onEditPlannedIntervention(intervention);

  /*EJECUCIÓN---------------------------------------------------------------------------------------------------------*/
  const handleExecutionEditChange = (isEditing) => setIsExecutionEditable(isEditing);

  /*DOCUMENTACIÓN-----------------------------------------------------------------------------------------------------*/
  const handleUploadFile = (uploadFormData) => onUploadFile(onUploadFile(intervention.intervention_zone, intervention.id, uploadFormData));
  const handleDeleteFile = fileId => onDeleteFile(intervention.intervention_zone, intervention.id, fileId);

  /*INTERVENCIÓN------------------------------------------------------------------------------------------------------*/
  const handleAlertDeleteIntervention = () => setIsAlertDialogOpen(true);
  const handleDeleteIntervention = () => {
    setIsAlertDialogOpen(false);
    onDelete(intervention.id);
  };
  const handleCancelDeleteIntervention = () => setIsAlertDialogOpen(false);
  const handleUpdateExecution = (intervention) => {
    onUpdateExecution(intervention);
    onCloseInterventionMenu();
  };

  return <>
    {
      isAlertDialogOpen ? <AlertDeleteDialog
        title={'Eliminar intervención'}
        description={'No se podrá recuperar la intervención. ¿Estás seguro?'}
        onCancel={handleCancelDeleteIntervention}
        onAccept={(interventionId) => handleDeleteIntervention(interventionId)}/> : 
        <DialogPanel
          isOpen={isInterventionMenuOpen}
          onClose={onEditEnd}
          label="DETALLES DE LA INTERVENCIÓN"
          fullWidth
        >
          <Box sx={{p: 1.25}}>
            <InterventionHeader
              batches={batches}
              categoryFilters={categoryFilters}
              intervention={intervention}
              multiselect={multiselect}
              role={role}
              isOffline={isOffline}
              onAlertDeleteIntervention={handleAlertDeleteIntervention}
              onUpdateIntervention={(updatedIntervention) => {
                onUpdateIntervention(intervention.id, updatedIntervention);
              }}
            />
            <AnnualPlanPanel
              annualPlansAssignedInSelectedIntervention={annualPlansAssignedInSelectedIntervention}
              intervention={intervention}
              role={role}
              selectedAnnualPlan={selectedAnnualPlan}
              onAnnualPlanUpdateIntervention={onAnnualPlanUpdateIntervention}
            />
            <PlanningPanel
              isOffline={isOffline}
              intervention={intervention}
              interventionZone={interventionZone}
              role={role}
              onEditPlannedIntervention={(intervention) => handleEditPlannedYears(intervention)}
            />
            <ExecutionPanel
              basemap={basemap}
              batches={batches}
              isOffline={isOffline}
              isEditing={isExecutionEditable}
              intervention={intervention}
              interventionZone={interventionZone}
              interventionZoneDetail={interventionZoneDetail}
              role={role}
              viewport={viewport}
              onCreateExecution={onCreateExecution}
              onUpdateExecution={handleUpdateExecution}
              onEditChange={handleExecutionEditChange}
              /*onEditedAccept={handleEditedAccept}*/
              onDeleteExecution={onDeleteExecution}
              onInputInterventionZoneGeometryStarts={onInputInterventionZoneGeometryStarts}
              onInputInterventionZoneGeometryEnds={onInputInterventionZoneGeometryEnds}
              onNeverDo={onNeverDo}
            />
            <DocumentationPanel
              commoners={commoners}
              isOffline={isOffline}
              isEditing={isExecutionEditable}
              files={intervention.files}
              fileDetail={fileDetail}
              role={role}
              rolesList={rolesList}
              onEditChange={handleExecutionEditChange}
              onUploadFile={handleUploadFile}
              onDeleteFile={handleDeleteFile}
              onDownloadFile={onDownloadFile}
              onFileDetailOpen={onFileDetailOpen}
            />
          </Box>
        </DialogPanel>
    }
  </>;
};

InterventionMenu.propTypes = {
  annualPlansAssignedInSelectedIntervention: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    year: PropTypes.number
  })),
  basemap: basemap,
  batches: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.number, name: PropTypes.string })),
  categoryFilters: categoryFilters,
  commoners: commoners,
  fileDetail: fileDetail,
  intervention: intervention,
  interventionZone: PropTypes.object,
  interventionZoneDetail: PropTypes.object,
  isOffline: PropTypes.bool,
  isInterventionMenuOpen: PropTypes.bool.isRequired,
  multiselect: PropTypes.bool.isRequired,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)),
  rolesList: PropTypes.arrayOf(roles),
  selectedAnnualPlan: annualPlan,
  viewport: viewport,
  onCreateExecution: PropTypes.func,
  onAnnualPlanUpdateIntervention: PropTypes.func,
  onCloseInterventionMenu: PropTypes.func,
  onEditEnd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEditPlannedIntervention: PropTypes.func.isRequired,
  onDeleteExecution: PropTypes.func.isRequired,
  onFileDetailOpen: PropTypes.func,
  onUpdateIntervention: PropTypes.func.isRequired,
  onUpdateExecution: PropTypes.func,
  onUploadFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  onDownloadFile: PropTypes.func.isRequired,
  onInputInterventionZoneGeometryStarts: PropTypes.func.isRequired,
  onInputInterventionZoneGeometryEnds: PropTypes.func.isRequired,
  onNeverDo: PropTypes.func.isRequired
};

export default InterventionMenu;
