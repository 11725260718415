import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

const accordion = {
  p: 0,
  m: '16px 0',
  '&.Mui-expanded': {
    m: '16px 0',
  },
  '&::before': {
    bgcolor: 'transparent',
  },
  '& .MuiButtonBase-root': {
    minHeight: '28px',
  }
};

const summary = {
  p: 0,
  m: 0,
  borderBottom: theme => `2px solid ${theme.palette.secondary.main}`,
  '& .MuiAccordionSummary-content': {
    m: 0,
  },
  '&.Mui-expanded': {
    '& .MuiAccordionSummary-content': {
      m: 0,
    },
    minHeight: '28px',
  },
};

const details = {
  p: 0,
  mt: 1
};

const DropDown = ({title, children, hasData = false}) => {

  const [expanded, setExpanded] = useState(hasData);

  const handleChange = (panel) => (event, newExpanded) => {
    if (hasData) setExpanded(newExpanded ? panel : false);
  };

  return <Accordion elevation={0} sx={accordion} expanded={expanded} onChange={handleChange(!expanded)} disableGutters>
    <AccordionSummary sx={summary} expandIcon={hasData ? <ExpandMoreIcon sx={{color: 'secondary.main'}}/> : null}>
      <Typography variant="subtitle1" sx={{
        m: 0,
        fontWeight: 'bold',
        color: 'secondary.main'
      }}>
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails sx={details}>{children}</AccordionDetails>
  </Accordion>;
};

DropDown.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  hasData: PropTypes.bool,
};

export default DropDown;