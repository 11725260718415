import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import Stack from '@mui/material/Stack';

import InterventionZoneCard from '../Cards/InterventionZoneCard';
import Carousel from '../Carousel';

//STYLES
const carouselHorizontal = {
  zIndex: 40,
  position: 'absolute',
  bottom: -16,
  left: -34,
  right: 0
};
const scrollVertical = {
  zIndex: 40,
  top: 6,
  bottom: 0,
  position: 'absolute',
  left: 0,
  right: 0,
  width: '250px',
  height: 'auto',
  overflow: 'auto'
};
const interventionZonestyles = {
  display: 'inline-block',
  ml: {
    xs: 5,
    lg: 1
  },
  mb: {
    xs: 9.5,
    lg: 1
  }
};

const InterventionZoneGroup = ({
  interventionZones,
  onInterventionZoneSelect,
  onInterventionZonePreselect,
  interventionZoneId,
  annualPlansAssignedInSelectedInterventionZones
}) => {
  const [index, setIndex] = useState(interventionZoneId ? interventionZones.findIndex(interventionZone => interventionZone.id === interventionZoneId) : 0);
  const handleInterventionZoneSelect = (id) => onInterventionZoneSelect(id);
  const handleInterventionZoneCarouselSelect = (id) => onInterventionZonePreselect(id);

  useEffect(() => {
    if (interventionZones.length) {
      handleInterventionZoneCarouselSelect(interventionZones[index].id);
    }
  }, [index]);
  
  return useMemo(() => {
    return <>
      <Hidden lgUp>{/*MOBILE*/}
        <Stack sx={carouselHorizontal}>
          <Carousel
            activeIndex={index}
            onActiveIndexChange={setIndex}
          >
            {
              interventionZones.map((interventionZone, index) =>
                <Box key={index} sx={interventionZonestyles}>
                  <InterventionZoneCard
                    {...interventionZone}
                    annualPlansAssigned={
                      annualPlansAssignedInSelectedInterventionZones
                        .find(intZone => intZone.interventionZoneId === interventionZone.id).annualPlans
                    }
                    onInterventionZoneSelect={() => handleInterventionZoneSelect(interventionZone.id)}
                  />
                </Box>)
            }
          </Carousel>
        </Stack>
      </Hidden>
      <Hidden lgDown>{/*DESKTOP*/}
        <Stack direction="column" sx={scrollVertical}>
          {
            interventionZones.map((interventionZone, index) =>
              <Box key={index} sx={interventionZonestyles}>
                <InterventionZoneCard
                  {...interventionZone}
                  annualPlansAssigned={
                    annualPlansAssignedInSelectedInterventionZones
                      .find(intZone => intZone.interventionZoneId === interventionZone.id).annualPlans
                  }
                  onInterventionZoneSelect={() => handleInterventionZoneSelect(interventionZone.id)}
                />
              </Box>)
          }
        </Stack>
      </Hidden>
    </>;
  }, [interventionZones]);
};

InterventionZoneGroup.propTypes = {
  annualPlansAssignedInSelectedInterventionZones: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    annualPlans: PropTypes.arrayOf(PropTypes.number)
  })),
  interventionZones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      canton: PropTypes.string,
      mouteira: PropTypes.string,
      stratum: PropTypes.string,
      area: PropTypes.number,
      age: PropTypes.number,
      planned_interventions: PropTypes.oneOf(['Sí', 'No']),
      categories: PropTypes.arrayOf(PropTypes.number),
      categoryGroups: PropTypes.arrayOf(
        PropTypes.number),
    })
  ),
  interventionZoneId: PropTypes.number,
  onInterventionZoneSelect: PropTypes.func,
  onInterventionZonePreselect: PropTypes.func
};

export default InterventionZoneGroup;