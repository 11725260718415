import React from 'react';

//MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//UTILS
import {ELASTIC_MODULE_LABELS, ELASTIC_MODULE_PARAMS} from '../../../config';
import {elastic_module} from '../../proptypes/elastic_module';

//STYLES
const subtitleSx = {
  fontWeight: 'bolder',
  textTransform: 'uppercase',
  color: 'grey.800',
  bgcolor: 'grey.300',
  p: 0.5,
  pt: 0.75,
  pl: 1
};
const paramLabelSx = {
  mr:1,
  fontWeight: 'bold',
  textAlign: 'center'
};
const paramValueSx = {
  py: 0.5,
  px: 1,
  bgcolor: 'grey.100',
  border: '1px solid lightgrey',
  borderRadius: 1
};

const MaderaPlusCard = ({elastic_module}) => {

  const groupParamsTitles = Object.keys(elastic_module).filter(key => key !== 'id' && key !== 'label');

  return <Box pb={1}>
    <Box display="flex" flexDirection="row" justifyContent="flex-start" mt={1}>
      <Stack ml={1}>
        {
          groupParamsTitles.map(title => {
            const params = Object.keys(elastic_module[title]);
            return <Box key={title} sx={{flexDirection: 'row', alignItems: 'center'}}>
              <Typography sx={subtitleSx}>
                {ELASTIC_MODULE_LABELS[title]}
              </Typography>
              <Grid container spacing={0}>
                {
                  params.map((param, index) => {
                    return <Grid key={index} item xs={4}>
                      <Stack key={index} sx={{flexDirection: 'column', m: 0.5, alignItems: 'center', p: 1}}>
                        <Typography sx={paramLabelSx}>
                          {ELASTIC_MODULE_PARAMS[param].label}
                        </Typography>
                        <Typography sx={paramValueSx}>
                          {elastic_module[title][param]} {ELASTIC_MODULE_PARAMS[param].units}
                        </Typography>
                      </Stack>
                    </Grid>;
                  })
                }
              </Grid>
            </Box>;
          })
        }
      </Stack>
    </Box>
  </Box>;
};

MaderaPlusCard.propTypes = {
  elastic_module: elastic_module,
};
export default MaderaPlusCard;
