import PropTypes from 'prop-types';

export const intervention = PropTypes.shape({
  id: PropTypes.number,
  intervention_zone: PropTypes.number,
  type: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  uom: PropTypes.string,
  mid_value: PropTypes.string,
  planned_income: PropTypes.number,
  planned_invest: PropTypes.number,
  planned_start_year: PropTypes.number,
  planned_end_year: PropTypes.number,
  planned_assigned: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    year: PropTypes.number
  })),
  done: PropTypes.bool,
  geometry: PropTypes.oneOf(['total', 'partial']),
  categories: PropTypes.arrayOf(PropTypes.number),
  categoryGroups: PropTypes.arrayOf(PropTypes.number),
  is_annual_planned: PropTypes.bool,
  who_made_it: PropTypes.string,
  executed_start_year: PropTypes.number,
  executed_end_year: PropTypes.number,
  executed_invest: PropTypes.number,
  executed_income: PropTypes.number,
  comments: PropTypes.string,
  stratum: PropTypes.number,
  files: PropTypes.arrayOf (PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    content_type: PropTypes.string,
    extension: PropTypes.string,
    download_url: PropTypes.string,
    thumbnail_url: PropTypes.string
  }))
});