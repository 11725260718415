import React, {useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//BIKENTA
import MaderaPlusCard from './MaderaPlusCard';
import InputSelect from '../AddInterventionMenu/InputSelect';
import {elastic_module} from '../../proptypes/elastic_module';

const maderaPlusButton = {
  '&.Mui-disabled': {
    color: 'grey.400'
  }
};

const MaderaPlusResults = ({maderaPlusAvailableSpecies, onMaderaPlusFormOpen, elastic_module}) => {
  const [selectedSpecieId, setSelectedSpecieId] = useState(maderaPlusAvailableSpecies.length ? maderaPlusAvailableSpecies[0].id : undefined);
  const handleMaderaPlusFormOpen = () => onMaderaPlusFormOpen();
  const handleSelectedSpecieChange = (id) => setSelectedSpecieId(id);
  const selectedSpecieParams = elastic_module && elastic_module.length && elastic_module.find(params => params.id === selectedSpecieId);

  return <Stack sx={{display: 'flex', gap: 1}}>
    <Typography gutterBottom>Madera Plus es una empresa que te calcula las propiedades tecnológicas de la madera de una masa
      forestal, tanto de forma remota, como mediante evaluación in situ con equipos sónicos, para dar mayor valor a tu madera. .</Typography>
    {
      maderaPlusAvailableSpecies.length === 0 && <Typography gutterBottom color='error' sx={{fontWeight: 900}}>
        Lo lamentamos, pero no está disponible el módulo de elasticidad para ninguna de las especies de la zona de intervención.
      </Typography>
    }
    <Button variant='contained' onClick={handleMaderaPlusFormOpen} 
      disabled={maderaPlusAvailableSpecies.length === 0}
      sx={maderaPlusButton}>Calcular módulo elasticidad MADERA+</Button>
    {
      !!maderaPlusAvailableSpecies.length && !!elastic_module.length && <>
        <InputSelect value={22} options={maderaPlusAvailableSpecies} onChange={handleSelectedSpecieChange}/>
        <Typography variant='button' sx={{mt: 4}}>Resultados de la predicción</Typography>
        <Typography gutterBottom variant='body1'>Estos resultados tienen en cuenta <b>únicamente </b> los puntos dentro de
          la Zona de Intervención seleccionados por el usuario.</Typography>
        <MaderaPlusCard elastic_module={selectedSpecieParams}/>
        <Typography gutterBottom variant='button' sx={{mt: 4}}>Resultados de la obtención</Typography>
        <Typography gutterBottom variant='body1'>Estos resultados tienen en cuenta <b>las variables aportadas por el
          usuario</b> que se encuentran en negrita.</Typography>
        <MaderaPlusCard elastic_module={selectedSpecieParams}/>
      </>
    }
  </Stack>;
};

MaderaPlusResults.propTypes = {
  maderaPlusAvailableSpecies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    existences: PropTypes.shape({
      age: PropTypes.number,
      treeFeet: PropTypes.number,
      height: PropTypes.number,
      basometricArea: PropTypes.number,
      volumeWithBark: PropTypes.number,
      volumeWithoutBark: PropTypes.number,
      biomass: PropTypes.number,
    })
  })),
  onMaderaPlusFormOpen: PropTypes.func.isRequired,
  elastic_module: PropTypes.arrayOf(elastic_module)
};

MaderaPlusResults.defaultProps = {
  species: []
};

export default MaderaPlusResults;