import * as React from 'react';

import Tooltip from '@mui/material/Tooltip';
import {CATEGORY_GROUPS} from '../../../config';

const IcInfraestructuras = () => {
  return <Tooltip title={CATEGORY_GROUPS.find(cat => cat.id === 5)?.label}>
    <svg x='0px' y='0px' width='100%' height='100%' viewBox='0 0 24 24' >
      <path fill="#562B54" d="M0 0h24v24H0z" />
      <g fill="#FFF">
        <path d="M15.108 11.857l-.434-.011.434.011zM4.566 3.718h-.325v.365l-.018.365c2.616.13 6.659 1.569 7.127 3.83.227 1.097-.428 2.28-1.912 3.499-.481.007-.805.022-.973.047-1.051.152-3.48.714-3.583.738l-.131.03-.047.103-.242.527-1.453 3.175a7.208 7.208 0 01-.96-.127l-.868-.168.621.629c.104.106.211.194.318.269l-1.302.544 1.579.26v2.461H3.64l2.134-3.025h.157l-.232 3.025h.896l.587-1.689.067 1.675h.918l.35-3.022h3.449c.156.324.528.726 1.221.817l-.759 2.221h1.043l1.001-1.885.551 1.883h1.066v-3.459l-.48-.773.559-2.949.005-.022.001-.001h-.001l.02-.104c.008.026.016.05.022.077l.009.027a.745.745 0 00.006.023c.207.729.402 1.787.352 2.532-.219.121-.344.393-.292.693.056.327.415.743.486.823l.284.318.171-.392c.042-.096.248-.595.189-.942a.704.704 0 00-.292-.469c.05-.76-.116-1.767-.326-2.547-.095-.336-.32-.92-.34-.969l-.009-.017a.919.919 0 00-.062-.108c-.004-.007-.011-.013-.016-.02-.132-.076-.701-.112-.701-.112a8.125 8.125 0 01-.2-.006l-4.947-.082.009-.009 2.401.03c-.808-.017-1.656-.03-2.397-.033 1.261-1.224 1.773-2.442 1.525-3.644-.352-1.698-2.146-2.917-4.131-3.64 2.317.081 4.851.319 6.971 1.014v-.787c-3.238-1-7.267-1-10.339-.999zM7.75 19.09l-.075-1.862h.29L7.75 19.09zm5.535.358l.474-1.384h.262l-.736 1.384zm-.348-7.094l.364.007.211.005.365.008.434.01a178.712 178.712 0 01.798.021l.365.01.262.007-.052.272-.005.023-.064.341-.004.023-.579 3.056.51.822v2.755h-.108l-.643-2.2h-1.307c-.877 0-1.045-.563-1.061-.623l-.048-.213H7.26l-.119.342-.001.002-.825 2.379.208-2.712H5.491l-2.135 3.025h-.411v-2.378l-.155-.023.18-.075h.001l.315-.132 1.851-4.041a31.743 31.743 0 013.405-.7c.032-.004.077-.01.138-.014.183-.014.515-.027 1.114-.031h.365c.822 0 1.829.014 2.778.034zM19.684 10.668c.195 2.294-.751 5.262-2.835 8.866l.632.365c2.313-4.001 3.271-7.293 2.862-9.826h-.388a1.319 1.319 0 01-.271.595z" />
        <path d="M7.751 9.215a.275.275 0 00-.322.215l-.124.627-.381-.28a.274.274 0 10-.325.441l.728.537a.274.274 0 00.162.054h.003a.22.22 0 00.062-.008c.007-.001.013-.004.02-.007.015-.005.028-.009.042-.016l.004-.002.942-.509a.272.272 0 00.111-.371.274.274 0 00-.372-.112l-.431.232.096-.479a.274.274 0 00-.215-.322z" />
        <g>
          <path d="M13.369 6.25v3.433h.617v.184c0 .499.406.905.906.905h3.869a.906.906 0 00.905-.905v-.185h.936V8.167h.815c.06.853.771 1.53 1.641 1.53h.116v-.768h-.116a.88.88 0 010-1.759h.116v-.767h-.116c-.869 0-1.581.676-1.641 1.53h-.815V6.25h-.348v-.67h-.651v.67h-.463l-.8-2.531h-2.9V6.25h-2.071zm6.652-.012h-.184v-.425h.184v.425zm1.625 1.811c0-.74.571-1.348 1.296-1.408v.3a1.151 1.151 0 00-.105.016l-.024.006c-.026.006-.053.012-.079.02l-.037.013a1.596 1.596 0 00-.063.023l-.04.019-.055.026a2.203 2.203 0 00-.089.053l-.039.027-.045.034-.037.031-.041.038-.033.034a1.594 1.594 0 00-.068.079l-.034.045-.026.04a.409.409 0 00-.029.048.246.246 0 00-.023.042.41.41 0 00-.025.052l-.02.042-.021.057-.015.043a1.412 1.412 0 00-.016.062l-.011.042-.011.071-.005.036a1 1 0 000 .22l.005.036.011.071.011.042.016.062.015.043.021.057.02.043a.832.832 0 00.025.052l.023.042a.79.79 0 00.029.049l.026.039.034.045.03.037.038.042.033.034.042.038.036.031a.78.78 0 00.046.034l.038.027a.509.509 0 00.05.03l.039.023a.7.7 0 00.056.026c.013.006.025.013.039.018.021.009.042.016.063.023l.037.013c.026.008.053.014.079.021a.884.884 0 00.129.022v.301a1.421 1.421 0 01-1.296-1.412zm-5.43-3.583h1.667l.489 1.771h-2.156V4.466zm2.675 1.772h-.276l-.553-2.005h-2.079v2.005h-.308V3.952h2.493l.723 2.286zm-4.67 3.444v-.07a.671.671 0 01.671-.672h3.869a.67.67 0 01.671.672v.254c0 .37-.302.671-.672.671h-3.869a.673.673 0 01-.672-.671v-.184zm-.617-3.199h6.766v2.965h-.718a.906.906 0 00-.89-.742h-3.869a.907.907 0 00-.89.742h-.399V6.483z" />
          <path d="M18.146 9.682c-.003.019-.006.037-.006.057a.407.407 0 10.812 0c0-.02-.003-.038-.005-.057a.41.41 0 00-.118-.234.402.402 0 00-.566 0 .41.41 0 00-.117.234zm.228.057c0-.02.004-.039.011-.057a.17.17 0 01.324 0 .156.156 0 01.011.057.173.173 0 01-.346 0zM17 9.682c-.003.019-.007.037-.007.057a.408.408 0 00.814 0c0-.02-.004-.038-.007-.057a.4.4 0 00-.683-.234.403.403 0 00-.117.234zm.228.057c0-.02.004-.039.011-.057a.17.17 0 01.324 0 .18.18 0 01.011.057.173.173 0 01-.346 0zM15.853 9.682c-.003.019-.006.037-.006.057a.407.407 0 00.813 0c0-.02-.003-.038-.006-.057a.403.403 0 00-.801 0zm.228.057c0-.02.004-.039.011-.057a.17.17 0 01.324 0 .156.156 0 01.011.057.173.173 0 01-.346 0zM14.706 9.682c-.003.019-.006.037-.006.057a.407.407 0 00.813 0c0-.02-.003-.038-.006-.057a.405.405 0 00-.801 0zm.229.057a.172.172 0 11.345 0 .173.173 0 11-.345 0z" />
        </g>
      </g>
    </svg>
  </Tooltip>;
};

export default IcInfraestructuras;