import * as React from 'react';

import Tooltip from '@mui/material/Tooltip';
import {CATEGORY_GROUPS} from '../../../config';

const IcAprovechamiento = () => {
  return <Tooltip title={CATEGORY_GROUPS.find(cat => cat.id === 1)?.label}>
    <svg x="0px" y="0px" width="100%" height="100%" viewBox="0 0 24 24">
      <path fill="#38331F" d="M0 0h24v24H0z"/>
      <path
        fill="#FFF"
        d="M21.934 18.231l-7.785-5.485-.713 2.852-.862-.203-.184-.043-.037.158h-.001l.001.001.082.079-.083.046.261.1-.261.105.117.103-.103.077.91.214-.5 1.997h-.414v-1.656l-.135-.261-.08.083-.046-.083-.1.261-.105-.261-.102.117-.088-.117v1.917H8.43v-3.229h2.922l-3.25-9.449-2.87 8.344-1.514-4.4-2.356 6.852H3.39v1.882H1.706v.492H22.66v-.492h-.726zM8.102 7.067l2.561 7.443H8.43v-2.037l.775-.775-.348-.348-.427.427v-1.244h-.657v2.502l-.669-.669-.348.348 1.018 1.018v.778H5.542l2.56-7.443zm-6.051 8.789l1.667-4.845 1.254 3.643.12.348.293.854h-1.34v-1.329l.49-.489-.348-.348-.142.141v-.653h-.656v1.525l-.309-.308-.348.348.657.657v.457H2.051zm1.994.493h2.029l-.463-1.347h2.162v3.229H4.045v-1.882zm9.239 1.882l.471-1.884 2.026.478.577.932.418-.259-.318-.514 1.21.285.15-.639-2.436-.573.805-.498-.259-.418-1.223.757-.792-.187.537-2.148 6.627 4.669h-7.793z"
      />
      <path
        fill="#FFF"
        d="M19.405 7.155l-.021-.088-.09-.008c-.955-.078-1.583-.293-2.617-.815l.276-.507-.785-.465-.324.538-.307-.164-.105-.056-1.086 1.83.101.065.277.182-3.039 5.035 1.117.663 2.785-5.119c.908.632 1.387 1.078 1.9 1.858l.05.076.088-.024c.038-.011.95-.27 1.475-1.153.524-.884.315-1.809.305-1.848zm-3.151-1.542l.363.215-.164.3a26.424 26.424 0 01-.389-.203l.19-.312zm-3.548 7.403l-.677-.401 2.903-4.812c.159.105.308.205.447.299l-2.673 4.914zm3.001-4.992a17.809 17.809 0 00-.207-.142c-.137-.093-.283-.19-.438-.293l-.209-.138-.169-.111.841-1.418.19.102.221.118.398.208.223.114c.848.428 1.431.656 2.134.773.004.324-.15.783-.427 1.255l-.006.008-.007.012c-.282.469-.613.826-.896.976-.429-.548-.897-.944-1.648-1.464zm1.936 1.861a5.488 5.488 0 00-.136-.195c.295-.169.613-.502.894-.943l.002.001.068-.115.009-.015.063-.108h-.002c.253-.459.393-.895.399-1.237.077.01.156.019.237.026.036.239.092.925-.293 1.574-.383.647-1.014.928-1.241 1.012z"
      />
    </svg>
  </Tooltip>;
};

export default IcAprovechamiento;