import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import {Snackbar} from '@mui/material';
import PropTypes from 'prop-types';


const PushNotification = ({visible, message, actionText, onAction, onClose}) => {
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };
  
  const action =  <>
    <Button color="secondary" size="small" onClick={onAction}>
      {actionText}
    </Button>
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  </>;
  
  return <Snackbar
    open={visible}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    //autoHideDuration={10000}
    onClose={handleClose}
    message={message}
    action={action}
  />;
};

PushNotification.propTypes = {
  visible: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired  
};

export default PushNotification;

