import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import {Vega} from 'react-vega';
import {BASELINE_CO2, CO2_LABELS, FORESTRY_PROJECT} from '../../../../config';
import {PRIMARY_MAIN_GREEN} from '../../../../theme';

const BiomasCO2Chart = ({baselineData, projectData, selectedCo2Id}) => {

  const selectedCo2Label = CO2_LABELS.filter(l => l.id === selectedCo2Id)[0]?.label;

  const filteredData = (data) => data.filter(d => d.co2 === selectedCo2Label);

  const data = useMemo(() => baselineData && projectData
    ? [...filteredData(baselineData), ...filteredData(projectData)]
    : filteredData(baselineData), [baselineData, projectData]);

  const spec= useMemo (() => ({
    $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
    width: 900, height: 300,
    data: {
      values: data
    },
    layer: [
      {
        mark: 'line',
        encoding: {
          x: {
            field: 'year',
            type: 'ordinal',
            title: null,
            axis: { labelAngle: 90, grid: true}
          },
          y: {
            field: 'value',
            type: 'quantitative',
            title: null,
            axis: { labelAngle: 0, grid: false}
          },
          color: {
            field: 'type',
            scale: { domain: [BASELINE_CO2, FORESTRY_PROJECT], range: ['#49340e', PRIMARY_MAIN_GREEN]},
            type: 'nominal',
            title: 'Categoría de CO2',
            legend: null
          },
          strokeDash: {
            field: 'type',
            type: 'nominal',
            title: 'Tipo',
            legend: null
          },
          tooltip: [    // Configuración del tooltip
            { field: 'year', type: 'ordinal', title: 'Año' },
            { field: 'value', type: 'quantitative', title: 'Valor' },
            { field: 'co2', type: 'nominal', title: 'Categoría de CO2' },
            { field: 'type', type: 'nominal', title: 'Tipo' }
          ]
        }
      }
    ]
  }), [data]);
  
  return <Box mt={0.5} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1}}>
    <Vega spec={spec} data={data} actions={false} />
  </Box>;
};

BiomasCO2Chart.propTypes = {
  baselineData: PropTypes.array,
  projectData: PropTypes.array,
  selectedCo2Id: PropTypes.string.isRequired
};

export default BiomasCO2Chart;