import PropTypes from 'prop-types';

export const execution = PropTypes.shape({
  id: PropTypes.number,
  geom: PropTypes.object,
  who_made_it: PropTypes.string,
  executed_start_year: PropTypes.string,
  executed_end_year: PropTypes.string,
  executed_invest: PropTypes.string,
  executed_income: PropTypes.string,
  comments: PropTypes.string
});