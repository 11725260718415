import React from 'react';
import PropTypes from 'prop-types';

//MUI
import {lighten} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const IndicatorInterventionNumber = ({label, style, sublabels}) => {

  const getSummary = (param) => sublabels && sublabels
    .filter(sublabel => sublabel[param] !== undefined)
    .reduce((prev, curr) => prev + (curr[param]), 0);

  //STYLES
  const WIDTH = 200;
  const getSize = (param) => (param * WIDTH) / getSummary('planned_interventions');
  const plannedStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    bgcolor: style.background,
    alignItems: 'flex-end'
  };
  const executedStyle = {
    width: getSize(getSummary('executed_interventions')),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    bgcolor: style.color,
    alignItems: 'flex-end'
  };

  return <Card sx={{height: {sm: 205}, flexGrow: 1, width: {xs: '100%', sm: '48%', md: '30%'}}}>
    <CardHeader
      title={
        <Stack direction='column' alignItems='flex-start' sx={{m: 1}}>
          <Typography variant='body2' sx={{fontWeight: 'bold', color: style.color, ml: 1}}>{label.toUpperCase()}</Typography>
        </Stack>
      }
      sx={{bgcolor: style.background, m:0, p:0}}
    />
    <CardContent sx={{m: 1, p: 0}}>
      <Stack direction='column' justifyContent='flex-end' alignItems='flex-start' sx={{mb: 0.2}}>
        <Stack direction='row' justifyContent='flex-end' alignItems='center'>
          <Stack sx={{width: 30, mr: 2, alignItems: 'center'}}>
            {style.icon}
          </Stack>
          <Stack direction='row' sx={{width: WIDTH, height: 24, position: 'relative'}}>
            <Box sx={plannedStyle}/>
            <Stack direction='row' alignItems='center'>
              <Box sx={executedStyle}/>
              <Typography variant='h5'
                sx={{
                  color: getSize(getSummary('executed_interventions')) < (WIDTH - 50) ? lighten(style.color, 0.4) : 'white',
                  position: 'absolute',
                  fontWeight: 'bold',
                  left: getSize(getSummary('executed_interventions')) < (WIDTH - 50) ? `${getSize(getSummary('executed_interventions'))}px` : 10,
                  zIndex: 500,
                  ml: 1,
                }}>
                {getSummary('executed_interventions')}
              </Typography>
            </Stack>
          </Stack>
          <Typography variant='h5' sx={{
            color: lighten(style.color, 0.4),
            fontWeight: 'bold',
            ml: 1
          }}>{getSummary('planned_interventions')}</Typography>
        </Stack>
      </Stack>
      {
        sublabels.map((cat, index) => {
          return <Stack key={index} direction='row' justifyContent='flex-start' alignItems='center' sx={{ml: 6, mt: 0.5, '&:last-of-type': {mb: 1}}}>
            <Stack direction='row' sx={{width: getSize(cat.planned_interventions), height: 10, position: 'relative'}}>
              <Box sx={{
                width: getSize(cat.planned_interventions),
                height: '100%',
                position: 'absolute',
                display: 'flex',
                bgcolor: style.background,
                alignItems: 'flex-end'
              }}/>
              <Stack direction='row' alignItems='center'>
                <Box sx={{
                  width: getSize(cat.executed_interventions),
                  height: '100%',
                  position: 'absolute',
                  display: 'flex',
                  bgcolor: style.color,
                  alignItems: 'flex-end'
                }}/>
              </Stack>
            </Stack>
            <Typography
              variant='body2'
              sx={{
                color: (cat.planned_interventions === 0) ? 'grey.400' : lighten(style.color, 0.2),
                fontWeight: 'bold',
                ml: 1
              }}>
              {cat.executed_interventions} / {cat.planned_interventions} {cat.label}
            </Typography>
          </Stack>;
        })
      }
    </CardContent>
  </Card>;
};

IndicatorInterventionNumber.propTypes = {
  label: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  sublabels: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    planned_interventions: PropTypes.number,
    executed_interventions: PropTypes.number
  }))
};

export default IndicatorInterventionNumber;