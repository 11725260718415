import * as React from 'react';

import Tooltip from '@mui/material/Tooltip';
import {CATEGORY_GROUPS} from '../../../config';

const IcRegeneracion = () => {
  return <Tooltip title={CATEGORY_GROUPS.find(cat => cat.id === 3)?.label}>
    <svg x='0px' y='0px' width='100%' height='100%' viewBox='0 0 24 24' >
      <path fill="#81B829" d="M0 0h24v24H0z" />
      <g fill="#FFF">
        <path d="M10.178 18.246l-.197-.005a.539.539 0 01-.128-.044l-5.115-2.76a.532.532 0 01.495-.939l3.706 1.828c.126.109.277-.108.444-.064.091.022.185-.262.283-.262H13 9.666c-.029 0-.056.004-.083 0a.523.523 0 01-.303-.156.47.47 0 01-.145-.338c-.001-.292.238-.506.531-.506H13v.057l.058-.052c.041-.026.519-.338 1.195-.478.751-.155 1.798-.142 2.863.625l.346-.664C15.474 13.055 13.535 14 12.963 14H9.666c-.606 0-1.099.675-1.109 1.278L5.505 13.89c-.159-.083-.338-.082-.518-.082-.413 0-.79.251-.982.616a1.125 1.125 0 00.463 1.512l5.115 2.766c.159.084.338.131.518.131.046 0 .296-.003.34-.009 1.331.039 6.56.187 7.56.187v-.58c-1 0-4.45-.105-6.37-.16-.384-.01-1.256-.019-1.453-.025z" />
        <path d="M9.301 7.126c.303.086.628.13.968.13.786 0 1.48-.231 1.849-.382.18 1.238.209 2.408-.338 4.438-1.937.128-4.594 1.775-4.71 1.848l.307.491c.028-.018 2.853-1.77 4.635-1.77 1.775 0 4.362 1.746 4.388 1.764l.326-.479c-.105-.071-2.447-1.651-4.351-1.844.237-.907.362-1.656.411-2.315.282.081.81.203 1.426.203.541 0 1.038-.094 1.476-.281 1.446-.618 2.133-2.18 2.162-2.247l.121-.28-.286-.106a5.181 5.181 0 00-1.642-.272c-.541 0-1.037.095-1.476.282-.838.358-1.42 1.033-1.768 1.55a12.28 12.28 0 00-.136-1.245l.002-.002-.004-.006-.15-.256c-.018-.03-.229-.383-.611-.79-.414-.441-1.026-.946-1.813-1.171a3.413 3.413 0 00-.967-.133 5.072 5.072 0 00-2.126.508l-.27.141.154.263c.036.061.911 1.526 2.423 1.961zm5.494-.289a3.163 3.163 0 011.248-.235c.455 0 .86.077 1.124.143-.252.443-.82 1.271-1.707 1.649a3.163 3.163 0 01-1.248.236 4.49 4.49 0 01-.617-.045l1.883-.804-.228-.533-1.879.802c.311-.428.784-.939 1.424-1.213zM9.12 4.832c.286 0 .559.037.809.108.669.193 1.204.642 1.564 1.03l-1.966-.565-.16.557 1.967.565a4.15 4.15 0 01-1.065.15c-.286 0-.558-.037-.809-.108-.922-.265-1.59-1.018-1.896-1.426a4.426 4.426 0 011.556-.311z" />
        <circle cx={11.114} cy={12.794} r={0.121} />
        <circle cx={12.622} cy={12.287} r={0.133} />
        <circle cx={13.129} cy={13.12} r={0.145} />
        <path d="M18 14v6h2v-6h-2zm1 5h-1v-5h1v5z" />
      </g>
    </svg>
  </Tooltip>;
};

export default IcRegeneracion;