import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import DialogPanel from '../DialogPanel';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//UTILS
import {lighten} from '@mui/material/styles';
import {commoners} from '../proptypes/commoners';
import {fileDetail} from '../proptypes/fileDetail';

//STYLES
const headerSx = {
  height: '38px'
};

const tableHeader = {
  m: 0,
  p: 1,
  bgcolor: theme => lighten(theme.palette.primary.main, 0.80),
  borderBottom: theme => `1.5px solid ${theme.palette.primary.main}`,

};

const AcknowledgementFileDetail = ({
  fileDetail,
  commoners,
  isOpen,
  onClose
}) => {
  const recipients = fileDetail && fileDetail.recipients;
  const confirmationsUserIds = fileDetail && fileDetail?.confirmations?.map(confirmation => confirmation.user && confirmation.user.id);

  const commonerName = (id) => commoners.find(commoner => commoner.id === id)?.name.concat(' ').concat(commoners.find(commoner => commoner.id === id).last_name);

  return <DialogPanel isOpen={isOpen}
    label='DESTINATARIOS'
    fullWidth={true} onClose={onClose} sx={{p: 2}}>
    <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center'}}>
      <Typography SX={{pl: 1}}>DOCUMENTO: </Typography>
      <Chip label={fileDetail.name} size='small' variant='outlined' color='primary'/>
    </Box>

    <Table padding='none' size='small' stickyHeader sx={{border: theme => `1.5px solid ${theme.palette.primary.main}`, m:2, width: '95%'}}>
      <TableHead sx={headerSx}>
        <TableRow>
          <TableCell size='small' sx={tableHeader}>DESTINATARIO</TableCell>
          <TableCell size='small' sx={tableHeader}>NOMBRE</TableCell>
          <TableCell align='center' size='small' sx={tableHeader}>LEÍDO</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>

        {
          recipients && recipients.map(recipient => <TableRow key={recipient.id}>
            <TableCell sx={{p: 1}}>{recipient.role.localized_name}</TableCell>
            <TableCell sx={{p: 1}}>
              { commonerName(recipient.id) }
            </TableCell>
            <TableCell align='center' size='small'>
              {
                confirmationsUserIds.includes(recipient?.user?.id) ?
                  <CheckCircleIcon sx={{color: 'secondary.main', m: 0}}/> :
                  <CancelIcon sx={{color: 'grey.400', m: 0}}/>
              }
            </TableCell>
          </TableRow>
          )
        }

      </TableBody>
    </Table>
  </DialogPanel>;
};

AcknowledgementFileDetail.propTypes = {
  commoners: commoners,
  fileDetail: fileDetail,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default AcknowledgementFileDetail;