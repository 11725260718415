import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import {STATUS_COLORS} from '../config';


const StatusChip = ({status, label}) => {
  //STYLES
  const statusStyle = {
    color: 'white',
    backgroundColor: `${STATUS_COLORS[status] || STATUS_COLORS['DEFAULT']}`,
    padding: 0.5,
    margin: 0,
    borderRadius: 1,
    fontWeight: 'bold'
  };

  return <Typography
    variant='caption'
    component='span'
    sx={statusStyle}
  >
    {label}
  </Typography>;
};

StatusChip.propTypes = {
  status: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default StatusChip;
