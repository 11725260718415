import PropTypes from 'prop-types';

export const elastic_module = PropTypes.shape({
  mass_variables: PropTypes.shape({
    t: PropTypes.number,
    n: PropTypes.number,
    g: PropTypes.number,
    dc: PropTypes.number,
    do: PropTypes.number,
    hm: PropTypes.number,
    hc: PropTypes.number,
    ho: PropTypes.number,
    esb: PropTypes.number,
    cr: PropTypes.number,
    voc: PropTypes.number,
    in: PropTypes.number,
  }),
  technological_properties: PropTypes.shape({
    db_p: PropTypes.number,
    moe_est: PropTypes.number,
    moe_d: PropTypes.number,
    hmt: PropTypes.number,
    hmtab: PropTypes.number
  }),
  other_estimates: PropTypes.shape({
    rend: PropTypes.number,
    ce_sup: PropTypes.number,
    porc_estr: PropTypes.number,
    hum: PropTypes.number
  }),
  physiography_climate_soil: PropTypes.shape({
    altitud: PropTypes.number,
    pendiente: PropTypes.number,
    orientacion: PropTypes.number,
    tm: PropTypes.number,
    pm: PropTypes.number
  })
});