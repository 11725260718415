const indicatorInterventionsNumber = (filteredInterventionZones, options) => {

  if (!('categories' in options)) {
    throw new Error('Categories not passed as parameter!!');
  }

  return {
    total: {
      executed_interventions: filteredInterventionZones.reduce((acum, el) => acum + el.interventions.filter(int => int.done).length, 0),
      planned_interventions: filteredInterventionZones.reduce((acum, el) => acum + el.interventions.length, 0),
    },
    categoryGroups: options.categories.map(categoryGroup => ({
      id: categoryGroup.key,
      label: categoryGroup.label.es,
      categories: categoryGroup.values.map(category => {
        const filteredInterventionsByCategory = filteredInterventionZones.map(x => x.interventions).flat().filter(int => int.categories.includes(category.key));
        return {
          id: category.key,
          label: category.label.es,
          planned_interventions: filteredInterventionsByCategory.length,
          executed_interventions: filteredInterventionsByCategory.filter(int => int.done).length,
        };
      })
    })).sort((a, b) => a.categories.id > b.categories.id ? 1 : -1)
  };

};

export default indicatorInterventionsNumber;