'use strict';

class Calculator {

  constructor() {
    this._operations = [];
  }

  installOperation(operation) {
    if ('id' in operation && 'execute' in operation) {
      this._operations.push(operation);
    } else {
      throw new Error('Not id or execute found in operation');
    }
  }

  execute(interventionZones, params = undefined) {
    return this._operations.map(operation => {
      return {'id': operation.id, 'value': operation.execute(interventionZones, params)};
    });
  }
}

export default Calculator;