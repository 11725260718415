import {useMediaQuery} from '@mui/material';
import Hidden from '@mui/material/Hidden';
import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';

//MUI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DialogPanel from '../DialogPanel';
import StatusChip from '../StatusChip';
import Typography from '@mui/material/Typography';

//BIKENTA
import SectionTitle from '../Layout/SectionTitle';
import SelectAnnualPlanYearMenu from './SelectAnnualPlanYearMenu';

//UTILS
import {annualPlan} from '../proptypes/annualPlan';
import {SECONDARY_DARK_BROWN, SECONDARY_MAIN_BROWN} from '../../theme';
import {useTheme} from '@mui/material';
import {hasViewPermissions} from '../../modules/useCases';
import {ROLES_LABEL, VIEW_PERMISSIONS} from '../../config';

//STYLES
const annualPlanItemListStyle = {
  py: 0.5,
  cursor: 'default',
  '&:hover': {
    bgcolor: '#eeeeee'
  }
};
const newAnnualPlanButtonStyle = {
  mt: 2,
  bgcolor: SECONDARY_MAIN_BROWN,
  '&:hover': {
    bgcolor: SECONDARY_DARK_BROWN
  },
  '&.Mui-disabled': {
    color: '#fff'
  }
};

const AnnualPlanList = ({
  annualPlans,
  isOffline,
  isAnnualPlanListDialogOpen,
  role,
  onAnnualPlanCreate,
  onAnnualPlanClick,
  onAnnualPlanListDialogClose
}) => {
  const theme = useTheme();
  const widescreen = useMediaQuery(theme.breakpoints.up('lg'), {noSsr: true});
  const [isAnnualPlanYearMenuOpen, setAnnualPlanYearMenuOpen] = useState(false);
  const handleAnnualPlanYearMenuOpen = () => setAnnualPlanYearMenuOpen(true);
  const handleAnnualPlanYearMenuClose = () => setAnnualPlanYearMenuOpen(false);
  const handleAnnualPlanYearMenuCreate = (year) => {
    onAnnualPlanCreate && onAnnualPlanCreate(year);
    setAnnualPlanYearMenuOpen(false);
  };
  const handleAnnualPlanClick = (plan) => onAnnualPlanClick(plan);
  
  const annualPlanListTitle = 'PLANES ANUALES';
  
  const annualPlanListContent = useMemo(() => (
    <>
      {
        annualPlans && annualPlans.length !== 0 ? annualPlans.map(plan => <Stack
          className="annual-plan-list-item"
          direction="row"
          alignItems="center"
          key={plan.id}
          sx={annualPlanItemListStyle}
          onClick={() => handleAnnualPlanClick(plan)}
        >
          <Typography variant="body2" sx={{mx: 1}}>{plan.year}</Typography>
          <StatusChip status={plan.status} label={plan.get_status_display}/>
        </Stack>)
          :
          <Typography>No existe ningún Plan Anual.</Typography>
      }
      {
        widescreen && hasViewPermissions(VIEW_PERMISSIONS.EDIT_ANNUAL_PLAN, role) && <Button id='button-create-annual-plan'
          variant="contained" sx={newAnnualPlanButtonStyle} onClick={handleAnnualPlanYearMenuOpen} disabled={isOffline}>
      NUEVO PLAN ANUAL
        </Button>
      }
      {annualPlans && <SelectAnnualPlanYearMenu
        role={role}
        annualPlans={annualPlans}
        isAnnualPlanYearMenuOpen={isAnnualPlanYearMenuOpen}
        onClose={handleAnnualPlanYearMenuClose}
        onAnnualPlanCreate={handleAnnualPlanYearMenuCreate}
      />}
    </>
    
  ), [annualPlans, isAnnualPlanYearMenuOpen, widescreen]);
  
  return <>
    <Hidden lgDown>{/*DESKTOP*/}
      <SectionTitle title={annualPlanListTitle}/>
      {annualPlanListContent}
    </Hidden>
    <Hidden lgUp>{/*MOBILE*/}
      <DialogPanel
        isOpen={isAnnualPlanListDialogOpen}
        label={annualPlanListTitle}
        onClose={onAnnualPlanListDialogClose}
        fullWidth={true}
      >
        {annualPlanListContent}
      </DialogPanel>
    </Hidden>
  </>;
};

AnnualPlanList.propTypes = {
  annualPlans: PropTypes.arrayOf(annualPlan),
  isOffline: PropTypes.bool.isRequired,
  isAnnualPlanListDialogOpen: PropTypes.bool.isRequired,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)).isRequired,
  onAnnualPlanClick: PropTypes.func,
  onAnnualPlanCreate: PropTypes.func,
  onAnnualPlanListDialogClose: PropTypes.func,
};

export default AnnualPlanList;