import React from 'react';
import PropTypes from 'prop-types';

import GestureIcon from '@mui/icons-material/Gesture';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const ToogleScopeIntervention = ({scopeIntervention, onScopeChange}) => {
  const handleScopeIntervention = (e, scope) => scope && onScopeChange(scope);
  const toogleButton = {
    textTransform: 'none',
    '&$selected': {
      backgroundColor: 'transparent',
      color: 'primary.main',
      border: '2px solid primary.main',
      '&:hover': {
        color: 'primary.main'
      },
    },
  };
  return (
    <ToggleButtonGroup
      color='primary'
      value={scopeIntervention}
      exclusive
      onChange={handleScopeIntervention}
      size="small"
    >
      <ToggleButton sx={toogleButton} value="total">Total</ToggleButton>
      <ToggleButton sx={toogleButton} value="partial">
        <GestureIcon sx={{size: '28px', mr: 0.5}}/>Definir
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

ToogleScopeIntervention.propTypes = {
  scopeIntervention: PropTypes.oneOf(['total', 'partial']).isRequired,
  onScopeChange: PropTypes.func.isRequired,
};

ToogleScopeIntervention.defaultProps = {
  scopeIntervention: 'total',
};

export default ToogleScopeIntervention;
