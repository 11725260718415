import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//BIKENTA
import DialogPanel from '../DialogPanel';

const AnualPlanInProcedureAlert = ({isOpenInProcedureMenu, onCancelAnnualPlan, onClose, onAnnualPlanInProcedure}) => {

  return <DialogPanel isOpen={isOpenInProcedureMenu} label="EN EJECUCIÓN" onClose={onClose}>
    <Typography>¿Estás seguro de querere pasar a ejecución el Plan Anual?</Typography>
    <Typography>No se podrá deshacer.</Typography>
    <Stack direction='row' justifyContent='flex-end' sx={{mt:2}}>
      <Button color="secondary" onClick={onCancelAnnualPlan}>CANCELAR</Button>
      <Button variant="contained" color="secondary" onClick={onAnnualPlanInProcedure}>PASAR A EJECUCIÓN</Button>
    </Stack>
  </DialogPanel>;
};

AnualPlanInProcedureAlert.propTypes = {
  isOpenInProcedureMenu: PropTypes.bool,
  onCancelAnnualPlan: PropTypes.func,
  onClose: PropTypes.func,
  onAnnualPlanInProcedure: PropTypes.func
};

AnualPlanInProcedureAlert.defaultProps = {
  isOpenInProcedureMenu: false
};

export default AnualPlanInProcedureAlert;