import {BASE_URL} from '../modules/api/middleware';

const APPLICATION_JSON = 'application/json';
const USER_NOTIFICATIONS_URL = `${BASE_URL}/bm/usernotifications/`;

const GET_API_USER_NOTIFICATIONS = (token) => {
  return fetch(USER_NOTIFICATIONS_URL, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': APPLICATION_JSON,
      'Authorization': `Token ${token}`
    })
  }).then(response => {
    if ((response.status === 200 || response.status === 201) && response.ok) {
      return response.json();
    } else {
      return Promise.reject({
        status: response.status,
        message: response.statusText
      });
    }
  });
};

const POST_API_USER_NOTIFICATIONS = (notification_id, token) => {
  return fetch(`${USER_NOTIFICATIONS_URL}${notification_id}/read/`, {
    method: 'POST',
    headers: new Headers({
      'Authorization': `Token ${token}`
    })
  }).then(response => {
    if ((response.status === 204) && response.ok) {
      return Promise.resolve();
    } else {
      return Promise.reject({
        status: response.status,
        message: response.statusText
      });
    }
  });
};


export const getUserNotifications = (token) =>
  GET_API_USER_NOTIFICATIONS(token);

export const readUserNotification = (notification_id, token) =>
  POST_API_USER_NOTIFICATIONS(notification_id, token);