import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

import CheckIcon from '@mui/icons-material/Check';
import Categories from '../icons/Categories';

import grey from '@mui/material/colors/grey';
import {BACKGROUNDCOLOR_CATEGORIES} from '../../config';


const CategoryItem = ({label, categoryId, values, onSelectionChange, selectedIds}) => {
  const handleChange = (key) => {
    onSelectionChange([key].filter(x => !selectedIds.includes(x)));
    /*if (selectedIds.includes(key)) {
      onSelectionChange(selectedIds.filter(value => value !== key));
    } else {
      onSelectionChange([...selectedIds, key]);
    }*/
  };
  const form = {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 16px 24px 32px',
    backgroundColor: BACKGROUNDCOLOR_CATEGORIES[categoryId],
  };
  const labelStyle = {
    fontWeight: 900,
    color: 'black',
    mb: 2
  };
  const details = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  };
  const formLabel = {
    color: grey[600],
    pb: 1,
    display: 'flex',
    alignItems: 'flex-start',
  };
  const checkbox = {
    color: grey[400],
    pl: 2,
    pr: 1,
    py: 0,
  };
  const categoryIconContainer = {
    width: 57,
    margin: '0px, 3px, 0px, 0px',
  };
  return (
    <>
      <FormControl sx={form}>
        <FormLabel sx={labelStyle}>{label}</FormLabel>
        <Box sx={details}>
          <Box sx={categoryIconContainer}>
            <Categories categoryId={categoryId}/>
          </Box>
          <FormGroup sx={{width: '90%'}}>
            {
              values.map(({key, label}) => (
                <FormControlLabel
                  sx={formLabel}
                  label={<Typography sx={{lineHeight: 1.75}}>{label}</Typography>}
                  key={key}
                  onChange={() => handleChange(key)}
                  control={
                    <Checkbox
                      checked={selectedIds.includes(key)}
                      value={key}
                      name={label}
                      checkedIcon={<CheckIcon/>}
                      sx={checkbox}
                    />
                  }
                />
              ))}
          </FormGroup>
        </Box>
      </FormControl>
      <Divider/>
    </>
  );
};

CategoryItem.propTypes = {
  categoryId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    label: PropTypes.string,
  })).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
};
CategoryItem.defaultProps = {
  selectedIds: []
};

export default CategoryItem;