import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

//MUI
import Box from '@mui/material/Box';
import {PIE_INDICATOR_TYPE} from '../../config';


//BIKENTA
import AreaGroup from './AreaGroup';
import InterventionNumberGroup from './InterventionNumberGroup';
import IndicatorMoney from './IndicatorMoney';
import TotalIndicator from './TotalIndicator';
import AreaAgePerSpecieIndicator from './AreaAgePerSpecieIndicator';
import PieChart from './PieChart';

//UTILS
import {MAP_OFFSET, PIE_INDICATOR_LABEL } from '../../config';

//CALCULATIONS
import Calculator from '../../modules/calculator/calculator';
import indicatorInterventionsEconomicCalculation from '../../modules/calculator/indicators/indicatorInterventionsEconomic.calc';
import indicatorInterventionsAreaCalculation from '../../modules/calculator/indicators/indicatorInterventionsArea.calc';
import indicatorInterventionsNumberCalculation from '../../modules/calculator/indicators/indicatorInterventionsNumber.calc';
import areaSpecies from '../../modules/calculator/indicators/areaSpecies.calc';
import existences from '../../modules/calculator/indicators/existences.calc';
import areaAproveitamentoSpecies from '../../modules/calculator/indicators/areaAproveitamentoSpecies.calc';
import areaAgeSpecies from '../../modules/calculator/indicators/areaAgeSpecies.calc';
import existencesAproveitamento from '../../modules/calculator/indicators/existencesAproveitamento.calc';

const CALCULATOR_CONFIG = {
  AREA: 'area',
  AREA_AGE: 'age_area',
  AREA_APROVEITAMENTO: 'area-aproveitamento',
  EXISTENCES: 'existences',
  EXISTENCES_APROVEITAMENTO: 'existences-aproveitamento',
  ECONOMIC_VALUES: 'economicValues',
  INTERVENTION_AREA: 'interventionArea',
  INTERVENTION_NUMBER: 'interventionNumber'
};

const calculator = new Calculator();
calculator.installOperation({id: CALCULATOR_CONFIG.AREA, execute: areaSpecies});
calculator.installOperation({id: CALCULATOR_CONFIG.AREA_AGE, execute: areaAgeSpecies});
calculator.installOperation({id: CALCULATOR_CONFIG.AREA_APROVEITAMENTO, execute: areaAproveitamentoSpecies});
calculator.installOperation({id: CALCULATOR_CONFIG.EXISTENCES, execute: existences});
calculator.installOperation({id: CALCULATOR_CONFIG.EXISTENCES_APROVEITAMENTO, execute: existencesAproveitamento});
calculator.installOperation({id: CALCULATOR_CONFIG.ECONOMIC_VALUES, execute: indicatorInterventionsEconomicCalculation});
calculator.installOperation({id: CALCULATOR_CONFIG.INTERVENTION_AREA, execute: indicatorInterventionsAreaCalculation});
calculator.installOperation({id: CALCULATOR_CONFIG.INTERVENTION_NUMBER, execute: indicatorInterventionsNumberCalculation});

//STYLES
const dashboardWrapper = {
  position: 'relative',
  width: '100%',
  height: '100%',
  right: 0,
  overflow: 'scroll'
};
  
const dashboard = {
  position: 'absolute',
  right: 0,
  bottom: 0,
  width: {
    xs: '100%',
    lg: `calc(100% - ${MAP_OFFSET})`,
  },
  height: {
    xs: '100%',
    lg: 'calc(100% - 64px)',
  },
  pt: {
    xs: 11,
    lg: 1,
  },
  pb: {
    xs: 8,
    lg: 1
  },
  px: {
    xs: 1
  },
  bgcolor: {
    xs: 'grey.400',
    lg: 'grey.200',
  }
};

const Dashboard = ({
  categories,
  filteredInterventionZones, 
  interventionZones, 
  selectedCommunity
}) => {

  const calculations = useMemo(() => calculator
    .execute(
      filteredInterventionZones,
      {totalUnfiltered: interventionZones, categories, totalCommunityArea: selectedCommunity?.area}
    ),
  [filteredInterventionZones, interventionZones, categories, selectedCommunity]
  );

  const getData = (calculation) => calculations.find(calc => calc.id === calculation);

  return <Box sx={dashboard}>
    <Box sx={dashboardWrapper}>
      <>
        <TotalIndicator
          title='AREA COMUNIDAD'
          value={`${getData(CALCULATOR_CONFIG.INTERVENTION_AREA).value.total} Ha`}
        />
        <AreaGroup data={getData(CALCULATOR_CONFIG.INTERVENTION_AREA).value}/>  
      </>

      <>
        <TotalIndicator
          title='Nº INTERVENCIONES EJECUTADAS COMUNIDAD'
          value={`${getData(CALCULATOR_CONFIG.INTERVENTION_NUMBER).value.total.executed_interventions} / ${getData(CALCULATOR_CONFIG.INTERVENTION_NUMBER).value.total.planned_interventions}`}
        />
        <InterventionNumberGroup data={getData(CALCULATOR_CONFIG.INTERVENTION_NUMBER).value}/>
      </>

      <TotalIndicator title='DATOS GENERALES DE LAS ESPECIES DE LA COMUNIDAD'/>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2}}>
        <PieChart
          title={PIE_INDICATOR_LABEL.TREE_FEET}
          indicator={PIE_INDICATOR_TYPE.TREE_FEET}
          data={getData(CALCULATOR_CONFIG.EXISTENCES).value}/>
        <PieChart
          title={PIE_INDICATOR_LABEL.VOLUME_WITH_BARK}
          indicator={PIE_INDICATOR_TYPE.VOLUME_WITH_BARK}
          data={getData(CALCULATOR_CONFIG.EXISTENCES).value}/>
        <PieChart
          title={PIE_INDICATOR_LABEL.VOLUME_WITHOUT_BARK}
          indicator={PIE_INDICATOR_TYPE.VOLUME_WITHOUT_BARK}
          data={getData(CALCULATOR_CONFIG.EXISTENCES).value}/>
        <PieChart
          title={PIE_INDICATOR_LABEL.AREA}
          indicator={null}
          data={getData(CALCULATOR_CONFIG.AREA).value}/>
      </Box>
      
      <TotalIndicator title='ESPECIES CON INTERVENCIONES DE APROVECHAMIENTO'/>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2}}>
        <PieChart
          title={`${PIE_INDICATOR_LABEL.TREE_FEET}`}
          indicator={PIE_INDICATOR_TYPE.TREE_FEET}
          aproveitamento={true}
          data={getData(CALCULATOR_CONFIG.EXISTENCES_APROVEITAMENTO).value}/>
        <PieChart
          title={PIE_INDICATOR_LABEL.VOLUME_WITH_BARK}
          indicator={PIE_INDICATOR_TYPE.VOLUME_WITH_BARK}
          aproveitamento={true}
          data={getData(CALCULATOR_CONFIG.EXISTENCES_APROVEITAMENTO).value}/>
        <PieChart
          title={PIE_INDICATOR_LABEL.VOLUME_WITHOUT_BARK}
          indicator={PIE_INDICATOR_TYPE.VOLUME_WITHOUT_BARK}
          aproveitamento={true}
          data={getData(CALCULATOR_CONFIG.EXISTENCES_APROVEITAMENTO).value}/>
        <PieChart
          title={PIE_INDICATOR_LABEL.AREA}
          aproveitamento={true}
          data={getData(CALCULATOR_CONFIG.AREA_APROVEITAMENTO).value}/>
      </Box>
      
      <Box sx={{mb:2}}>
        <TotalIndicator title='SUPERFICIE POR ESPECIE Y GRUPO DE EDAD'/>
        <AreaAgePerSpecieIndicator data={getData(CALCULATOR_CONFIG.AREA_AGE).value}/>
      </Box>
      
      <TotalIndicator title='DATOS ECONÓMICOS'/>
      <IndicatorMoney data={getData(CALCULATOR_CONFIG.ECONOMIC_VALUES).value}/>
      
    </Box>
  </Box>;
};

Dashboard.propTypes = {
  categories: PropTypes.array,
  filteredInterventionZones: PropTypes.arrayOf(
    PropTypes.object
  ),
  interventionZones: PropTypes.arrayOf(
    PropTypes.object
  ),
  selectedCommunity: PropTypes.object
};

export default Dashboard;