import PropTypes from 'prop-types';

export const business = PropTypes.arrayOf(PropTypes.shape({
  address: PropTypes.string,
  cif: PropTypes.string,
  cnae: PropTypes.string,
  country: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  resfor_number: PropTypes.number,
  type: PropTypes.shape({
    type: PropTypes.string,
    display_name: PropTypes.string
  }),
}));