import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

//MUI-ICONS
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import MapIcon from '@mui/icons-material/Map';

//BIKENTA
import LazyLoading from '../../LazyLoading';

//UTILS
import round from '../../../utils/round';
import {ELASTIC_MODULE_GROUPS, ELASTIC_MODULE_PARAMS} from '../../../config';
import {prospectingPoints} from '../../proptypes/prospectingPoints';
import {useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/styles';
import {getMaderaPlusValues} from '../../../services/maderaPlus';
import {from4326_to25829} from '../../../utils/from4326_to25826';
import {lighten} from '@mui/material/styles';
import {PRIMARY_MAIN_GREEN} from '../../../theme';

//STYLES
const rowHeaderSx = {
  '& th:first-of-type': {
    '& button': {
      display: 'none'
    }
  },
  '& th:nth-of-type(2)': {
    '& button': {
      display: 'none'
    }
  }
};

const cellHeaderSx = {
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  fontWeight: 'bolder',
  fontSize: '12px',
  '&:nth-of-type(3)': { //Average column
    bgcolor: 'grey.100',
    borderRight: theme => `2px solid ${theme.palette.primary.main}`,
    borderLeft: theme => `2px solid ${theme.palette.primary.main}`,
    borderTop: theme => `2px solid ${theme.palette.primary.main}`,
  }
};

const rowBodySx = {
  '&:first-of-type': {
    borderTop: '2px solid grey',
    bgcolor: lighten(PRIMARY_MAIN_GREEN, 0.75)
  },
  '&:nth-of-type(2)': {
    bgcolor: lighten(PRIMARY_MAIN_GREEN, 0.75)
  },
  '&:nth-of-type(3)': {
    bgcolor: lighten(PRIMARY_MAIN_GREEN, 0.75)
  },
  '&:nth-of-type(4)': {
    borderTop: '2px solid grey'
  },
  '&:nth-of-type(12)': {
    borderTop: '2px solid grey'
  },
  '& .MuiTableCell-root': {
    '&:first-of-type': { //MapIcon column
      textAlign: 'right',
      maxWidth: '32px',
      fontWeight: 'bold'
    },
    '&:nth-of-type(2)': { //Labels column
      textAlign: 'right',
      maxWidth: '150px',
      fontWeight: 'bold'
    },
    '&:nth-of-type(3)': { //Average column
      minWidth: {
        xs: 25,
        sm: 40
      },
      fontWeight: 'bold',
      bgcolor: 'grey.100',
      borderRight: theme => `2px solid ${theme.palette.primary.main}`,
      borderLeft: theme => `2px solid ${theme.palette.primary.main}`
    }
  }
};

const MaderaPluspProspectionPointsCalculation = ({prospectingPoints, specie, district, onMaderaPlusEnd, onDeletePoint, onViewMapParam}) => {
  const theme = useTheme();
  const widescreen = useMediaQuery(theme.breakpoints.up('lg'), {noSsr: true});

  const [prospectingPointsWithValues, setProspectingPointsWithValues] = useState([]);

  const isLoading = useMemo(() => {
    if (prospectingPoints.length) {
      return !!prospectingPoints.filter(point => !Object.keys(point.properties).length && !prospectingPointsWithValues.map(({id}) => id).includes(point.id)).length;
    } else {
      return false;
    }
  }, [prospectingPoints, prospectingPointsWithValues]);

  useEffect(() => {
    if (prospectingPoints.length) {
      //Hacemos llamada a maderaPlus para cada punto que no tenga variables
      const pendingPoints = prospectingPoints.filter(point => !Object.keys(point.properties).length && !prospectingPointsWithValues.map(({id}) => id).includes(point.id));
      if (pendingPoints.length) {
        for (const point of pendingPoints) {
          if (!Object.keys(point.properties).length) {
            const {x, y} = from4326_to25829({
              x: point.geometry.coordinates[0],
              y: point.geometry.coordinates[1],
            });
            getMaderaPlusValues(x, y, specie.id, district)
              .then(variables => {
                setProspectingPointsWithValues(prospectingPointsWithValues.concat({...point, properties: variables}));
              })
              .catch(() => onDeletePoint(point.id));
          }
        }
      }
    }
  }, [prospectingPoints]);
  
  const headers = prospectingPointsWithValues.length && ['', '', 'Media', ...prospectingPointsWithValues.map(point => (point.id))];//['', 'Media', P1, P2, P3]
  const paramsId = Object.keys(ELASTIC_MODULE_PARAMS);//['t', 'n', 'g']*/

  const labels = paramsId.map(param => ELASTIC_MODULE_PARAMS[param]?.label);//['Edad', 'Pies', 'A.Basimétrica']

  const paramsPerPoint = prospectingPointsWithValues.length &&
    prospectingPointsWithValues.map(point => Object.values(point.properties).map(item => item));//[[t, n, g],[t, n, g]]

  const paramRows = prospectingPointsWithValues.length &&
    paramsPerPoint[0].map((_, i) => paramsPerPoint.map(row => row[i]));//[[t, t, t], [n, n, n]]

  const averageParams = prospectingPointsWithValues.length &&
    paramRows.map(param => round((param.reduce((prev, curr) => curr + prev)) / param.length));//[avg, avg, avg...]

  const rows = prospectingPointsWithValues.length &&
    paramRows.map((row, i) => [labels[i], averageParams[i], ...row]);//[['Edad', avg, t, t], ['Pies', avg, n, n], ...]

  const savedAverageParams = Object.fromEntries(
    paramsId.map((param, i) => [param, averageParams[i]])
  );//{t: 24, n: 56}

  const savedAverageParamsPerGroup = Object.entries(ELASTIC_MODULE_GROUPS)
    .reduce((acumulador, [clave, valor]) => {
      acumulador[clave] = valor.reduce((acumuladorInterno, variable) => {
        acumuladorInterno[variable] = savedAverageParams[variable];
        return acumuladorInterno;
      }, {});
      return acumulador;
    }, {});

  const handleClick = () => onMaderaPlusEnd(specie.id, savedAverageParamsPerGroup);
  const handleDeletePoint = (deletePointId) => {
    const noDeletePoints = prospectingPointsWithValues.filter(point => point.id !== deletePointId);
    onDeletePoint(deletePointId);
    setProspectingPointsWithValues(noDeletePoints);
  };

  const hasMapParam = (paramName) => {
    for (const key in ELASTIC_MODULE_PARAMS) {
      if (ELASTIC_MODULE_PARAMS[key].label === paramName) {
        return ELASTIC_MODULE_PARAMS[key].map;
      }
    }
    return null;
  };

  const handleViewMapParam = (paramName, specieId) => {
    for (const key in ELASTIC_MODULE_PARAMS) {
      if (ELASTIC_MODULE_PARAMS[key].label === paramName) {
        onViewMapParam(ELASTIC_MODULE_PARAMS[key].id, specieId);
      }
    }
    return null;
  };

  return <>
    <LazyLoading isOpen={isLoading} />
    <Typography variant={widescreen ? 'h6' : 'button'} gutterBottom color={widescreen ? undefined : 'primary'}>
      {widescreen ? `Cálculo del módulo de elasticidad del ${specie.label}` : `${specie.label}`}
    </Typography>
    <Typography gutterBottom>Haz click dentro de la zona de intervención para elegir los puntos sobre los que se realice el cálculo y luego haz click en Guardar el Cálculo.</Typography>
    {
      !!prospectingPointsWithValues.length && <TableContainer sx={{width: '100%', height: {xs: '100%', lg: '75vh'}}}>
        <Table size='small'>
          <TableHead>
            <TableRow sx={rowHeaderSx}>
              {
                headers.map((column, index) => <TableCell key={index} align='center' sx={cellHeaderSx}>
                  <Stack sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography>{column}</Typography>
                    <IconButton onClick={() => handleDeletePoint(column)} size='small' sx={{color: 'grey.400', width: 30, height: 30}}>
                      <DeleteIcon/>
                    </IconButton>
                  </Stack>
                </TableCell>)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => <TableRow key={index} sx={rowBodySx}>
              <TableCell sx={{width: '40px'}}>
                {
                  hasMapParam(row[0]) && <IconButton onClick={() => handleViewMapParam(row[0], specie.id)} size='small'
                    sx={{color: 'grey.500', width: 15, height: 15}}>
                    <MapIcon/>
                  </IconButton>
                }
              </TableCell>
              {row.map((value, index) => <TableCell key={index} align='center'>{value}</TableCell>)}
            </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    }
    <Button sx={{mt: 5, '&.Mui-disabled': {color: 'white'}}} variant="contained" startIcon={<SaveIcon/>} onClick={handleClick} disabled={!prospectingPoints.length}>Guardar el cálculo</Button>
  </>;
};

MaderaPluspProspectionPointsCalculation.propTypes = {
  specie: PropTypes.object,//TODO
  district: PropTypes.number,
  onMaderaPlusEnd: PropTypes.func,
  prospectingPoints: prospectingPoints,
  onDeletePoint: PropTypes.func,
  onViewMapParam: PropTypes.func
};

export default MaderaPluspProspectionPointsCalculation;