import React from 'react';
import PropTypes from 'prop-types';

import {CATEGORY_GROUPS, DEFAULT_CATEGORY_GROUP_ICON} from '../../config';

const Categories = ({categoryId}) => {
  return <>
    {CATEGORY_GROUPS.find(cat => cat.id === categoryId)?.icon || DEFAULT_CATEGORY_GROUP_ICON}
  </>;
};

Categories.propTypes = {
  categoryId: PropTypes.number
};

export default Categories;