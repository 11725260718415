import PropTypes from 'prop-types';
import {STATUS_COLORS} from '../../config';

export const selectedCommunity = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  status: PropTypes.oneOf(Object.keys(STATUS_COLORS)),
  get_status_display: PropTypes.string,
  owner: PropTypes.string,
  vat_number: PropTypes.string,
  address: PropTypes.string,
  company: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    legal_name: PropTypes.string,
  }),
  name_contact: PropTypes.string,
  email: PropTypes.string,
  phone_number: PropTypes.string,
  get_certification_type_display: PropTypes.string,
  fsc_entry_date: PropTypes.string,
  fsc_leaving_date: PropTypes.string,
  pefc_entry_date: PropTypes.string,
  pefc_leaving_date: PropTypes.string,
  province: PropTypes.string,
  card_image: PropTypes.string,
  geometry: PropTypes.shape({
    type: PropTypes.string,
    coordinates: PropTypes.array
  }),
  certification_type: PropTypes.string,
  fsc_code: PropTypes.string,
  pefc_code: PropTypes.string,
  project_aproval_date: PropTypes.string,
  validity_date_project: PropTypes.string,
  exist_agreement_or_consortium: PropTypes.string,
  public_observations: PropTypes.string,
  private_observations: PropTypes.string,
  files: PropTypes.array,
});