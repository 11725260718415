import React, {useState} from 'react';
import PropTypes from 'prop-types';

import DialogPanel from '../../DialogPanel';
import EditGeometryMap from './EditGeometryMap';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const EditInterventionGeometryMenu = ({onAcceptFeature, onCancel, ...otherProps}) => {
  const [feature, setFeature] = useState(null);
  const handleModalClose = () => {};
  const handleCancel = () => onCancel();
  const handleSave = () => onAcceptFeature(feature);
  const handleFeatureInput = (feat) => setFeature(feat);
  const container = {
    height: '75vh',
    margin: '0px 10px'
  };
  return <DialogPanel
    isOpen={true}
    label='DEFINIR NUEVA GEOMETRÍA'
    fullWidth
    onClose={handleModalClose}
  >
    <Box sx={container}>
      <EditGeometryMap onFeatureInput={handleFeatureInput} {...otherProps} />
    </Box>
    <Box display='flex' flexDirection='row' justifyContent='flex-end' mt={1} mr={1.2}>
      <Button color="secondary" onClick={handleCancel}>CANCELAR</Button>
      <Button color="secondary" variant='contained' disabled={!feature} onClick={handleSave}>GUARDAR</Button>
    </Box>
  </DialogPanel>;
};

EditInterventionGeometryMenu.propTypes = {
  ...EditGeometryMap.propTypes,
  onAcceptFeature: PropTypes.func,
  onCancel: PropTypes.func
};

export default EditInterventionGeometryMenu;