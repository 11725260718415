import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

//UTILS
import {MAP_DETAILS, MAP_DETAIL_STYLES} from '../../config';
import Box from '@mui/material/Box';

const BusinessCard = ({feature}) => {
  const BUSINESS_STYLE = MAP_DETAIL_STYLES.find(style => style.id === MAP_DETAILS.business).colors.find(item => item.id === feature.type);
  return <Box>
    <CardHeader
      avatar={ <Avatar sx={{ bgcolor: BUSINESS_STYLE.paint.color}}>{BUSINESS_STYLE.icon}</Avatar> }
      title={feature.name}
      subheader={feature.cnae}
      sx={{
        p: 1,
        '& .MuiCardHeader-title': {
          fontSize: '1rem'
        },
        '& .MuiCardHeader-action': {
          fontSize: '0.75rem',
          ml: 4
        }
      }}
    />
    <CardContent sx={{
      pl: 8,
      pb: 0,
      '&.MuiCardContent-root:last-child': {
        pt: 0,
        pb: 1
      }
    }}>
      <Typography variant='caption' sx={{p: 0, m: 0}}>{`resforNº: ${feature.resfor_number}`}</Typography>
    </CardContent>
  </Box>;
};

BusinessCard.propTypes = {
  feature: PropTypes.shape({
    address: PropTypes.string,
    cif: PropTypes.string,
    cnae: PropTypes.string,
    country: PropTypes.string,
    name: PropTypes.string,
    resfor_number: PropTypes.number,
    state: PropTypes.string,
    type: PropTypes.string,
  })
};

export default BusinessCard;