import calculateAgeGroup from '../../../utils/calculateAgeGroup';
import round from '../../../utils/round';

const GROUP_AGE_RANGE = 5;

const areaAgeSpecies = (interventionZones, options) => {

  if (options.totalUnfiltered === undefined) {
    throw new Error('Total interventions zones unfiltered not passed as parameter!!');
  } else {

    //genera array de lo necesario para vegalite, diferenciado por especie y ageGroup
    const speciesFormattedWithoutGrouped = options.totalUnfiltered && options.totalUnfiltered
    //filtra las que no tienen especies
      .filter(interventionZone => interventionZone.species && interventionZone.species.length !== 0)
    //filtra las que no tienen area
      .filter(interventionZone => interventionZone.area)
    //mapea el rest
      .map(interventionZone => (
        {...interventionZone,
          species: interventionZone.species
            .map(specie => (
              {
                ...specie,
                ageGroup: calculateAgeGroup(specie.age)
              }
            ))
        }
      ))
      .flatMap(iz => Array.from(new Set(iz.species.map(({ageGroup}) => ageGroup))).map(ageGroup =>
        iz.species
          .filter(sp => sp.ageGroup === ageGroup)
          .reduce((acc, specie, index, filteredSpecies) => {
            return {
              label: [...acc.label, specie.label].sort(),
              area: ((iz.area / iz.species.length) * filteredSpecies.length),
              age: [...acc.age, specie.age],
              ageGroup: ageGroup
            };
          }, {
            label: [],
            area: 0,
            age: [],
            ageGroup: ''
          })
      ))
      .map(formattedSpecie => ({
        ...formattedSpecie,
        label: formattedSpecie.label.length > 1 ?
          `Mixta (${formattedSpecie.label.sort().slice(0).join( ', ' )})` :
          formattedSpecie.label[0],
        age: (formattedSpecie.age.reduce((tot, value) => tot + value, 0)/formattedSpecie.age.length)
      }));

    const minAge = Math.min(...(speciesFormattedWithoutGrouped.map(item => item.age)));
    const maxAge = Math.max(...(speciesFormattedWithoutGrouped.map(item => item.age)));
    const rangeAllGroups = [...Array((maxAge % GROUP_AGE_RANGE === 0 ? Math.ceil(maxAge / GROUP_AGE_RANGE) + 1 : Math.ceil(maxAge / GROUP_AGE_RANGE)) - Math.floor(minAge / GROUP_AGE_RANGE)).keys()].map(item =>
      calculateAgeGroup(minAge + (item * GROUP_AGE_RANGE))
    );

    //aqui generamos array por cada especie y subarray por cada ageGroup existente y tambiñen lo rellenamos con valores por defecto.
    const speciesFormattedGrouped = Array.from(new Set(speciesFormattedWithoutGrouped.map(({label}) => label)))
      .map(label =>
        Array.from(new Set(speciesFormattedWithoutGrouped.filter(specie => specie.label === label).map(({ageGroup}) => ageGroup)))
          .map(ageGroup => {
            const filtered = speciesFormattedWithoutGrouped.filter(specie => specie.label === label && specie.ageGroup === ageGroup);
            const area = round(filtered.reduce((acc, cur) => acc + cur.area, 0));
            const age = Math.round(filtered.reduce((acc, cur) => acc + cur.age, 0) / filtered.length);
            return {
              label,
              ageGroup,
              area,
              age
            };
          })
      )
      .map(species =>
        rangeAllGroups.map(ageGroup =>
          species.filter(specie => specie.ageGroup === ageGroup).length === 1 ?
            species.find(specie => specie.ageGroup === ageGroup) :
            {
              label: species[0].label,
              area: 0,
              age: 0,
              ageGroup: ageGroup
            }
        )
      );

    return speciesFormattedGrouped;
  }
};

export default areaAgeSpecies;