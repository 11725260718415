import {getCredentials as getStateCredentials} from './modules/api/selectors';
import {removeCredentials, setCredentials} from './services/localStorage';

function observeStore(store, selector, onChange, equalsFunc) {
  let currentState = false;

  function handleChange() {
    let nextState = selector(store.getState());
    if (!equalsFunc(nextState, currentState)) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  let unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
}

const persistCredentials = store => {
  const persistCredentials = () => {
    const {username, token, maderaPlus_cookie} = getStateCredentials(store.getState());
    if (username && token) {
      setCredentials(username, token, maderaPlus_cookie);
    } else {
      removeCredentials();
    }
  };

  const equals = (next, current) => next.username === current.username && next.token === current.token;
  return observeStore(store, getStateCredentials, persistCredentials, equals);
};

const setObservers = store => {
  persistCredentials(store);
};

export default setObservers;
