import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DoneIcon from '@mui/icons-material/Done';

//UTILS
import {intervention} from '../../proptypes/intervention';
import {annualPlan} from '../../proptypes/annualPlan';
import {ANNUAL_PLAN_STATUS, ROLES_LABEL, VIEW_PERMISSIONS} from '../../../config';
import {hasViewPermissions} from '../../../modules/useCases';

//STYLES
const editablePlanWrapper = {
  border: theme => `1px solid ${theme.palette.grey[400]}`,
  borderRadius: 0.5,
  mt: 1.25,
};
const planWrapper = {
  borderRadius: 0.5,
  mt: 1.25,
  backgroundColor: 'grey.200',
};
const accordionEditable = {
  backgroundColor: 'white',
};
const accordion = {
  backgroundColor: 'grey.200',
  pt: 0
};
const titleStyle = {
  fontWeight: 'bold'
};
const nonEditableTitle = {
  fontWeight: 'bold',
  color: 'grey.600',
  mr: 2
};


const AnnualPlanPanel = ({
  intervention,
  role,
  selectedAnnualPlan,
  annualPlansAssignedInSelectedIntervention,
  onAnnualPlanUpdateIntervention
}) => {

  const handleAnnualPlanUpdateIntervention = () => onAnnualPlanUpdateIntervention(intervention.id);

  return (
    <Box sx={selectedAnnualPlan ? editablePlanWrapper : planWrapper}>
      <Accordion sx={selectedAnnualPlan ? accordionEditable : accordion} elevation={0} disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography color="secondary" sx={selectedAnnualPlan ? titleStyle : nonEditableTitle}>{selectedAnnualPlan ? `PLAN ANUAL ${selectedAnnualPlan.year}` : 'PLAN ANUAL'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {
            selectedAnnualPlan && selectedAnnualPlan.status !== ANNUAL_PLAN_STATUS.COMPLETED && !intervention.done ?
              <>
                {
                  hasViewPermissions(VIEW_PERMISSIONS.EDIT_ANNUAL_PLAN, role) && <>
                    <Stack direction="row" alignItems="center" sx={{ml: 1, mb: 2}}>
                      {selectedAnnualPlan.status === ANNUAL_PLAN_STATUS.DRAFT && <Checkbox
                        checked={selectedAnnualPlan.interventions.includes(intervention.id)}
                        onChange={(e) => handleAnnualPlanUpdateIntervention({annualPlanIncluded: e.target.checked})}
                        icon={<CheckBoxOutlineBlankIcon color="disabled"/>}
                        checkedIcon={<DoneIcon color="secondary"/>}
                      />}
                      <Typography>Intervención incluida en el Plan Anual de {selectedAnnualPlan.year}</Typography>
                    </Stack>

                  </>
                }
                {
                  annualPlansAssignedInSelectedIntervention.length !== 0 && <>
                    <Divider sx={{my: 1}}/>
                    <Typography varian='caption' sx={{ml: 1, mt: 2, mb: 1}}>
                    INCLUIDA EN LOS SIGUIENTES PLANES ANUALES:
                    </Typography>
                  </>
                }
                {
                  annualPlansAssignedInSelectedIntervention
                    .map(plan => <Typography key={plan.id} sx={{ml: 2, mb: 1}}>Plan Anual {plan.year}</Typography>)
                }
              </> :
              <>
                {
                  annualPlansAssignedInSelectedIntervention.length === 0 ?
                    <Typography>No está incluida en ningún Plan Anual.</Typography>
                    :
                    <>
                      <Typography varian='caption' sx={{ml: 1, mt: 2, mb: 1}}>INTERVENCIÓN INCLUIDA EN LOS SIGUIENTES
                        PLANES ANUALES:</Typography>
                      {
                        annualPlansAssignedInSelectedIntervention
                          .map(plan => <Typography key={plan.id} sx={{ml: 2, mb: 1}}>Plan Anual {plan.year}</Typography>)
                      }
                    </>
                }
              </>
          }
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

AnnualPlanPanel.propTypes = {
  annualPlansAssignedInSelectedIntervention: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    year: PropTypes.number
  })),
  intervention: intervention,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)),
  selectedAnnualPlan: annualPlan,
  onAnnualPlanUpdateIntervention: PropTypes.func
};

export default AnnualPlanPanel;