const calculateAgeGroup = (age) => {
  if (age < 0) return 'Unknown';

  const lowerBound = age < 6 ? 0 : age % 5 === 0 ? age-4 : (Math.floor(age / 5) * 5)+1;
  const upperBound =  age < 6 ? lowerBound + 5 : lowerBound + 4;

  return `${lowerBound}-${upperBound}`;

};

export default calculateAgeGroup;