import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';

import Carousel from '../../Carousel';
import ExistencesCard from './ExistencesCard';
import Typography from '@mui/material/Typography';

const MenuExistences = ({species}) => {

  const plantedSpecies = useMemo(() => {
    return species.filter(specie => (specie.end_date === null) && (specie.start_date !== null));
  }, [species]);

  const hasPlantedSpecies = useMemo(() => plantedSpecies.length > 0, [plantedSpecies]);

  return <>
    <Hidden lgUp>{/*MOBILE*/}
      { hasPlantedSpecies ?
        <Carousel>
          {
            plantedSpecies.map(
              specie => <Box key={specie.id} sx={{display: 'inline-block'}}>
                <Paper elevation={3} sx={{width: 200, m: 0.5, p: 0.5}}>
                  <ExistencesCard id={specie.id} label={specie.label} existences={specie.existences}/>
                </Paper>
              </Box>
            ) 
          }
        </Carousel>
        : <Typography sx={{color: 'error.main', textAlign: 'end', mt: 1}}>No hay especies plantadas</Typography>
      }
    </Hidden>
    <Hidden lgDown>{/*DESKTOP*/}
      <Box sx={{display: 'flex', flexWrap: 'wrap', maxHeight: 250, overflow: 'auto', mb: 1.5}}>
        {
          hasPlantedSpecies ?
            plantedSpecies.map(
              specie => <ExistencesCard key={specie.id} id={specie.id} label={specie.label} age={specie.age} existences={specie.existences}/>
            ) : <Typography sx={{color: 'error.main', textAlign: 'end', mt: 1}}>No hay especies plantadas.</Typography>
        }
      </Box>
    </Hidden>
  </>;
};

MenuExistences.propTypes = {
  species: PropTypes.arrayOf(PropTypes.shape({
    ...ExistencesCard.propTypes
  })).isRequired
};

export default MenuExistences;