import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {BASELINE_CO2, FORESTRY_PROJECT} from '../../../../config';

const TotalTons = ({number, label, size='large'}) => {

  const textColor = label === FORESTRY_PROJECT.toUpperCase() ? 'primary.main' : label === BASELINE_CO2.toUpperCase() ? '#49340e' : '#4c7ed6';

  const numberSx = {
    fontSize: size === 'large' ? 40 : 30,
    fontWeight: 900,
    color: textColor,
    lineHeight: 1
  };

  return <Box mt={2} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
    <Typography sx={numberSx}>{number} T CO<span style={{fontSize: size === 'large' ? 20 : 15}}>2</span></Typography>
    <Typography variant='body1' sx={{fontWeight: 900, color: textColor}}>{label}</Typography>
  </Box>;
};

TotalTons.propTypes = {
  number: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'large']),
};

export default TotalTons;