import React, {useState} from 'react';
import PropTypes from 'prop-types';

//GEOCOMPONENTS
import BottomSheet from '@geomatico/geocomponents/BottomSheet';

//MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';

import AlertDeleteDialog from '../AlertDeleteDialog';
import IconButton from '@mui/material/IconButton';
import SectionTitle from '../Layout/SectionTitle';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';

import {lighten} from '@mui/material/styles';
import {useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material';

//MUI-ICONS
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

//UTILS
import {intervention} from '../proptypes/intervention';
import CreateBatchMenu from './CreateBatchMenu';
import AddButton from '../AddButton';

const BatchList = ({
  batches,
  interventions,
  isBatchMenuOpen,
  isOffline,
  onBatchExit,
  onBatchMode,
  onBatchModeMenuClose,
  onDeleteBatch,
  onEditBatch,
  onCreateBatch
}) => {

  const theme = useTheme();
  const widescreen = useMediaQuery(theme.breakpoints.up('lg'), {noSsr: true});

  const [batchSelectedId, setBatchSelectedId] = useState(undefined);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(undefined);
  const [isEditingBatchName, setIsEditingBatchName] = useState({id: undefined, name: undefined});
  const [isBatchAddDialogOpen, setIsBatchAddDialogOpen] = useState(false);

  const handleBatchMode = (batchId) => {
    if (batchSelectedId !== batchId) {
      setBatchSelectedId(batchId);

    } else {
      setBatchSelectedId(undefined);
    }
    !widescreen && onBatchModeMenuClose;

    const interventionZoneIds = [];

    interventions.forEach(intervention => {
      if (intervention.batch && intervention.batch.id === batchId) {
        interventionZoneIds.push(intervention.intervention_zone);
      }
    });
    onBatchMode(batchId, interventionZoneIds);
  };

  const rowSx = (batchId) => {
    return {
      bgcolor: batchId === batchSelectedId ? theme => lighten(theme.palette.primary.main, 0.80) : undefined,
      '&:hover': {
        color: batchId === batchSelectedId ? theme => lighten(theme.palette.primary.main, 0.80) : undefined
      },
    };
  };

  //DELETE BATCH
  const handleAlertDelete = (batchId) => setIsAlertDialogOpen(batchId);

  const handleCancelDelete = () => setIsAlertDialogOpen(undefined);

  const handleDelete = () => {
    setIsAlertDialogOpen(undefined);
    onDeleteBatch(isAlertDialogOpen);
  };

  //EDIT BATCH
  const handleEditBatchStart = (batchId, batchName) => setIsEditingBatchName({id: batchId, name: batchName});
  const handleEditBatchEnd = () => setIsEditingBatchName({id: undefined, name: undefined});
  const handleEditBatch = () => {
    setIsEditingBatchName({id: undefined, name: undefined});
    onEditBatch(isEditingBatchName);
  };

  const handleBatchAdd = () => {
    setIsBatchAddDialogOpen(true);
  };

  const handleBatchAddAccepted = (name) => {
    onCreateBatch(name);
    setIsBatchAddDialogOpen(false);
  };

  const batchTitle = 'Gestión de lotes';

  const batchListContent = <>
    {
      isAlertDialogOpen && <AlertDeleteDialog
        title={'Eliminar lote'}
        description={'No se podrá recuperar el lote. ¿Estás seguro?'}
        onCancel={handleCancelDelete}
        onAccept={handleDelete}
      />
    }
    <Typography gutterBottom id='batchList-subtitle' sx={{color: 'common.black', ml: 1, mt: 1}}>Listado de los lotes de toda la comunidad.</Typography>
    <Typography gutterBottom sx={{color: 'common.black', ml: 1, mb:1}}>Puedes hacer click para ver en el mapa a que intervenciones pertenece.</Typography>
    <TableContainer sx={{overflowY: 'auto', width: '100%'}}>
      <Table size='small' dense='true'>
        <TableBody>
          {
            batches.length ? batches.sort((a, b) => a.id - b.id)
              .map(batch => {
                return <TableRow key={batch.id} sx={rowSx(batch.id)}>
                  <TableCell width='5%'>
                    {batch.id}
                  </TableCell>
                  <TableCell onClick={() => !isEditingBatchName.id && handleBatchMode(batch.id)}>
                    {
                      isEditingBatchName.id === batch.id ?
                        <TextField fullWidth margin='none' size='small' value={isEditingBatchName.name}
                          onChange={(e) => setIsEditingBatchName({id: batch.id, name: e.target.value})}
                        />
                        : <Typography>{batch.name}</Typography>
                    }
                  </TableCell>
                  <TableCell width='5%'>
                    {
                      isEditingBatchName.id === batch.id ?
                        <>
                          <IconButton size='small' onClick={() => handleEditBatch(batch.id, batch.name)} sx={{color: 'grey.400'}} disabled={isOffline}>
                            <CheckIcon fontSize='small' sx={{color: 'grey.700'}}/>
                          </IconButton>
                          <IconButton size='small' onClick={handleEditBatchEnd} sx={{color: 'grey.400'}} disabled={isOffline}>
                            <CloseIcon fontSize='small' sx={{color: 'grey.700'}}/>
                          </IconButton>
                        </>
                        : <IconButton size='small' onClick={() => handleEditBatchStart(batch.id, batch.name)} sx={{color: 'grey.400'}} disabled={isOffline}>
                          <EditIcon fontSize='small' sx={{color: 'grey.700'}}/>
                        </IconButton>
                    }
                  </TableCell>
                  <TableCell width='5%'>
                    <IconButton size='small' onClick={() => handleAlertDelete(batch.id)} sx={{color: 'grey.400'}} disabled={isOffline}>
                      <DeleteIcon fontSize='small' sx={{color: 'grey.700'}}/>
                    </IconButton>
                  </TableCell>
                </TableRow>;
              }) : <Typography>Esta comunidad no tiene todavía ningún lote creado</Typography>
          }
        </TableBody>
      </Table>
    </TableContainer>
  </>;

  return <><Hidden lgDown>{/*DESKTOP*/}
    <SectionTitle title={batchTitle.toUpperCase()}/>
    {batchListContent}
    <Box display="flex" flexDirection='row' justifyContent="flex-end" mt={2}>
      <Button variant='contained' size='medium' startIcon={<ExitToAppIcon/>} isOffline={isOffline} onClick={onBatchExit}>
          SALIR
      </Button>
      <AddButton isOffline={isOffline} onAddButtonClick={handleBatchAdd}/>
    </Box>
    <CreateBatchMenu isCreateBatchMenuOpen={isBatchAddDialogOpen} onAccept={handleBatchAddAccepted} onCancel={() => setIsBatchAddDialogOpen(false)}/>
  </Hidden>
  <Hidden lgUp>{/*MOBILE*/}
    {
      isBatchMenuOpen && <BottomSheet
        closedHeight={120}
        openHeight={'50vh'}
        onToggle={onBatchModeMenuClose}
        isOpen={isBatchMenuOpen}
      >
        <Box sx={{mb: 10}}>
          <Typography variant='subtitle1' sx={{fontWeight: 'bold', color: 'secondary.main', ml:1}}>
            {batchTitle.toUpperCase()}
          </Typography>
          {batchListContent}
        </Box>
      </BottomSheet>
    }
  </Hidden>
  </>;
};

BatchList.propTypes = {
  batches: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.number, name: PropTypes.string })),
  interventions: PropTypes.arrayOf(intervention),
  isBatchMenuOpen: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  onBatchExit: PropTypes.func,
  onBatchMode: PropTypes.func,
  onBatchModeMenuClose: PropTypes.func,
  onDeleteBatch: PropTypes.func,
  onEditBatch: PropTypes.func,
  onCreateBatch: PropTypes.func
};

export default BatchList;