import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
/*import IconButton from '@mui/material/IconButton';*/

//MUI-ICONS
/*import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ShareIcon from '@mui/icons-material/Share';*/

//BIKENTA
import Row from '../../Row';

const MenuHeader = ({
  cuartel,
  ua,
  uo,
  /*onExportClick,
  onNotificationClick, 
  onShareClick*/
}) => {
  /*const handleExportClick = () => onExportClick();
  const handleNotificationClick = () => onNotificationClick();
  const handleShareClick = () => onShareClick();*/

  //STYLES
  const interventionZoneTitle = {
    color: 'primary.contrastText',
    fontWeight: '900',
    mr: 0.5
  };
  /*const iconButton = {
    '&.MuiButtonBase-root': {
      padding: '0px 8px',
      width: 30,
      height: 30,
      color: 'primary.contrastText'
    }
  };*/

  return <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flexWrap="wrap" sx={{bgcolor: 'primary.light', borderRadius: 1, mb: 1}}>
    <Box display="flex" flexDirection="row" m={1}>
      <Row sx={{mr: 1}}>
        <Typography sx={interventionZoneTitle} variant="h2">U. Actuación</Typography>
        <Typography sx={interventionZoneTitle}>{ua}</Typography>
      </Row>
      <Row sx={{mr: 1}}>
        <Typography sx={interventionZoneTitle} variant="h2">UO</Typography>
        <Typography sx={interventionZoneTitle}>{uo}</Typography>
      </Row>
      <Row sx={{mr: 1}}>
        <Typography sx={interventionZoneTitle} variant="h2">Cuartel</Typography>
        <Typography sx={interventionZoneTitle}>{cuartel}</Typography>
      </Row>
    </Box>
    {/*<Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-end" m={0.5}>
      <IconButton onClick={handleExportClick} sx={iconButton}>
        <PictureAsPdfIcon/>
      </IconButton>
      <IconButton onClick={handleNotificationClick} sx={iconButton}>
        <NotificationsIcon/>
      </IconButton>
      <IconButton onClick={handleShareClick} sx={iconButton}>
        <ShareIcon/>
      </IconButton>
    </Box>*/}
  </Box>;
};

MenuHeader.propTypes = {
  cuartel: PropTypes.string,
  uo: PropTypes.string,
  ua: PropTypes.string,
  onExportClick: PropTypes.func.isRequired,
  onNotificationClick: PropTypes.func.isRequired,
  onShareClick: PropTypes.func.isRequired,
};

export default MenuHeader;