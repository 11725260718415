import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import {IcPassword, IcUser} from '../../components/icons';
import {grey} from '@mui/material/colors';

const LoginForm = ({loginErrorMessage, username, password, onUsernameChanged, onPasswordChanged, onLoginButtonClicked}) => {
  const handleNameChange = (e) => onUsernameChanged(e.target.value);
  const handlePasswordChange = (e) => onPasswordChanged(e.target.value);
  const handleLoginClick = () => onLoginButtonClicked();
  const textField = {
    mb: 1,
    height: '64px',
    p: 2,
    '& .MuiInputBase-root': {
      color: '#5F5D70',
      fontWeight: '900',
      backgroundColor: grey[100]
    }
  };
  const errorText = {
    color: 'error.main',
    mb: 2,
    ml: 2
  };
  const signUpButton = {
    borderRadius: 0,
    height:'48px',
  };
  return (
    <Paper elevation={0} sx={{maxWidth: '600px'}}>
      <Grid sx={{mb: 1}}>
        <TextField
          type='text'
          placeholder='NIF'
          value={username}
          id='name'
          onChange={handleNameChange}
          fullWidth
          sx={textField}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SvgIcon>
                  <IcUser />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          color='primary'
          type='password'
          placeholder='Contraseña'
          value={password}
          id='password'
          onChange={handlePasswordChange}
          fullWidth
          sx={textField}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SvgIcon>
                  <IcPassword />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Typography variant='body2' sx={errorText}>{loginErrorMessage}</Typography>
      <Grid container direction='row-reverse' justifyContent='flex-start' alignItems='baseline'>
        <Button sx={signUpButton} variant='contained' color='primary' fullWidth onClick={handleLoginClick}>ENTRAR</Button>
      </Grid>
    </Paper>
  );
};

LoginForm.propTypes = {
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  onUsernameChanged: PropTypes.func.isRequired,
  onPasswordChanged: PropTypes.func.isRequired,
  onLoginButtonClicked: PropTypes.func.isRequired,
  loginErrorMessage: PropTypes.string
};

export default LoginForm;