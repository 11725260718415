import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const EditableData = ({label, value, onChange, isRequired=false, min, max, step=1, minWidth='90px', sx}) => {
  return <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, ...sx}}>
    {
      label &&
      <Typography sx={{minWidth: minWidth, fontWeight: 900}}>{label}<span style={{color: 'red'}}>{isRequired ? '*' : ''}</span></Typography>
    }
    <TextField
      error={isRequired && value === 0}
      type='number'
      inputProps={{min: min, max: max, step: step }}
      variant='outlined'
      size='small'
      value={value}
      onChange={(e) => onChange(Number(e.target.value))}
      sx={{width: '150px'}}
    />
  </Box>;
};

EditableData.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.number || PropTypes.string,
  isRequired: PropTypes.bool,
  minWidth: PropTypes.string,
  sx: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default EditableData;