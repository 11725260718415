import {ELASTIC_MODULE_PARAMS} from '../config';

const APPLICATION_JSON = 'application/json';
const MADERA_PLUS_URL = process.env.MADERA_PLUS_URL;
const MADERA_PLUS_API_KEY = process.env.MADERA_PLUS_API_KEY;

const MADERA_PLUS_GET_DATA_URL = (variable, x, y, specieId, district) => `${MADERA_PLUS_URL}/get_data?token=${MADERA_PLUS_API_KEY}&variable=${variable}&x=${x}&y=${y}&sp=${specieId}&distrito=${district}`;
const GET_API_MADERA_PLUS = (url) => {
  return fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': APPLICATION_JSON
    })
  }).then(response => {
    if ((response.status === 200) && response.ok) {
      return response.json();
    } else {
      return Promise.reject({
        status: response.status,
        message: response.statusText
      });
    }
  });
};

const MADERA_PLUS_VARIABLES = Object.freeze(Object.fromEntries(Object.entries(ELASTIC_MODULE_PARAMS).map(([key]) => [key, key])));

export const getMaderaPlusValues = (x, y, specieId, district) => {
  const variablesPromises = Object.values(MADERA_PLUS_VARIABLES).map(variable => {
    return GET_API_MADERA_PLUS(MADERA_PLUS_GET_DATA_URL(variable, x, y, 26, district))
      .then(({status, value}) => Promise.resolve({[variable]: status === 1 ? variable !== 'orientacion' ? parseFloat(value) : value : undefined}));
  });

  return Promise.all(variablesPromises)
    .then(variables => {
      const final = variables.reduce((acc, cur) => {
        return acc = {...acc, ...cur};
      }, {});
      return Promise.resolve(final);
    })
    .catch(() => Promise.reject());
};