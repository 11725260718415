import {createSelector} from 'reselect';
import range from './utils/range';
import {FILTERS} from './config';
import {
  getFiltersDescriptionsUseCase,
  getValidFilterValuesUseCase, interventionMeetsAnnualPlanUseCase,
  interventionMeetsFiltersUseCase, interventionZoneMeetsAnnualPlanUseCase,
  interventionZoneMeetsFiltersUseCase
} from './modules/useCases';
import {from4326_to25829} from './utils/from4326_to25826';

export const selectedAnnualPlan = (state) => state.app.selectedAnnualPlan;
export const selectedCommunity = (state) => state.app.selectedCommunity;
export const selectedInterventionZones = (state) => state.api.interventionZones && state.api.interventionZones;
export const selectedCommunityHasDetails = (state) => !!selectedInterventionZones(state);
export const selectedCommunityBounds = (state) => selectedCommunityHasDetails(state);
export const hasActivedFilters = (state) => !!state.app.activeFilters.length;
export const activedFilters = (state) => state.app.activeFilters;

export const activedCategoryFiltersValues = (state) => {
  const categoryFilter = activedFilters(state).find(filter => filter.key === 'categories');
  return categoryFilter ? categoryFilter.values : [];
};

export const getCommunitiesInState = (state) => state.api.communities;

/**is
 * Comprueba  si una comunidad coincide con los filtros aplicados
 * @param community Object state.api.communities
 * @param filters Array state.app.filters
 */
const communityMeetsFilters = (community, filters) =>
  filters
    .every(filter =>
      filter.values.every(value =>
        Array.isArray(community.interventions[filter.key]) ?
          community.interventions[filter.key].includes(value) :
          community.interventions[filter.key] === value
      )
    );


export const getCommunities = createSelector(
  [
    hasActivedFilters,
    activedFilters,
    state => state.api.communities,
    state => state.app.searchedText,
    selectedCommunity
  ],
  (hasActivedFilters, activedFilters, communities, text, selectedCommunity) =>
    !selectedCommunity ?
      hasActivedFilters ?
        text === '' ?
          communities.filter(community => communityMeetsFilters(community, activedFilters)
          ) : communities.filter(community => communityMeetsFilters(community, activedFilters))
            .filter(community => community.owner?.toUpperCase().includes(text?.toUpperCase()))
        :
        communities.filter(community => community.owner?.toUpperCase().includes(text?.toUpperCase()))
      :
      [selectedCommunity]
);


/**
 * Obtiene las zonas de intervención...
 * Tiene en cuenta si hay comunidad seleccionada y/o hay algún filtro activo.
 */
export const getInterventionZones = createSelector(
  [
    hasActivedFilters,
    activedFilters,
    state => state.api.interventionZones
  ],
  (hasActivedFilters, activedFilters, interventionZones) =>
    hasActivedFilters ?
      interventionZones.filter(interventionZone =>
        interventionZoneMeetsFiltersUseCase(interventionZone, activedFilters))
      : interventionZones
);


/**
 * Obtiene las zonas de intervención y sus intervenciones filtradas
 * Tiene en cuenta si hay comunidad seleccionada y/o hay algún filtro activo.
 */
export const getInterventionZonesAndItsInterventionsAllFiltered = createSelector(
  [
    hasActivedFilters,
    activedFilters,
    state => state.api.interventionZones,
    state => state.app.selectedAnnualPlan?.id || undefined
  ],
  (hasActivedFilters, activedFilters, interventionZones, selectedAnnualPlanId) => {

    let finalInterventionZones = [];

    finalInterventionZones = interventionZones
      .filter(interventionZone => interventionZoneMeetsFiltersUseCase(interventionZone, activedFilters))
      .map(interventionZone => ({
        ...interventionZone,
        interventions: interventionZone.interventions
          .filter(intervention => interventionMeetsFiltersUseCase(intervention, activedFilters))
      }));

    if (selectedAnnualPlanId) {
      finalInterventionZones = finalInterventionZones
        .filter(interventionZone => interventionZoneMeetsAnnualPlanUseCase(interventionZone, selectedAnnualPlanId)
        )
        .map(interventionZone => ({
          ...interventionZone,
          interventions: interventionZone.interventions
            .filter(intervention => interventionMeetsAnnualPlanUseCase(intervention, selectedAnnualPlanId))
        }));
    }

    return finalInterventionZones;
  }
);

/**
 * Obtiene las zonas de actuación con la información necesaria para renderizar componentes.
 * Tiene en cuenta si hay comunidad seleccionada y/o hay algún filtro activo.
 */
export const getRenderInterventionZones = createSelector(
  [
    getInterventionZones
  ],
  (interventionZones) => {
    const sortedInterventionZones = interventionZones
      .sort((a, b) => a.uo - b.uo);
    return sortedInterventionZones
      .map(interventionZone => ({
        id: interventionZone.id,
        canton: interventionZone.canton,
        mouteira: interventionZone.mouteira,
        stratum: interventionZone.stratum,
        age: interventionZone.age ? interventionZone.age : 0,
        interventionCategories: interventionZone.categoryGroups,
        planned_interventions: interventionZone.planned_interventions ? 'Sí' : 'No',
        area: interventionZone.area ? parseFloat(interventionZone.area.toFixed(2)) : 0,
        bbox: interventionZone.bbox,
        uo: interventionZone.uo,
        ua: interventionZone.ua,
        cuartel: interventionZone.cuartel,
        sxpublica: interventionZone.sxpublica,
        refcat: interventionZone.refcat
      }));
  }
);

export const selectedInterventionZoneId = (state) => state.app.selectedInterventionZone;
export const selectedInterventionZone = (state) => {
  if (selectedInterventionZoneId(state)) {
    return state.api.interventionZones.find(({id}) => id === selectedInterventionZoneId(state));
  } else {
    return undefined;
  }
};

/**
 * Obtiene los años válidos para filtrar.
 * Tiene en cuenta si hay comunidad seleccionada y/o hay algún filtro activo.
 */
export const getValidYears = createSelector(
  [
    state => state.app.selectedCommunity?.id,
    getCommunities,
    getInterventionZones,
    state => state.app.activeFilters
  ],
  (selectedCommunityId, communities, interventionZones, activeFilters) =>
    selectedCommunityId ?
      Array.from(new Set(interventionZones
        .flatMap(({interventions}) => interventions
          .filter(intervention => interventionMeetsFiltersUseCase(intervention, activeFilters))
          .flatMap(({planned_start_year, planned_end_year}) =>
            range(parseInt(planned_start_year), parseInt(planned_end_year))
          ))
        .concat(activeFilters.filter(({key}) => key === 'years').length ? activeFilters.find(({key}) => key === 'years').values : []) // valores de los filtros aplicados
      )).sort() :
      Array.from(
        new Set(communities
          .flatMap(({interventions}) => interventions.years)
        )
      ).sort());

export const getValidFilterValues = createSelector(
  [
    state => state.app.selectedCommunity?.id,
    getCommunities,
    getInterventionZones,
    state => state.app.activeFilters
  ], getValidFilterValuesUseCase);

export const getFiltersDescriptions = createSelector(
  [
    getValidYears,
    state => state.api.manifest,
    state => state.app.i18n,
    getValidFilterValues
  ], getFiltersDescriptionsUseCase);

export const getFiltersCategoriesDescriptions = createSelector(
  [
    state => state.api.manifest,
    state => state.app.i18n,
    getValidFilterValues
  ],
  (manifest, i18n, communitiesFiltersValues) =>
    manifest && communitiesFiltersValues ?
      manifest.filters
        .find(filter => filter.key === 'categories')
        .values
        .map(filter => ({
          ...filter,
          label: filter.label[i18n],
          values: filter.values
            .filter(({key}) => communitiesFiltersValues.categories.includes(key))
            .map(value => ({...value, label: value.label[i18n]}))
        })) :
      []);

export const getInterventionTypes = createSelector(
  [
    state => state.api.manifest,
  ],
  (manifest) => manifest ? manifest.interventions : []
);

export const getCategories = createSelector(
  [
    state => state.api.manifest
  ],
  (manifest) => manifest ?
    manifest.filters
      .find(filter => filter.key === 'categories').values : []
);

export const selectedInterventionId = (state) => state.app.selectedInterventionId;
export const selectedIntervention = (state) => {
  if (selectedInterventionZoneId(state) && selectedInterventionId(state)) {
    return state.api.interventionZones.find(({id}) => id === selectedInterventionZoneId(state))
      .interventions.find(({id}) => id === selectedInterventionId(state));
  } else {
    return undefined;
  }
};

export const getAnnualPlans = (state) => selectedCommunity(state) && state.api.annualPlans;

export const getInterventionsFromSelectedCommunity = createSelector(
  [
    state => state.api.interventionZones
  ],
  (interventionZones) =>
    interventionZones.flatMap(
      interventionZone => interventionZone.interventions
    )
);

export const getInterventionsFromSelectedAnnualPlan = createSelector(
  [
    state => state.app.selectedAnnualPlan,
    getInterventionsFromSelectedCommunity,
  ],
  (selectedPlan, interventions) =>
    selectedPlan && interventions && interventions
      .filter(intervention => selectedPlan.interventions.includes(intervention.id))
);

export const getAnnualPlanSummary = createSelector(
  [
    getInterventionsFromSelectedAnnualPlan
  ],
  (interventions) => {
    if (interventions) {
      const executedInterventions = interventions.filter(el=>el.done);
      return {
        interventions: interventions.length,
        done: executedInterventions.length,
        // planeado ganar
        planned_income: interventions.reduce((planned_income, intervention) => planned_income + intervention.planned_income, 0),
        // planeado invertir
        planned_invest: interventions.reduce((planned_invest, intervention) => planned_invest + intervention.planned_invest, 0),
        // de lo planeado de ganacias, la cantidad de las ejecutadas
        executed_income: executedInterventions.reduce((executed_income, intervention) => executed_income + intervention.executed_income, 0),
        // de lo planeado en inversion, la cantidad de las ejecutadas
        executed_invest: executedInterventions.reduce((executed_invest, intervention) => executed_invest + intervention.executed_invest, 0),
      };
    } else {
      return undefined;
    }
  }
);

export const getCategoriesAndInterventionZonesIdsOfInterventionsFromAnnualPlan = createSelector(
  [
    state => state.app.selectedAnnualPlan,
    state => state.api.interventionZones,
    state => state.api.manifest?.filters
  ],
  (selectedAnnualPlan, interventionZones, filters) => {
    if (filters && selectedAnnualPlan) {
      const categories = filters.find(({key}) => key === FILTERS.CATEGORIES)?.values;
      return categories.reduce((acc, {key, values}) => {
        const categoryIds = values.map(({key}) => key);
        const zonas = interventionZones.filter(({interventions}) =>
          interventions.some(({id, categories}) =>
            selectedAnnualPlan.interventions.includes(id) &&
            categories.some(categoryId => categoryIds.includes(categoryId))
          ));

        acc[key] = zonas.map(({id}) => id);
        return acc;
      }, {});
    } else {
      return {};
    }
  }
);

export const getInterventionZonesIdsFromAnnualPlan = createSelector(
  [
    state => state.app.selectedAnnualPlan,
    state => state.api.interventionZones,
    state => state.api.manifest?.filters
  ],
  (selectedAnnualPlan, interventionZones, filters) => {
    if (filters && selectedAnnualPlan) {
      return interventionZones.filter(({interventions}) =>
        interventions.some(({id}) =>
          selectedAnnualPlan.interventions.includes(id)
        )).map(interventionZone => interventionZone.id);
    } else {
      return [];
    }
  }
);

export const getAnnualPlansFromSelectedIntervention = createSelector(
  [
    state => state.app.selectedInterventionId,
    state => state.api.annualPlans
  ],
  (interventionId, annualPlans) => {
    if (interventionId) {
      return annualPlans.filter(
        plan => plan.interventions.includes(interventionId))
        .map(plan => (
          {
            id: plan.id,
            year: plan.year
          }
        ));
    }
  }
);

export const getAnnualPlansFromSelectedInterventionZones = createSelector(
  [
    selectedInterventionZones
  ],
  (interventionZones) => {
    if (interventionZones) {
      return interventionZones
        .map(interventionZone => (
          {
            interventionZoneId: interventionZone.id,
            annualPlans: Array.from(new Set(interventionZone.interventions
              .flatMap(intervention => intervention.planned_assigned
                .map(plan => plan.year))
              .sort()
            )
            )
          }
        )
        );
    } else {
      return undefined;
    }
  }
);

export const getBoardMembers = createSelector(
  [
    state => state.api.manifest,
  ],
  (manifest) => manifest ? manifest.board_members : []
);

//export const getMaderaPlusSpecies = (state) => state.api.maderaPlus_species;
export const getMaderaPlusSpecies = () => [27];
export const getMaderaPlusDistrict = (state) => state.api.maderaPlus_district;

export const getSelectedInterventionZoneCentroid = createSelector(
  [
    state => state.app.selectedInterventionZone,
    state => state.api.interventionZones
  ],
  (interventionZoneId, interventionZones) => {
    if (interventionZoneId) {
      const selectedBbox = Object.values(interventionZones.find(
        interventionZone => interventionZone.id === interventionZoneId).bbox);
      return from4326_to25829({
        x: selectedBbox[0] + (selectedBbox[2] - selectedBbox[0]),
        y: selectedBbox[1] + (selectedBbox[3] - selectedBbox[1]),
      });
    }
  }
);


export const getBatches = createSelector(
  [
    state => state.api.batches
  ],
  (batches) => batches
);

export const getCommunityFiles = createSelector(
  [
    state => state.api.files
  ],
  (files) => files
);
