import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogPanel from '../DialogPanel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const CreateBatchMenu = ({isCreateBatchMenuOpen, onAccept, onCancel}) => {

  const createBatchMenuTitle = 'CREAR UN LOTE';

  const [batchName, setBatchName] = useState(undefined);
  
  return <DialogPanel isOpen={isCreateBatchMenuOpen} label={createBatchMenuTitle} onClose={() => {}} fullWidth={true}>
    <Typography>Introduce el nombre del NUEVO lote.</Typography>
    <TextField variant="outlined" size='small' fullWidth sx={{my: 1}} onChange={e => setBatchName(e.target.value)}/>
    <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={1} ml={2}>
      <Button color="secondary" sx={{color: 'grey.400'}} onClick={onCancel}>CANCELAR</Button>
      <Button variant="contained" color="secondary" onClick={() => onAccept(batchName)} >ACEPTAR</Button>
    </Box>
  </DialogPanel>;

};

CreateBatchMenu.propTypes = {
  isCreateBatchMenuOpen: PropTypes.bool,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func
};

export default CreateBatchMenu;