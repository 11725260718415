import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import CommunityMapPage from '../views/CommunityMapPage';
import Login from '../views/Login/Login';


const Routes = () => 
  <Router>
    <Switch>
      <Route path="/login">
        <Login/>
      </Route>
      <PrivateRoute path='/community/:communityId/interventionZone/:interventionZoneId/intervention/:interventionId'>
        <CommunityMapPage/>
      </PrivateRoute>
      <PrivateRoute path='/community/:communityId/interventionZone/:interventionZoneId'>
        <CommunityMapPage/>
      </PrivateRoute>
      <PrivateRoute path='/community/:communityId'>
        <CommunityMapPage/>
      </PrivateRoute>
      <PrivateRoute path='/'>
        <CommunityMapPage/>
      </PrivateRoute>
    </Switch>
  </Router>;

export default Routes;
