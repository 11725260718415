import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

//STYLES
const avatarSx = {
  bgcolor: 'secondary.main',
  width: 24,
  height: 24
};

const avatarContentSx = {
  fontWeight: 900
};

const textSx = {
  textTransform: 'uppercase',
  borderBottom: theme => `2px solid ${theme.palette.secondary.main}`,
  fontWeight: 900
};

const BiomasSection = ({number, label, children, aditionalProperty, sx}) => {
  return <Box mb={3} ml={1} sx={sx}>
    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
      <Avatar sx={avatarSx}>
        <Typography variant='body1' sx={avatarContentSx}>{number}</Typography>
      </Avatar>
      <Typography color='secondary' sx={textSx}>{label}</Typography>
      {aditionalProperty}
    </Box>
    <Box ml={4} mt={1}>
      {children}
    </Box>
  </Box>;
};

BiomasSection.propTypes = {
  aditionalProperty: PropTypes.element,
  number: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  sx: PropTypes.object,
  children: PropTypes.element.isRequired
};

export default BiomasSection;