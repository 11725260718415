import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

const InputNumber = ({label, min, max, value, onChange, disabled, sx}) => {

  const editableUp = value < max;
  const editableDown = value > min;
  const editable = min < value < max;

  return <Box display='flex' flexDirection='column' justifyContent='flex-start' sx={sx}>
    <Typography sx={{mx:0.25}} variant='caption'>{label}</Typography>
    <Box display='flex' flexDirection='row' alignItems='center'>
      <IndeterminateCheckBoxIcon
        id="input-number-decrease"
        color={disabled || editableDown ? 'primary' : 'disabled'}
        disabled={disabled || editable ? 1 : 0}
        onClick={!disabled && editableDown ? () => onChange(value-1) : undefined}
      />
      <Typography sx={{mx:0.5}} variant='subtitle1'>{value}</Typography>
      <AddBoxIcon
        id="input-number-increase"
        color={disabled || editableUp ? 'primary' : 'disabled'}
        disabled={disabled || editable ? 1 : 0}
        onClick={!disabled && editableUp ? () => onChange(value+1) : undefined}
      />
    </Box>
  </Box>;
};

InputNumber.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  sx: PropTypes.object,
};

InputNumber.defaultProps = {
  label: '',
};

export default InputNumber;
