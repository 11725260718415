import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const InputSelect = ({label, value, options, onChange}) => {
  const handleChange = (e) => onChange && onChange (e.target.value);
  const formControl = {
    m: 1,
    minWidth: 240,
    p:0
  };
  return (
    <FormControl variant="outlined" sx={formControl}>
      <InputLabel>{label}</InputLabel>
      <Select
        native
        label={label}
        value={value}
        onChange={handleChange}
      >
        {
          options.map(({key, id, label}) => <option key={key || id} value={key || id}>{label}</option>)
        }
      </Select>
    </FormControl>
  );
};

InputSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number || PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      label: PropTypes.string
    })).isRequired,
  onChange: PropTypes.func,
};

InputSelect.defaultProps = {
  label: '',
  value: 2,
};

export default InputSelect;
