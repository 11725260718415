import React, {useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import InfoIcon from '@mui/icons-material/Info';
import MailIcon from '@mui/icons-material/Mail';
import DraftsIcon from '@mui/icons-material/Drafts';

//BIKENTA
import AcknowledgmentMenu from './Layout/AcknowledgmentMenu';
import ThumbnailCarousel from './InterventionZones/InterventionMenu/ThumbnailCarousel';

//UTILS
import {FILE_READ_RECEIPT, FILE_TYPES, ROLES_LABEL} from '../config';
import {hasEditPermissions} from '../modules/useCases';
import AcknowledgementFileDetail from './Layout/AcknowledgementFileDetail';
import {roles} from './proptypes/roles';
import {commoners} from './proptypes/commoners';
import {fileDetail} from './proptypes/fileDetail';
import {getCredentials} from '../services/localStorage';


const buttonSx = {
  '&.Mui-disabled': {
    color: theme => theme.palette.secondary.main,
    border: theme => `1px solid ${theme.palette.secondary.main}`
  }
};

const UploadFile = ({
  commoners,
  fileDetail,
  fileType, 
  files, 
  isOffline,
  notifications = [],
  role,
  rolesList,
  onDeleteFile, 
  onDownloadFile, 
  onUploadFile,
  onFileDetailOpen,
  readUserNotification
}) => {
  const handleDeleteFile = document => {
    onDeleteFile(document.id);
    handleReadNotificationIfExistsForDocument(document);
  };

  const [isAcknowledgmentMenuOpen, setAcknowledgmentMenuOpen] = useState(false);
  const [isFileDetailOpen, setFileDetailOpen] = useState(false);
  const handleAcknowledgmentMenuOpen = () => setAcknowledgmentMenuOpen(true);
  const handleAcknowledgmentMenuClose = () => setAcknowledgmentMenuOpen(false);

  const handleFileDetailOpen = (id) => {
    onFileDetailOpen(id);
    setFileDetailOpen(true);
  };

  const handleClickOnFile = (document) => {
    onDownloadFile(document.id, document.content_type);
    handleReadNotificationIfExistsForDocument(document);
  };

  const handleReadNotificationIfExistsForDocument = (document) => {
    const notification = notifications.find(notification => notification.description === document.name);
    if (notification) {
      readUserNotification(notification.id, getCredentials().token);
    }
  };

  const withConfirmationFiles = files.filter(file => file.read_receipt === FILE_READ_RECEIPT.WITH_CONFIRMATION);
  const noConfirmationFiles = files.filter(file => file.read_receipt === FILE_READ_RECEIPT.NO_CONFIRMATION);

  const handleClose = () => setFileDetailOpen(false);

  const documentList = (confirmation) => <Box mt={1}>
    {
      confirmation.length === 0 ?
        <Typography sx={{color: 'grey.600'}}>No se ha subido nada.</Typography>
        : fileType === FILE_TYPES.DOCUMENT ?
          confirmation && confirmation.map((document, index) => <Box key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              m: 0.5
            }}>
            {
              notifications.some(notification => notification.description === document.name) ?
                <MailIcon color='primary' /> :
                <DraftsIcon color='primary' />
            }
            {confirmation === withConfirmationFiles && hasEditPermissions(role) &&
              <InfoIcon color='primary' onClick={() => handleFileDetailOpen(document.id)}/>
            }
            <Chip
              key={index}
              label={document.name}
              size='small'
              variant='outlined'
              clickable
              color='primary'
              onDelete={hasEditPermissions(role) ? () => handleDeleteFile(document) : undefined}
              onClick={() => handleClickOnFile(document)}
            /></Box>
          )
          : <Box display="flex" flexDirection="row" flexWrap="wrap" sx={{width: '100%'}}><ThumbnailCarousel
            images={files}
            thumbnailHeight={60}
            isDeletable={true}
            onDelete={onDeleteFile}
            onDownloadFile={onDownloadFile}
          /></Box>
    }
  </Box>;

  return <>
    <Box display='flex' flexDirection='column' mb={2}>
      <Button
        startIcon={
          fileType === FILE_TYPES.DOCUMENT ?
            <DescriptionIcon sx={{color: isOffline ? 'white' : undefined}}/>
            : <ImageIcon sx={{color: isOffline ? 'white' : undefined}}/>
        }
        color='primary'
        variant={hasEditPermissions(role) ? 'contained' : 'outlined'}
        component='label'
        disabled={isOffline || !hasEditPermissions(role)}
        onClick={hasEditPermissions(role) ? handleAcknowledgmentMenuOpen : undefined}
        sx={buttonSx}
      >
        <Typography variant='button' sx={{color: isOffline ? 'white' : undefined}}>
          {
            fileType === FILE_TYPES.DOCUMENT ? 'DOCUMENTOS' : 'IMÁGENES'
          }
        </Typography>
      </Button>
      <AcknowledgmentMenu
        role={role}
        rolesList={rolesList}
        isAcknowledgmentMenuOpen={isAcknowledgmentMenuOpen}
        fileType={fileType}
        onCancel={handleAcknowledgmentMenuClose}
        onUploadFile={onUploadFile}
      />
      {noConfirmationFiles && documentList(noConfirmationFiles)}
      {
        fileType === FILE_TYPES.DOCUMENT && <>
          <Typography sx={{mt: 1, color: 'secondary.main', fontWeight: 'bold'}}>CON ACUSE DE RECIBO</Typography>
          {withConfirmationFiles && documentList(withConfirmationFiles)}
        </>
      }
    </Box>
    {
      !!isFileDetailOpen && <AcknowledgementFileDetail
        commoners={commoners}
        isOpen={!!isFileDetailOpen}
        fileDetail={fileDetail}
        onClose={handleClose}
      />
    }
  </>;
};

UploadFile.propTypes = {
  commoners: commoners,
  isOffline: PropTypes.bool,
  fileDetail: fileDetail,
  fileType: PropTypes.oneOf(Object.values(FILE_TYPES)).isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    content_type: PropTypes.string,
    extension: PropTypes.string,
    download_url: PropTypes.string,
    thumbnail_url: PropTypes.string
  })).isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string
    })
  ),
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)).isRequired,
  rolesList: PropTypes.arrayOf(roles),
  onDeleteFile: PropTypes.func,
  onDownloadFile: PropTypes.func,
  onUploadFile: PropTypes.func,
  onFileDetailOpen: PropTypes.func,
  readUserNotification: PropTypes.func
};

export default UploadFile;