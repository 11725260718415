import round from '../../../utils/round';

const areaAproveitamentoSpecies = (interventionZones, options) => {

  if (options.totalUnfiltered === undefined) {
    throw new Error('Total interventions zones unfiltered not passed as parameter!!');
  } else {

    const formatSpecies = options.totalUnfiltered && options.totalUnfiltered
    //filtra las interventionZones que tienen intervenciones de Aproveitamento (1)
      .filter(interventionZone => interventionZone.categoryGroups.includes(1))
    //filtra las que no tienen especies
      .filter(interventionZone => interventionZone.species.length !== 0)
    //mapea el resto
      .map(iz => (
        iz.species && iz.species.reduce((acc, specie) => {
          return {
            label: iz.species.length > 1 ? [acc.label, specie.label].flat() : specie.label,
            area: round(iz.area)
          };
        }, {
          label: undefined,
          area: 0
        })
      ));

    const result = formatSpecies
      .map(spe => Array.isArray(spe.label) ?
        {
          ...spe,
          label: `Mixta (${spe.label.slice(1).join( ', ' )})`
        }
        : spe)
      .reduce((acc, species) => {
        const label = species.label;

        if (!acc[label]) {
          // Si la etiqueta no existe en el acumulador, inicialízala
          acc[label] = {
            label: species.label,
            area: 0
          };
        }

        // Realiza la suma de las propiedades para la etiqueta actual
        round(acc[label].area += species.area);

        return acc;
      }, {});

    // Convierte el objeto result en un array de objetos
    const calculation = Object.values(result);

    return {
      species: calculation,
    };
  }
};

export default areaAproveitamentoSpecies;