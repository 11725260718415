import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import {SPECIES_IMAGES} from '../../../config';
import round from '../../../utils/round';
import Row from '../../Row';
import {labelStyle, valueStyle} from '../../Cards/InterventionZoneCard';

//STYLES
const media = {
  height: 40,
  width: 40,
};
const specie = {
  color: 'primary.main',
  fontSize: 14,
  pr: 1,
  fontWeight: 'bold'
};

const ExistencesCard = ({id, label, age, existences}) => {
  return <Stack sx={{borderRadius: 2, pb: 1, mr: 1, mb: 1, boxShadow: { xs: 0, sm: 2}, bgcolor: 'common.white'}}>
    <Stack direction='row' sx={{justifyContent: 'flex-start', alignItems: 'flex-end', mt: 0.5}}>
      <Avatar variant="square" alt={label} sx={media} src={SPECIES_IMAGES[id] || SPECIES_IMAGES.default}/>
      <Typography sx={specie}>{label.toUpperCase()}</Typography>
    </Stack>
    <Box display="flex" flexDirection="row" justifyContent="flex-start" mt={1}>
      <Stack sx={{ml: 1}}>
        <Row>
          <Typography sx={labelStyle}>Edad:</Typography>
          <Typography sx={valueStyle}>{(age === 0 ? '0' : age) || '-'}</Typography>
        </Row>
        <Row>
          <Typography sx={labelStyle}>Nº pies:</Typography>
          <Typography sx={valueStyle}>{round(existences.treeFeet) || '-'}</Typography>
        </Row>
        <Row>
          <Typography sx={labelStyle}>A.Basimétrica:</Typography>
          <Typography sx={valueStyle}>{round(existences.basometricArea)}</Typography>
        </Row>
        <Row>
          <Typography sx={labelStyle}>Vol. con corteza:</Typography>
          <Typography sx={valueStyle}>{round(existences.volumeWithBark) || '-'}</Typography>
        </Row>
        <Row>
          <Typography sx={labelStyle}>Vol. sin corteza:</Typography>
          <Typography sx={valueStyle}>{round(existences.volumeWithoutBark) || '-'}</Typography>
        </Row>
        <Row>
          <Typography sx={labelStyle}>Altura:</Typography>
          <Typography sx={valueStyle}>{round(existences.height) || '-'}</Typography>
        </Row>
        <Row>
          <Typography sx={labelStyle}>Biomasa:</Typography>
          <Typography sx={valueStyle}>{round(existences.biomass) || '-'}</Typography>
        </Row>
      </Stack>
    </Box>
  </Stack>;
};

ExistencesCard.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  age: PropTypes.number,
  existences: PropTypes.shape({
    treeFeet: PropTypes.number,
    height: PropTypes.number,
    basometricArea: PropTypes.number,
    volumeWithBark: PropTypes.number,
    volumeWithoutBark: PropTypes.number,
    biomass: PropTypes.number,
    start_date: PropTypes.number,
    end_date: PropTypes.number
  })
};

export default ExistencesCard;