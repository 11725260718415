import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import DeckGLMap from '@geomatico/geocomponents/DeckGLMap';
import {GeoJsonLayer} from '@deck.gl/layers';
import { EditableGeoJsonLayer } from '@nebula.gl/layers';
import {DrawPolygonMode, CompositeMode, TranslateMode, ModifyMode} from '@nebula.gl/edit-modes';
import intersect from '@turf/intersect';
import booleanEqual from '@turf/boolean-equal';
import LazyLoading from '../../LazyLoading';
import AlertDeleteDialog from '../../AlertDeleteDialog';
import centroid from '@turf/centroid';

//STYLES
const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%'
  }
});
const emptyFeature = {
  type: 'Feature',
  geometry: {
    type: 'Polygon',
    coordinates: []},
  properties: {}
};


const CastAsMultiPolygon = feature =>
  feature.geometry.type === 'Polygon' ?
    {...feature, geometry: {type: 'MultiPolygon', coordinates: [feature.geometry.coordinates]}} :
    feature;

const EditGeometryMap = ({initialViewport, basemap, baseGeometry, onFeatureInput, finalGeometry, editable}) => {
  const classes = useStyles();

  const [viewport, setViewport] = useState(initialViewport);
  const [cutFeature, setCutFeature] = useState(emptyFeature);
  const [finalFeature, setFinalFeature] = useState(editable ? null : finalGeometry);
  const [editMode, setEditMode] = useState(new DrawPolygonMode());
  const previousEditType = useRef(undefined);

  const [error, setError] = useState(null);
  
  const normalizedBaseFeature = useMemo(() => {
    if (baseGeometry){
      const centerFeature = centroid({type: 'Feature', geometry: baseGeometry});
      setViewport({
        ...viewport,
        longitude: centerFeature.geometry.coordinates[0],
        latitude: centerFeature.geometry.coordinates[1],
      });
      return baseGeometry;
      // if (baseGeometry.type === 'MultiPolygon') {
      //   const features = baseGeometry.coordinates.map(poly => ({type: 'Polygon', coordinates: poly}));
      //   const validFeatures = features.filter(feat => area(feat) > 1);
      //   return validFeatures.length === 1 ?
      //     validFeatures[0] :
      //     {type: 'MultiPolygon', coordinates: [validFeatures.map(({coordinates}) => coordinates)]};
      // } else {
      //   return baseGeometry;
      // }
    } else {
      return emptyFeature;
    }
  }, [baseGeometry]);


  const handleEditLayerUpdate = ({editContext, editType, updatedData}) => {
    if (editType === 'addFeature') {
      const feature = editContext.featureIndexes
        .map(i => updatedData.features[i])[0];
      setEditMode( new CompositeMode([new TranslateMode(), new ModifyMode()]));
      setCutFeature(feature);
    } else if (editType === 'movePosition' || editType === 'addPosition') {
      const feature = updatedData.features[0];
      setCutFeature(feature);
    } else if (editType === 'finishMovePosition') {
      const feature = updatedData.features[0];
      setCutFeature(feature);
    } else if ((editType === 'translated' || editType === 'translating') && (previousEditType.current !== 'movePosition' && previousEditType.current !== 'addPosition')) {
      const feature = updatedData.features[0];
      setCutFeature(feature);
    }
    previousEditType.current = editType;
  };


  const deckLayers = useMemo(() => {
    const layers = [
      new GeoJsonLayer({
        id: 'baseFeature',
        data: normalizedBaseFeature,
        getFillColor: [94, 94, 94, 80],
        getLineWidth: 2,
        getLineColor: [215, 215, 215, 255],
      }),
      new GeoJsonLayer({
        id: 'final',
        data: finalFeature || emptyFeature,
        getFillColor: [245, 211, 102, 50],
        getLineWidth: 2,
        getLineColor: [255, 234, 0, 255],
      }),
    ];

    if (editable) {
      layers.push(new EditableGeoJsonLayer({
        id: 'cutFeature',
        data: {
          type: 'FeatureCollection',
          features: [cutFeature],
        },
        getFillColor: [94, 94, 94, 80],
        getLineWidth: 2,
        getLineColor: [215, 215, 215],
        selectedFeatureIndexes: [0],
        mode: editMode,
        enableSnapping: true,
        onEdit: handleEditLayerUpdate
      }));
    }

    return layers;
  }, [normalizedBaseFeature, cutFeature, finalFeature, editMode]);


  useEffect(() => {
    if (editable){
      try {
        const feature = intersect(normalizedBaseFeature, cutFeature);
        if (!finalFeature || !feature) {
          setFinalFeature(feature);
        }
        else if (feature && finalFeature) {
          if (!booleanEqual(feature, finalFeature)){
            setFinalFeature(feature);
          }
        }
      } catch (error) {
        setCutFeature({mode: undefined, feature: emptyFeature});
        setFinalFeature(null);
        setError('Geometría de estrato errónea. No se puede dividir.');
      }
    }
  }, [cutFeature]);

  useEffect(() => {
    editable && finalFeature && onFeatureInput(CastAsMultiPolygon(finalFeature));
  }, [finalFeature]);

  return baseGeometry ?
    <div className={classes.root}>
      <DeckGLMap
        mapStyle={basemap}
        mapboxAccessToken={process.env.MAPBOX_ACCESS_TOKEN}
        viewport={viewport}
        onViewportChange={setViewport}
        deckLayers={deckLayers}
      />
      {error && <AlertDeleteDialog title={'Error'} description={error} onAccept={() => setError()}/> }   
    </div>:
    <LazyLoading isOpen={true} />;
};

const Geometry = PropTypes.shape({
  type: PropTypes.string.isRequired,
  coordinates: PropTypes.array.isRequired
});

EditGeometryMap.propTypes = {
  baseGeometry: Geometry.isRequired,
  finalGeometry: Geometry,
  editable: PropTypes.bool,
  basemap: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  initialViewport: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    zoom: PropTypes.number,
    bearing: PropTypes.number,
    pitch: PropTypes.number,
  }).isRequired,
  onFeatureInput: PropTypes.func
};

EditGeometryMap.defaultProps = {
  finalGeometry: null,
  editable: true
};

export default EditGeometryMap;