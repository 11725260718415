import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';

//STYLES
const classes = {
  root: 'DialogPanel-root',
  header: 'DialogPanel-header',
  headerText: 'DialogPanel-headerText',
  contentText: 'DialogPanel-contentText',
  footer: 'DialogPanel-footer'
};

const Root = styled(Dialog)(({theme}) => ({
  zIndex: 2000,
  '& .DialogPanel-header': {
    marginBottom: '10px',
    paddingTop: '5px',
    paddingLeft: '10px',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    height: '30px'
  },
  '& .DialogPanel-headerText': {
    fontWeight: 900,
    paddingRight: 10,
  },
  '& .DialogPanel-contentText': {
    height: '70%',
    overflow: 'auto',
    margin: '0 8px',
  },
  '& .DialogPanel-footer': {
    marginTop: '10px',
    backgroundColor: theme.palette.secondary.main,
    height: '5px'
  }
}));

const DialogPanel = ({isOpen, label, fullWidth, children, onClose, sx}) => {
  const handleModalClose = () => onClose && onClose();

  return (
    <Root
      id="bikenta-dialog"
      open={isOpen}
      onClose={handleModalClose}
      fullWidth={fullWidth}
      className={classes.root}
      sx={sx}
    >
      <Box className={classes.header}>
        <Typography className={classes.headerText}>{label}</Typography>
      </Box>
      <Box className={classes.contentText}>
        {children}
      </Box>
      <Box className={classes.footer}/>
    </Root>
  );
};

DialogPanel.propTypes = {
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

DialogPanel.defaultProps = {
  isOpen: false,
  label: '',
  fullWidth: false,
  sx: {}
};

export default DialogPanel;