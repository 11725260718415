import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//BIKENTA
import DialogPanel from '../DialogPanel';

const AnualPlanCompleteAlert = ({isOpenCompleteMenu, onCancelAnnualPlan, onClose, onAnnualPlanComplete}) => {

  return <DialogPanel isOpen={isOpenCompleteMenu} label="FINALIZAR PLAN ANUAL" onClose={onClose}>
    <Typography>¿Estás seguro de finalizar el Plan Anual?</Typography>
    <Typography>No se podrá deshacer.</Typography>
    <Stack direction='row' justifyContent='flex-end' sx={{mt:2}}>
      <Button color="secondary" onClick={onCancelAnnualPlan}>CANCELAR</Button>
      <Button variant="contained" color="secondary" onClick={onAnnualPlanComplete}>FINALIZAR</Button>
    </Stack>
  </DialogPanel>;
};

AnualPlanCompleteAlert.propTypes = {
  isOpenCompleteMenu: PropTypes.bool,
  onCancelAnnualPlan: PropTypes.func,
  onClose: PropTypes.func,
  onAnnualPlanComplete: PropTypes.func
};

AnualPlanCompleteAlert.defaultProps = {
  isOpenCompleteMenu: false
};

export default AnualPlanCompleteAlert;