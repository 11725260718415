import {EXECUTION_TYPES} from '../config';

const hasPartialExecutions = (intervention) => intervention.executions?.some(execution => execution.area_percentage !== 100);
const types = [];

export const getExecutionTypes = (intervention) => {
  if (intervention.never_do) {
    return [EXECUTION_TYPES.NEVER_DO.id];
  } else if (intervention.done && hasPartialExecutions(intervention)) {
    return [EXECUTION_TYPES.COMPLETE.id, EXECUTION_TYPES.PARTIAL.id];
  } else if (intervention.done) {
    return [EXECUTION_TYPES.COMPLETE.id];
  } else if (hasPartialExecutions(intervention)) {
    return [EXECUTION_TYPES.PARTIAL.id];
  } else return types;
};