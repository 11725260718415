import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';

//BIKENTA
import DialogPanel from '../DialogPanel';
import SectionTitle from './SectionTitle';
import UploadFile from '../UploadFile';

//UTILS
import {FILE_CONTENT_TYPES, FILE_TYPES, ROLES_LABEL} from '../../config';
import {roles} from '../proptypes/roles';
import {commoners} from '../proptypes/commoners';
import {fileDetail} from '../proptypes/fileDetail';

const CommunityDocumentationMenu = ({
  commoners,
  files,
  fileDetail,
  isOffline, 
  isCommunityDocumentationDialogOpen,
  notifications,
  role,
  rolesList,
  onCommunityDocumentationDialogClose, 
  onDeleteFile, 
  onDownloadFile, 
  onUploadFile,
  onFileDetailOpen,
  readUserNotification
}) => {
  const images = useMemo(() => files ? files.filter(file => file.content_type === FILE_CONTENT_TYPES.IMAGE_JPEG || file.content_type === FILE_CONTENT_TYPES.IMAGE_PNG) : [], [files]);
  const documents = useMemo(() => files ? files.filter(file => file.content_type === FILE_CONTENT_TYPES.DOCUMENT || file.content_type === FILE_CONTENT_TYPES.GEOPACKAGE) : [], [files]);
  
  const uploadFileComponent = (fileType, files) => <UploadFile
    commoners={commoners}
    fileDetail={fileDetail}
    fileType={fileType}
    files={files}
    isOffline={isOffline}
    notifications={notifications}
    role={role}
    rolesList={rolesList}
    onDeleteFile={onDeleteFile}
    onDownloadFile={onDownloadFile}
    onUploadFile={onUploadFile}
    onFileDetailOpen={onFileDetailOpen}
    readUserNotification={readUserNotification}
  />;
  
  const CommunityDocumentationContent = <>
    <Box display="flex" flexDirection="column" sx={{mt: 2}}>
      {uploadFileComponent(FILE_TYPES.DOCUMENT, documents)}
    </Box>
    <Box display="flex" flexDirection="column">
      {uploadFileComponent(FILE_TYPES.IMAGE, images)}
    </Box>
  </>;

  const communityDocumentationTitle = 'DOCUMENTACIÓN DE LA COMUNIDAD';

  return <>
    <Hidden lgDown>{/*DESKTOP*/}
      <SectionTitle title={communityDocumentationTitle}/>
      {CommunityDocumentationContent}
    </Hidden>
    <Hidden lgUp>{/*MOBILE*/}
      <DialogPanel
        isOpen={isCommunityDocumentationDialogOpen}
        label={communityDocumentationTitle}
        onClose={onCommunityDocumentationDialogClose}
      >
        {CommunityDocumentationContent}
      </DialogPanel>
    </Hidden>
  </>;
};

CommunityDocumentationMenu.propTypes = {
  commoners: commoners,
  isOffline: PropTypes.bool.isRequired,
  isCommunityDocumentationDialogOpen: PropTypes.bool.isRequired,
  fileDetail: fileDetail,
  files: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    content_type: PropTypes.string,
    extension: PropTypes.string,
    download_url: PropTypes.string,
    thumbnail_url: PropTypes.string
  })),
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string
    })
  ),
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)).isRequired,
  rolesList: PropTypes.arrayOf(roles),
  onUploadFile: PropTypes.func,
  onDeleteFile: PropTypes.func,
  onDownloadFile: PropTypes.func,
  onCommunityDocumentationDialogClose: PropTypes.func,
  onFileDetailOpen: PropTypes.func,
  readUserNotification: PropTypes.func
};

export default CommunityDocumentationMenu;