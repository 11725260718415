import round from '../../../utils/round';

const indicatorInterventionsArea = (filteredInterventionZones, options) => {

  if (!('categories' in options) || !('totalCommunityArea' in options)){
    throw new Error('Categories or totalCommunityArea not passed as parameter!!');
  }

  return {
    total: round(options.totalCommunityArea),
    categoryGroups: options.categories.map(categoryGroup => ({
      id: categoryGroup.key,
      label: categoryGroup.label.es,
      categories: categoryGroup.values.map(category => {
        return {
          id: category.key,
          label: category.label.es,
          value: round(filteredInterventionZones
            .map(zone => zone.interventions
              .filter(int => int.categories.includes(category.key))
              .reduce((acc) => acc + zone.area, 0))
            .reduce((acc, supByZone) => acc + supByZone, 0)),
        };
      })
    })).sort((a, b) => a.categories.id > b.categories.id ? 1 : -1)
  };
};

export default indicatorInterventionsArea;