import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogPanel from '../DialogPanel';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import ClearIcon from '@mui/icons-material/Clear';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

//BIKENTA
import AddButton from '../AddButton';
import CommonInterventionList from './CommonInterventionList';
import DetailsExecutionPanel from '../InterventionZones/InterventionMenu/ExecutionPanel/DetailsExecutionPanel';
import SectionTitle from '../Layout/SectionTitle';

//UTILS
import {ROLES_LABEL} from '../../config';
import {hasEditPermissions} from '../../modules/useCases';
import InterventionHeader from '../InterventionZones/InterventionMenu/InterventionHeader';
import {categoryFilters} from '../proptypes/categoryFilters';
import {intervention} from '../proptypes/intervention';
import {lighten} from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';

const MultipleExecutionMenu = ({
  batches,
  multiSelectModeInterventionZoneIds,
  categoryFilters,
  commonInterventions,
  interventions,
  isOffline,
  role,
  onAddButtonClick,
  onUpdateIntervention,
  onMultiselectDelete,
  onMultiselectAll,
  onMultiselectExit,
  onMultipleExecutionUpdate,
  onNeverDo
}) => {

  //STYLES
  const executableButton = {
    borderRadius: 10,
    bgcolor: 'secondary.light',
    '&:hover': {
      bgcolor: 'primary.main',
    },
    '&.Mui-disabled': {
      bgcolor: '#d7d7d7',
      color: 'common.white'
    }
  };
  const noExecutableButton = {
    borderRadius: 10,
    bgcolor: lighten('#de0606', 0.35),
    '&:hover': {
      bgcolor: 'error.main',
    },
    '&.Mui-disabled': {
      bgcolor: '#d7d7d7',
      color: 'common.white'
    }
  };
  
  const [isMultiselectInterventionMenuOpen, setMultiselectInterventionMenuOpen] = useState(false);
  const [updatedIntervention, setUpdatedIntervention] = useState(fakeIntervention);
  const [interventionIds, setInterventionIds] = useState(undefined);

  //CREATE EXECUTION PANEL
  const [isCreateExecutionMenuOpen, setCreateExecutionMenuOpen] = useState(false);
  const currentYear = new Date().getFullYear();
  
  const interventionType = useMemo(() => {
    if(interventionIds) {
      return interventions.find(intervention => intervention.id === interventionIds[0]);
    } else {
      return {};
    }
  }, [interventionIds]);

  const fakeIntervention = {
    type: interventionType.type,
    categories: interventionType.categories,
    who_made_it: '',
    executed_start_year: currentYear,
    executed_end_year: currentYear,
    executed_invest: 0,
    executed_income: 0,
    comments: '',
    never_do: commonInterventions.never_do,
    done: commonInterventions.done,
    executions: [
      {
        geom: null,
        executed_income: '',
        executed_invest: '',
        executed_start_year: currentYear.toString(),
        executed_end_year: currentYear.toString()
      }
    ],
    batch_name: '',
  };

  const handleNeverDoMultipleIntervention = () => {
    setMultiselectInterventionMenuOpen(false);
    interventionIds.map(id => onNeverDo(id));
  };

  const handleInterventionSelect = (ids, categories, done, never_do) => {
    setMultiselectInterventionMenuOpen(true);
    setInterventionIds(ids);
    setUpdatedIntervention(
      {
        ...updatedIntervention,
        categories: categories,
        done: done,
        never_do: never_do
      }
    );
  };

  const handleAccept = (multiselectionUpdate) => {
    setMultiselectInterventionMenuOpen(false);
    onMultipleExecutionUpdate({
      interventions: interventionIds,
      ...multiselectionUpdate
    });
  };

  const handleMultipleInterventionUpdate = (partialIntervention) =>
    interventionIds.map(id => onUpdateIntervention(id, partialIntervention));

  const handleAddButtonClick = () => onAddButtonClick(multiSelectModeInterventionZoneIds);
  
  const multiselectionTitle = 'Multiselección';
  return <>
    <SectionTitle title={multiselectionTitle.toUpperCase()}/>
    <Box mb={1}>
      <Typography gutterBottom>Puedes seleccionar varias Zonas de Intervención para realizar acciones a las intervenciones que tengan en común.</Typography>
      <Typography gutterBottom>Al terminar la multiselección, se mostrarán las intervenciones comunes a todas las Zonas de Intervención.</Typography>
      <Typography gutterBottom>Puedes ayudarte de los filtros para realizar la multiselección.</Typography>
    </Box>
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 1, marginBottom: 6}}>
        
        <Button variant='contained' onClick={onMultiselectAll} sx={{flexGrow: 1}}>Seleccionar todas</Button>
        <Button variant={multiSelectModeInterventionZoneIds.length === 0 ? 'outlined' : 'contained'} 
          disabled={multiSelectModeInterventionZoneIds.length === 0} onClick={onMultiselectDelete} sx={{flexGrow: 1}}>
          Limpiar selección
        </Button>

        <DialogPanel
          isOpen={isMultiselectInterventionMenuOpen}
          onClose={() => setMultiselectInterventionMenuOpen(false)}
          label="INTERVENCIÓN EN MULTISELECCIÓN"
          fullWidth
        >
          <Box sx={{p: 1.25}}>
            <InterventionHeader
              batches={batches}
              categoryFilters={categoryFilters}
              intervention={fakeIntervention}
              multiselect={true}
              role={role}
              isOffline={isOffline}
              onUpdateIntervention={handleMultipleInterventionUpdate}
            />

            <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1, mb: 2, mr: 1, gap: 2}}>
              <Button variant='contained' size='medium' startIcon={<AddIcon/>} sx={executableButton} isOffline={isOffline}
                onClick={() => setCreateExecutionMenuOpen(true)}>
                    AÑADIR EJECUCIÓN
              </Button>
              <Button variant='contained' size='medium' startIcon={<ClearIcon/>} sx={noExecutableButton} isOffline={isOffline}
                onClick={handleNeverDoMultipleIntervention}>
                    NO EJECUTABLE
              </Button>
            </Box>
          </Box>
        </DialogPanel>
      </Box>
      {
        !!commonInterventions.length && <CommonInterventionList commonInterventions={commonInterventions} onInterventionSelect={handleInterventionSelect}/>
      }
      <Box display="flex" flexDirection='row' justifyContent="flex-end" mt={2}>
        <Button variant='contained'  size='medium' startIcon={<ExitToAppIcon/>} isOffline={isOffline} onClick={onMultiselectExit}>
          SALIR
        </Button>
        {
          hasEditPermissions(role) &&
          <AddButton isOffline={isOffline} onAddButtonClick={handleAddButtonClick} disabled={multiSelectModeInterventionZoneIds.length <= 1}/>
        }
      </Box>
    </Box>
    {
      isCreateExecutionMenuOpen && <DetailsExecutionPanel
        multiselect={true}
        title='AÑADIR EJECUCIÓN'
        isEditing={true}
        isOffline={isOffline}
        onClose={() => setCreateExecutionMenuOpen(false)}
        onCreateExecution={handleAccept}
      />
    }
  </>;
};

MultipleExecutionMenu.propTypes = {
  batches: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.number, name: PropTypes.string })),
  multiSelectModeInterventionZoneIds: PropTypes.arrayOf(PropTypes.number),
  categoryFilters: categoryFilters,
  commonInterventions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  isOffline: PropTypes.bool,
  interventions: PropTypes.arrayOf(intervention),
  multiselect: PropTypes.bool,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)),
  onAddButtonClick: PropTypes.func,
  onUpdateIntervention: PropTypes.func,
  onMultiselectActivate: PropTypes.func,
  onMultiselectDelete: PropTypes.func,
  onMultiselectEnd: PropTypes.func,
  onMultiselectExit: PropTypes.func,
  onMultiselectAll: PropTypes.func,
  onMultipleExecutionUpdate: PropTypes.func,
  onNeverDo: PropTypes.func,
  onInterventionSelect: PropTypes.func
};

export default MultipleExecutionMenu;