import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import {Marker} from 'react-map-gl';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import {IcTreeBikenta} from '../icons';
import {lighten} from '@mui/material/styles';

//STYLES
const fab = {
  bgcolor: theme => lighten(theme.palette.primary.main, 0.5),
};
const selectedFab = {
  bgcolor: 'white',
  outline: '4px solid yellow',
  width: '50px',
  height: '50px'
};
const icon = {
  width: '80%',
  height: '80%',
  paddingTop: '5px',
  paddingRight: '2px'
};

// eslint-disable-next-line no-unused-vars
const CommunitiesPois = ({communitiesPoisData, onClick, size, selectedCommunityId}) => {
  //Avoid performance problems by rendering the selected poi on the top
  const selectedPoi = communitiesPoisData
    .filter(poi => poi.id === selectedCommunityId)
    .map(({id, lon, lat}) =>
      //TODO: hack mientras revelio no soluciona la respuesta de api
      <Marker key={`id${id}`} longitude={lat} latitude={lon}>
        <Fab
          onClick={() => onClick && onClick(id)}
          sx={selectedFab}
          size={size}
        >
          <Box sx={icon}>
            <IcTreeBikenta/>
          </Box>
        </Fab>
      </Marker>
    );
  const pois = useMemo(() => {
    return (communitiesPoisData
      .map(({id, lon, lat}) =>
        //TODO: hack mientras revelio no soluciona la respuesta de api
        <Marker key={id} longitude={lat} latitude={lon}>
          <Fab
            onClick={() => onClick && onClick(id)}
            sx={fab}
            size={size}
          >
            <Box sx={icon}>
              <IcTreeBikenta/>
            </Box>
          </Fab>
        </Marker>
      ));
  }, [communitiesPoisData]);
  return pois.concat(selectedPoi);
};

CommunitiesPois.propTypes = {
  communitiesPoisData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  selectedCommunityId: PropTypes.number
};

CommunitiesPois.defaultProps = {
  size: 'small'
};

export default CommunitiesPois;