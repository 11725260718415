import React from 'react';
import PropTypes from 'prop-types';

//BIKENTA
import AnnualPlanDetails from './AnnualPlanDetails';
import AnnualPlanList from './AnnualPlanList';

//UTILS
import {annualPlan} from '../proptypes/annualPlan';
import {intervention} from '../proptypes/intervention';
import {annualPlanSummary} from '../proptypes/annualPlanSummary';
import {ROLES_LABEL} from '../../config';

const AnnualPlanSidePanel = ({
  annualPlans,
  annualPlanSummary,
  isOffline,
  isAnnualPlanDialogOpen,
  role,
  selectedAnnualPlan,
  onAnnualPlanCreate,
  onAnnualPlanClick,
  onAnnualPlanComplete,
  onAnnualPlanDelete,
  onAnnualPlanDownload,
  onAnnualPlanInProcedure,
  onAnnualPlanSave,
  onAnnualPlanDialogClose
}) => {
  return <>
    {
      !selectedAnnualPlan && annualPlans && <AnnualPlanList
        annualPlans={annualPlans}
        isOffline={isOffline}
        isAnnualPlanListDialogOpen={isAnnualPlanDialogOpen}
        role={role}
        onAnnualPlanClick={onAnnualPlanClick}
        onAnnualPlanCreate={onAnnualPlanCreate}
        onAnnualPlanListDialogClose={onAnnualPlanDialogClose}

      />
    }
    {
      selectedAnnualPlan && <AnnualPlanDetails
        annualPlanSummary={annualPlanSummary}
        isAnnualPlanDetailsDialogOpen={isAnnualPlanDialogOpen}
        isOffline={isOffline}
        role={role}
        selectedAnnualPlan={selectedAnnualPlan}
        onAnnualPlanSave={onAnnualPlanSave}
        onAnnualPlanComplete={onAnnualPlanComplete}
        onAnnualPlanDelete={onAnnualPlanDelete}
        onAnnualPlanDownload={onAnnualPlanDownload}
        onAnnualPlanInProcedure={onAnnualPlanInProcedure}
        onAnnualPlanDetailsDialogClose={onAnnualPlanDialogClose}
      />
    }
  </>;
};

AnnualPlanSidePanel.propTypes = {
  annualPlans: PropTypes.arrayOf(annualPlan),
  annualPlanSummary: annualPlanSummary,
  isOffline: PropTypes.bool.isRequired,
  isAnnualPlanDialogOpen: PropTypes.bool.isRequired,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)).isRequired,
  selectedAnnualPlan: annualPlan,
  interventions: PropTypes.arrayOf(intervention),
  onAnnualPlanClick: PropTypes.func,
  onAnnualPlanCreate: PropTypes.func,
  onAnnualPlanComplete: PropTypes.func,
  onAnnualPlanDelete: PropTypes.func,
  onAnnualPlanDownload: PropTypes.func,
  onAnnualPlanInProcedure: PropTypes.func,
  onAnnualPlanSave: PropTypes.func,
  onAnnualPlanDialogClose: PropTypes.func,
};

export default AnnualPlanSidePanel;