import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const InterventionProperty = ({horizontal, title, value}) => {
  const nonEditableTitle = {
    fontWeight: 'bold',
    color: 'grey.600',
    marginRight: 2
  };
  const nonEditableValue = {
    color: 'grey.600'
  };
  return (
    <Box display="flex" flexDirection={horizontal ? 'row' : 'column'}>
      <Typography sx={nonEditableTitle}>{title}</Typography>
      <Typography sx={nonEditableValue}>{value === '' ? '-' : value}</Typography>
    </Box>
  );
};

InterventionProperty.propTypes = {
  horizontal: PropTypes.bool,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ])
};

InterventionProperty.defaultProps = {
  horizontal: true
};

export default InterventionProperty;

