import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import BiomasCO2Chart from './BiomasCO2Chart';
import SelectInput from '@geomatico/geocomponents/SelectInput';
import {ADDITIONALITY, BASELINE_CO2, CO2_LABELS, FORESTRY_PROJECT} from '../../../../config';
import TotalTons from './TotalTons';
import Typography from '@mui/material/Typography';

const AbsorptionPlanSeccion = ({baselineData, projectData}) => {

  const [ selectedCo2Id, setSelectedCo2Id ] = useState('co2_total');

  const getLast = (data, co2Type) => data && data.prediction && data.prediction[data.prediction.length - 1][co2Type];

  const formattedData = (data) => data && data.prediction && data.prediction
    .flatMap(({ year, ...co2Data }) =>
      Object.entries(co2Data).map(([co2, value]) => ({
        year: year,
        value: value.toFixed(2),
        co2: CO2_LABELS.filter(label => label.id === co2)[0].label,
        type: data.type === 'BASELINE' ? BASELINE_CO2 : FORESTRY_PROJECT
      })))
    .filter(d => d.co2 !== 'co2_interventions');

  const formattedBaseline = formattedData(baselineData);
  const formattedProject = formattedData(projectData);

  const formatNumber = (number) => Intl.NumberFormat('es-ES').format(number);

  return <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 4}}>
    {
      baselineData?.prediction || projectData?.prediction ?
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
          <BiomasCO2Chart baselineData={formattedBaseline} projectData={formattedProject} selectedCo2Id={selectedCo2Id}/>
          <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', mt: 1.5}}>
            <SelectInput options={CO2_LABELS.filter(l => l.id !== 'co2_interventions')} selectedOptionId={selectedCo2Id} onOptionChange={setSelectedCo2Id}/>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', mt: 3, gap: 1, width: '350px'}}>
              {baselineData?.prediction && <TotalTons label={BASELINE_CO2.toUpperCase()} number={formatNumber(getLast(baselineData, selectedCo2Id).toFixed())}/>}
              {projectData?.prediction && <TotalTons label={FORESTRY_PROJECT.toUpperCase()} number={formatNumber(getLast(projectData, selectedCo2Id).toFixed())}/>}
              {baselineData?.prediction && projectData?.prediction && <TotalTons label={ADDITIONALITY.toUpperCase()} number={formatNumber(getLast(projectData, selectedCo2Id).toFixed()-getLast(baselineData, selectedCo2Id).toFixed())}/>}
            </Box>
          </Box>
        </Box>
        : <Typography color='error' sx={{mb: 1, pl: 0.5}}>No hay datos para mostrar.</Typography>
    }
    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '80%'}}>
      {
        projectData?.prediction && CO2_LABELS
          .filter(l => l.id !== 'co2_total' && l.id !== 'co2_interventions')
          .map(({id, label}) =>
            <TotalTons key={id} size='small' label={label.toUpperCase()} mode='ADDITIONALITY' number={formatNumber(getLast(projectData, id).toFixed()-getLast(baselineData, id).toFixed())}/>)
      }
    </Box>
  </Box>;
};

AbsorptionPlanSeccion.propTypes = {
  baselineData: PropTypes.object,
  projectData: PropTypes.object
};

export default AbsorptionPlanSeccion;