import React from 'react';
import PropTypes from 'prop-types';

import DialogPanel from '../../DialogPanel';
import EditGeometryMap from './EditGeometryMap';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const EditGeometryMenu = ({onClose, ...otherProps}) => {
  const handleModalClose = () => {};
  const handleClose = () => onClose();
  const container = {
    height: '75vh',
    margin: '0px 10px'
  };
  return <>
    <DialogPanel
      isOpen={true}
      label='VISOR DE GEOMETRÍA'
      fullWidth
      onClose={handleModalClose}
    >
      <Box sx={container}>
        <EditGeometryMap {...otherProps} editable={false}/>
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='flex-end' mt={1} mr={1.2}>
        <Button color="secondary" variant='contained' onClick={handleClose}>Cerrar</Button>
      </Box>
    </DialogPanel>
  </>;
};

EditGeometryMenu.propTypes = {
  ...EditGeometryMap.propTypes,
  onClose: PropTypes.func
};

export default EditGeometryMenu;