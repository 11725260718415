import React, {useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';

//BIKENTA
import InterventionZoneMenu from '../InterventionZones/InterventionZoneMenu/InterventionZoneMenu';

//GEOCOMPONENTS
import BottomSheet from '@geomatico/geocomponents/BottomSheet';

//UTILS
import {ROLES_LABEL} from '../../config';
import {elastic_module} from '../proptypes/elastic_module';
import {prospectingPoints} from '../proptypes/prospectingPoints';

//STYLES
const container = {
  position: 'relative',
  height: '45vh'
};

const InterventionZoneBottomSheet = ({
  elastic_module,
  isOffline,
  role,
  specie,
  maderaPlusAvailableSpecies,
  prospectingPoints,
  selectedInterventionZone,
  isMaderaPlusMode, 
  onInterventionAdd, 
  onMaderaPlusFormOpen,
  onMaderaPlusEnd,
  onInterventionSelect
}) => {
  const [isOpen, setOpen] = useState(true);
  const handleInterventionAdd = () => onInterventionAdd && onInterventionAdd();
  const handleSelectIntervention = (interventionId) => onInterventionSelect && onInterventionSelect(interventionId);

  return <Hidden lgUp>{/*MOBILE*/}
    <BottomSheet
      closedHeight={120}
      openHeight={'50vh'}
      onToggle={() => setOpen(!isOpen)}
      isOpen={isOpen}
    >
      <Box sx={container}>
        {
          selectedInterventionZone && <InterventionZoneMenu
            age={selectedInterventionZone.age}
            area={selectedInterventionZone.area ? parseFloat(selectedInterventionZone.area.toFixed(2)) : 0}
            uo={selectedInterventionZone.uo}
            elastic_module={elastic_module}
            id={selectedInterventionZone.id}
            intervention={selectedInterventionZone.planned_interventions}
            interventions={selectedInterventionZone.interventions}
            isOffline={isOffline}
            isMaderaPlusMode={isMaderaPlusMode}
            maderaPlusAvailableSpecies={maderaPlusAvailableSpecies}
            cuartel={selectedInterventionZone.cuartel}
            role={role}
            refcat={selectedInterventionZone.refcat}
            stratum={selectedInterventionZone.stratum}
            species={selectedInterventionZone.species.length !== 0 ? selectedInterventionZone.species : []}
            specie={specie}
            prospectingPoints={prospectingPoints}
            onShareClick={() => { }}
            onExportClick={() => { }}
            onMaderaPlusFormOpen={onMaderaPlusFormOpen}
            onMaderaPlusEnd={onMaderaPlusEnd}
            onNotificationClick={() => { }}
            onInterventionAdd={handleInterventionAdd}
            onInterventionSelect={(interventionId) => handleSelectIntervention(interventionId)}
          />
        }
      </Box>
    </BottomSheet>
  </Hidden>;
};

InterventionZoneBottomSheet.propTypes = {
  elastic_module: PropTypes.arrayOf(elastic_module),
  isOffline: PropTypes.bool,
  isMaderaPlusMode: PropTypes.bool,
  maderaPlusAvailableSpecies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    existences: PropTypes.shape({
      age: PropTypes.number,
      treeFeet: PropTypes.number,
      height: PropTypes.number,
      basometricArea: PropTypes.number,
      volumeWithBark: PropTypes.number,
      volumeWithoutBark: PropTypes.number,
      biomass: PropTypes.number,
    })
  })),
  prospectingPoints: prospectingPoints,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)),
  selectedInterventionZone: PropTypes.object,
  specie: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    existences: PropTypes.shape({
      age: PropTypes.number,
      treeFeet: PropTypes.number,
      height: PropTypes.number,
      basometricArea: PropTypes.number,
      volumeWithBark: PropTypes.number,
      volumeWithoutBark: PropTypes.number,
      biomass: PropTypes.number,
    })
  }),
  onMaderaPlusFormOpen: PropTypes.func,
  onMaderaPlusEnd: PropTypes.func,
  onInterventionAdd: PropTypes.func,
  onInterventionSelect: PropTypes.func
};

export default InterventionZoneBottomSheet;