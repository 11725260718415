import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import ReadOnlyData from './ReadOnlyData';
import EditableData from './EditableData';
import Typography from '@mui/material/Typography';
import {MACHINING_DIFFICULTY, SPECIES_INCLUDED_BIOMASCO2} from '../../../../config';
import SelectInput from '@geomatico/geocomponents/SelectInput';

const MassDescription = ({id_rodal, species_id, age, area, number_per_ha, dominant_height, basimetric_area, site_index, wildfire_risk, machining_difficulty_id, onMassChange}) => {

  const speciesOptions = SPECIES_INCLUDED_BIOMASCO2.map(s => (
    {
      id: s.xorforId.toString(),
      label: s.name.toUpperCase()
    }
  ));

  return <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 1}}>
    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 4}}>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 0.5}}>
        <ReadOnlyData label='ID Rodal' value={id_rodal}/>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5.5}}>
          <Typography sx={{fontWeight: 900}}>Especie</Typography>
          <SelectInput
            options={speciesOptions}
            selectedOptionId={species_id.toString()}
            onOptionChange={(value) => onMassChange({species_id: value})}
            sx={{'& .SelectInput-select': {width: '225px'}}}
          />
        </Box>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 0.5}}>
        <EditableData onChange={(value) => onMassChange({initial_age: value})} label='Edad' value={age} min={0}/>
        <EditableData onChange={(value) => onMassChange({area: value})} label='Área' value={area} min={0}/>
        <EditableData onChange={(value) => onMassChange({number_per_ha: value})} label='Nºpies/ha' value={number_per_ha} min={0} isRequired={true}/>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 0.5}}>
        <EditableData onChange={(value) => onMassChange({dominant_height: value})} label='Ho(m)' value={dominant_height} min={0} isRequired={true}/>
        <EditableData onChange={(value) => onMassChange({basimetric_area: value === 0 ? undefined : value})} label='G' value={basimetric_area} min={1}/>
        <EditableData onChange={(value) => onMassChange({site_index: value === 0 ? undefined : value})} label='IS' value={site_index} min={0}/>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 0.5}}>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5.5}}>
          <Typography sx={{fontWeight: 900}}>Zona riesgo incendio</Typography>
          <Typography>{wildfire_risk}</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3.5}}>
          <Typography sx={{fontWeight: 900}}>Dificultad mecanizado</Typography>
          <SelectInput
            options={MACHINING_DIFFICULTY}
            selectedOptionId={machining_difficulty_id}
            onOptionChange={(value) => onMassChange({machining_difficulty_id: value})}
            sx={{'& .SelectInput-select': {width: '150px'}}}
          />
        </Box>
      </Box>
    </Box>
  </Box>;
};

MassDescription.propTypes = {
  id_rodal: PropTypes.number.isRequired,
  species_id: PropTypes.string.isRequired,
  age: PropTypes.number,
  area: PropTypes.number.isRequired,
  number_per_ha: PropTypes.number,
  dominant_height: PropTypes.number,
  basimetric_area: PropTypes.number,
  site_index: PropTypes.number,
  wildfire_risk: PropTypes.string,
  machining_difficulty_id: PropTypes.string,
  onMassChange: PropTypes.func.isRequired
};

export default MassDescription;