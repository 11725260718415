import createTheme from '@mui/material/styles/createTheme';

import '@fontsource/montserrat';
import {lighten} from '@mui/material/styles';
import {darken} from '@mui/material';
import {APP_MODES} from './config';

export const PRIMARY_MAIN_GREEN = '#86B54D';
export const PRIMARY_LIGHT_GREEN = lighten(PRIMARY_MAIN_GREEN, 0.15);
export const PRIMARY_DARK_GREEN = darken(PRIMARY_MAIN_GREEN, 0.25);

export const SECONDARY_MAIN_GREEN = '#107540';
export const SECONDARY_LIGHT_GREEN = lighten(SECONDARY_MAIN_GREEN, 0.15);
export const SECONDARY_DARK_GREEN = darken(SECONDARY_MAIN_GREEN, 0.25);

export const PRIMARY_MAIN_BROWN = '#D2691E';
export const PRIMARY_LIGHT_BROWN = lighten(PRIMARY_MAIN_BROWN, 0.15);
export const PRIMARY_DARK_BROWN = darken(PRIMARY_MAIN_BROWN, 0.25);

export const SECONDARY_MAIN_BROWN = '#572b0b';
export const SECONDARY_LIGHT_BROWN = lighten(SECONDARY_MAIN_BROWN, 0.15);
export const SECONDARY_DARK_BROWN = darken(SECONDARY_MAIN_BROWN, 0.25);

export const PRIMARY_MAIN_GREY = '#b2b2b2';
export const PRIMARY_LIGHT_GREY = lighten(PRIMARY_MAIN_GREY, 0.15);
export const PRIMARY_DARK_GREY = darken(PRIMARY_MAIN_GREY, 0.25);

export const SECONDARY_MAIN_GREY = '#818181';
export const SECONDARY_LIGHT_GREY = lighten(SECONDARY_MAIN_GREY, 0.15);
export const SECONDARY_DARK_GREY = darken(SECONDARY_MAIN_GREY, 0.25);

const theme = (mode) => createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: mode === APP_MODES.DEFAULT ? PRIMARY_MAIN_GREEN : mode === APP_MODES.OFFLINE ? PRIMARY_MAIN_GREY : PRIMARY_MAIN_BROWN,
      light: mode === APP_MODES.DEFAULT ? PRIMARY_LIGHT_GREEN : mode === APP_MODES.OFFLINE ? PRIMARY_LIGHT_GREY : PRIMARY_LIGHT_BROWN,
      dark: mode === APP_MODES.DEFAULT ? PRIMARY_DARK_GREEN : mode === APP_MODES.OFFLINE ? PRIMARY_DARK_GREY: PRIMARY_DARK_BROWN,
      contrastText: '#fff',
    },
    secondary: {
      main: mode === APP_MODES.DEFAULT ? SECONDARY_MAIN_GREEN : mode === APP_MODES.OFFLINE ? SECONDARY_MAIN_GREY : SECONDARY_MAIN_BROWN,
      light: mode === APP_MODES.DEFAULT ? SECONDARY_LIGHT_GREEN : mode === APP_MODES.OFFLINE ? SECONDARY_LIGHT_GREY : SECONDARY_LIGHT_BROWN,
      dark: mode === APP_MODES.DEFAULT ? SECONDARY_DARK_GREEN : mode === APP_MODES.OFFLINE ? SECONDARY_DARK_GREY : SECONDARY_DARK_BROWN,
    },
    text: {
      primary: '#000000',
      secondary: '#746F81',
    },
    action: {
      disabled: '#CCCCCC',
    }
  },
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      fontSize: 24,
      fontWeight: 700,
      color: PRIMARY_MAIN_GREEN
    },
    h2: {
      fontSize: 16,
      letterSpacing: 1,
    },
    body1: {
      fontSize: 14,
    },
    button: {
      fontSize: 12,
      letterSpacing: 1.2,
      fontWeight: 'bold'
    },
    body2: {
      fontSize: 11,
    },
    caption: {
      fontSize: 9,
      letterSpacing: 1,
    }
  },
  overrides: {
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: mode === APP_MODES.ANNUALPLAN ? SECONDARY_MAIN_BROWN : SECONDARY_MAIN_GREEN,
        },
        '&$active': {
          color: mode === APP_MODES.ANNUALPLAN ? SECONDARY_MAIN_BROWN : SECONDARY_MAIN_GREEN,
        },
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'body': {
          scrollbarColor: 'transparent transparent'   /* scroll thumb and track */
        },
        '*::-webkit-scrollbar': {
          width: '0.2em',
          height: '0em'
        },
        '*::-webkit-scrollbar-track': {
          WebkitBoxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '0px solid slategrey'
        }
      }
    }
  }
});

export default theme;
