import React from 'react';
import PropTypes from 'prop-types';

import Slider from '@mui/material/Slider';
import grey from '@mui/material/colors/grey';

const slider = {
  color: 'primary.main',
  '& .MuiSlider-rail': {
    height: 6,
    opacity: 1,
    backgroundColor: 'primary.main',
  },
  '& .MuiSlider-thumb': {
    backgroundColor: 'secondary.main',
  },
  '& .MuiSlider-markLabel': {
    color: grey[900]
  },
};

const StepSlider = ({min, max, defaultValue, marks, onValueChange, value}) => {
  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      onValueChange(newValue);
    }
  };
  return <Slider
    step={null}
    marks={marks}
    min={min}
    max={max}
    defaultValue={defaultValue}
    onChange={handleChange}
    color='secondary'
    value={value}
    sx={slider}
  />;
};

StepSlider.propTypes = {
  isRange: PropTypes.bool,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  defaultValue: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
  marks: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
  onValueChange: PropTypes.func.isRequired
};

StepSlider.defaultProps = {
  isRange: false,
};

export default StepSlider;