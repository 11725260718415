import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AlertDialog = ({title, description, isOpen, onAccept, onCancel}) => {
  const handleCancel = () => onCancel();
  const handleDelete = () => onAccept();
  const titleStyle = {
    color: 'secondary.main',
    '& h2': {
      fontWeight: 900
    }
  };

  return <Dialog open={isOpen}>
    <DialogTitle sx={titleStyle}>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {description}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {onCancel && <Button onClick={handleCancel} color="secondary" variant='text'>
        Cancelar
      </Button>}
      {onAccept && <Button onClick={handleDelete} color="secondary">
        Aceptar
      </Button>}
    </DialogActions>
  </Dialog>;
};

AlertDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: (props, propName, componentName) => {
    if (!props.title && !props.description) {
      return new Error(`One of props 'title' or 'description' was not specified in '${componentName}'.`);
    }
  },
  description: (props, propName, componentName) => {
    if (!props.title && !props.description) {
      return new Error(`One of props 'title' or 'description' was not specified in '${componentName}'.`);
    }
  },
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};

AlertDialog.defaultProps = {
  isOpen: true,
};

export default AlertDialog;