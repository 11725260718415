import React from 'react';
import PropTypes from 'prop-types';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

function Carousel({children, activeIndex, onActiveIndexChange, infinite}) {
  const handleActiveIndexChange = e => onActiveIndexChange(e.item);

  return (
    <AliceCarousel
      autoWidth
      mouseTracking
      disableButtonsControls
      disableDotsControls
      activeIndex={activeIndex}
      onSlideChanged={handleActiveIndexChange}
      items={children}
      infinite={infinite}
    />
  );
}

Carousel.propTypes = {
  activeIndex: PropTypes.number,
  onActiveIndexChange: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.node),
  infinite: PropTypes.bool,
};

Carousel.defaultProps = {
  activeIndex: 0,
  children: null,
  onActiveIndexChange: () => {},
  infinite: false,
};

export default Carousel;
