import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';

import { Route, Redirect } from 'react-router-dom';
import {isLoggedIn} from '../modules/api/selectors';
import {apiLoginSuccess} from '../modules/api/actions';
import {getCredentials as getStorageCredentials} from '../services/localStorage';

const PrivateRoute = ({ children, ...rest }) => {
  const loggedIn = useSelector(isLoggedIn);
  const dispatch = useDispatch();
  const {username, token} = getStorageCredentials();

  useEffect(() => {
    if (username && token) {
      dispatch(apiLoginSuccess({username, token}));
    }
  }, []);

  return <Route {...rest}
    render={({ location }) =>
      loggedIn ? (
        children
      ) : token ? <></> :(
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
      )
    }
  />;
};

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default PrivateRoute;