import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import BlockIcon from '@mui/icons-material/Block';
import grey from '@mui/material/colors/grey';

import Categories from '../icons/Categories';
import IconWrapper from '../IconWrapper';
import Row from '../Row';

//STYLES
export const card = {
  width: '230px',
  height: 'auto',
  mr: 0.5,
  cursor: 'pointer',
  p: 1
};
export const title = {
  color: 'secondary.main',
  fontWeight: 900,
  textOverflow: 'ellipsis',
  mr: 0.5,
};
export const labelStyle = {
  fontWeight: 900,
  paddingRight: 1,
  color: 'black'
};
export const valueStyle = {
  margin: '0 8px 0 4px',
  color: grey[700],
};
const valueOverflow = {
  margin: '0 8px 0 4px',
  color: grey[700],
  width: '90%',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
};

const InterventionZoneCard = ({
  age,
  annualPlansAssigned,
  area,
  cuartel,
  id,
  interventionCategories,
  refcat,
  stratum,
  ua,
  uo,
  onInterventionZoneSelect
}) => {
  const CategoryIcons = useMemo(() => <Row>
    {interventionCategories.length === 0 ? 
      <IconWrapper>
        <BlockIcon color='disabled'/>
      </IconWrapper> : 
      interventionCategories.map((category, index) => <IconWrapper key={index}>
        <Categories categoryId={category}/>
      </IconWrapper>)}
  </Row>, [interventionCategories]);

  return <Card elevation={3} onClick={() => onInterventionZoneSelect(id)} sx={card}>
    {CategoryIcons}
    <Row sx={{m: 0}}>
      <Row sx={{mr: 1}}>
        <Typography variant='body1' sx={title}>UA</Typography>
        <Typography sx={title}>{ua}</Typography>
      </Row>
      <Row sx={{mr: 1}}>
        <Typography variant='body1' sx={title}>UO</Typography>
        <Typography sx={title}>{uo}</Typography>
      </Row>
      <Row>
        <Typography variant='body1' sx={title}>Cuartel</Typography>
        <Typography sx={title}>{cuartel}</Typography>
      </Row>
    </Row>
    <Typography variant='body2' sx={{color: grey[700]}} gutterBottom>REF. CATASTRAL: {refcat}</Typography>
    <Stack sx={{mt: {xs: 0.5, sm: 0}, flexDirection: {xs: !stratum ? 'column' : 'row', sm: 'column'}, mb: 0.5}}>
      <Typography variant='body2' sx={labelStyle}>Estrato:</Typography>
      <Typography variant='body2' sx={valueOverflow}>{!stratum ? 'Sin estratificar' : stratum}</Typography>
    </Stack>
    <Row sx={{mt: {xs: 0.5, sm: 0}}}>
      <Typography variant='body2' sx={labelStyle}>Edad:</Typography>
      <Typography variant='body2' sx={valueStyle} noWrap>{age}</Typography>
      <Typography variant='body2' sx={labelStyle}>Superficie: </Typography>
      <Typography variant='body2' sx={valueStyle} noWrap>{area.toFixed(2)}</Typography>
    </Row>
    <Row sx={{mt: {xs: 0.5, sm: 0}}}>
      <Typography variant='body2' sx={labelStyle}>Planes Anuales:</Typography>
      <Tooltip title={annualPlansAssigned && annualPlansAssigned.join(' | ')}>
        <Typography variant='body2' noWrap sx={{...valueStyle, width: '50%'}}>
          {annualPlansAssigned && annualPlansAssigned.length === 0 ? '-' : annualPlansAssigned.join(' | ')}
        </Typography>
      </Tooltip>
    </Row>
  </Card>;
};

InterventionZoneCard.propTypes = {
  age: PropTypes.number.isRequired,
  annualPlansAssigned: PropTypes.arrayOf(PropTypes.number),
  area: PropTypes.number.isRequired,
  cuartel: PropTypes.string,
  id: PropTypes.number.isRequired,
  interventionCategories: PropTypes.arrayOf(PropTypes.number),
  planned_interventions: PropTypes.oneOf(['Sí', 'No']),
  refcat: PropTypes.string,
  stratum: PropTypes.string.isRequired,
  ua: PropTypes.string,
  uo: PropTypes.string,
  onInterventionZoneSelect: PropTypes.func,
};

export default InterventionZoneCard;