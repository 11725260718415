import React, {useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';

//UTILS
import {MAP_DETAIL_STYLES, MAP_DETAILS} from '../../config';

const Legend = ({legendTarget, isInterventionZoneSelected}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const infoIcon = {
    position: 'absolute',
    top: {
      xs: isInterventionZoneSelected ? 50 : 90,
      sm: 10
    },
    right: {
      xs: isInterventionZoneSelected ? 18 : 6,
      sm: 8
    }
  };

  return <Box sx={infoIcon}>
    <InfoIcon sx={{color: '#ffffff', cursor: 'pointer'}} onClick={handleClick}/>
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{margin: 1, opacity: 0.85, position: 'absolute', top: 20, height: '85vh', '& .MuiPaper-root': {width: '250px'}}}
    >
      {
        legendTarget.map(target => {
          const legendStyle = MAP_DETAIL_STYLES.find(style => style.id === MAP_DETAILS[target]);
          return <div key={target}>
            <Typography align='center' component='h5' sx={{fontSize: '13px', fontWeight: 700, my: 1}}>{legendStyle.label.toUpperCase()}</Typography>
            {
              legendStyle.colors.map(item => <List dense disablePadding key={item.id}>
                <ListItem sx={{m: 0, py: target === MAP_DETAILS.especies ? 0 : undefined}}>
                  {
                    target === MAP_DETAILS.business ?
                      <ListItemAvatar sx={{minWidth: '24px'}}>
                        <Avatar sx={{
                          width: 24,
                          height: 24,
                          bgcolor: item.paint.color,
                          mr: 1,
                          '& .MuiSvgIcon-root': {fill: 'white', fontSize: '16px'}
                        }}>
                          {item.icon}
                        </Avatar>
                      </ListItemAvatar> :
                      target !== MAP_DETAILS.business ?
                        <Box sx={{bgcolor: item.paint.color, width: 14, height: 14, borderRadius: '50%', mx: 1, opacity: 0.75}}/> : undefined
                  }
                  <ListItemText primary={item.name} sx={{ml: target === MAP_DETAILS.especies ? 1 : undefined, maxWidth: '85%'}}/>
                </ListItem>
              </List>)
            }
          </div>;
        })
      }
    </Menu>
  </Box>;
};

Legend.propTypes = {
  legendTarget: PropTypes.arrayOf(PropTypes.string),
  isInterventionZoneSelected: PropTypes.bool.isRequired
};

export default Legend;