import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//BIKENTA
import DialogPanel from '../../../DialogPanel';

const NeverDoExecutionChangeAlert = ({isOpenNeverDoExecutionChangeMenu, onClose, onNeverDoExecutionChangeAccept}) => {
  return <DialogPanel isOpen={isOpenNeverDoExecutionChangeMenu} label="INTERVENCIÓN NO EJECUTABLE" onClose={onClose} fullWidth={false}>
    <Typography>Esta intervención ha sido marcada como NO EJECUTABLE y no se puede cambiar, puedes Cancelar la edición en el botón de Cancelar Detalles de la Intervención.</Typography>
    <Stack direction='row' justifyContent='flex-end' sx={{mt:2}}>
      <Button variant="contained" color="secondary" onClick={onNeverDoExecutionChangeAccept}>ACEPTAR</Button>
    </Stack>
  </DialogPanel>;
};

NeverDoExecutionChangeAlert.propTypes = {
  isOpenNeverDoExecutionChangeMenu: PropTypes.bool,
  onClose: PropTypes.func,
  onNeverDoExecutionChangeAccept: PropTypes.func
};

NeverDoExecutionChangeAlert.defaultProps = {
  isOpenNeverDoExecutionChangeMenu: false
};

export default NeverDoExecutionChangeAlert;