import * as React from 'react';

function SvgIcUser1X(props) {
  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M15.508,13.4818835 L12.1666667,12.6465502 L12.1666667,11.4419669 C13.212,10.6638002 13.9166667,9.27488354 13.9166667,7.97988354 L13.9166667,5.93355021 C13.9166667,4.97105021 13.1326667,4.18355021 12.1660833,4.18355021 L11.9479167,4.18355021 L11.4888333,3.26480021 C11.378,3.04255021 11.1114167,2.94338354 10.888,3.03263354 L8.13525,4.13338354 C7.46208333,4.40288354 6.91666667,5.20905021 6.91666667,5.93413354 L6.91666667,7.97988354 C6.91666667,9.27488354 7.62133333,10.6638002 8.66666667,11.4419669 L8.66666667,12.6465502 L5.32533333,13.4818835 C4.54658333,13.6767169 4,14.3767169 4,15.1793835 L4,16.7298835 L16.8333333,16.7298835 L16.8333333,15.1793835 C16.8333333,14.3767169 16.28675,13.6767169 15.508,13.4818835 Z"
          id="Shape"
          fill="#DAD9E2"/>
        <path d="M0 0h20v20H0z" />
      </g>
    </svg>
  );
}

export default SvgIcUser1X;
