import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

const Row = ({children, sx}) => {
  return <Box display='flex' flexDirection='row' alignItems='center' sx={sx}>
    {children}
  </Box>;
};

Row.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  sx: PropTypes.object
};

export default Row;