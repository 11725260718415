import React, {useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import {DataGrid, esES} from '@mui/x-data-grid';

//MUI-ICONS
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ChairIcon from '@mui/icons-material/Chair';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ForestIcon from '@mui/icons-material/Forest';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import LayersIcon from '@mui/icons-material/Layers';
import CarpenterIcon from '@mui/icons-material/Carpenter';
import CloseIcon from '@mui/icons-material/Close';

//GEOCOMPONENTS
import SearchBox from '@geomatico/geocomponents/SearchBox';

//UTILS
import {business} from '../proptypes/business';

//STYLES
import {useTheme} from '@mui/styles';
import {useMediaQuery} from '@mui/material';
import IconButton from '@mui/material/IconButton';

const listStyle = {
  fontSize: '14px',
  boxShadow: 2,
  '& .MuiDataGrid-columnHeaders': {
    border: theme => `1.5px solid ${theme.palette.primary.main}`,
    bgcolor: '#E3E9D5',
    fontSize: '14px'
  },
  '&.MuiPaper-root': {
    border: 2,
    zIndex: 10000
  },
  '&.MuiDataGridPanel-root': {
    border: 2,
    zIndex: 10000
  },
  '& .MuiDataGrid-toolbarContainer': {
    height: '48px'
  },
  '& .MuiButton-root': {
    visibility: 'hidden'
  }
};
const button = {
  backgroundColor: 'secondary.main',
  color: 'primary.contrastText',
};

export default function BusinessList ({business, onBusinessListClose}) {
  const theme = useTheme();
  const widescreen = useMediaQuery(theme.breakpoints.up('md'), {noSsr: true});
  const [searchText, setSearchText] = useState('');

  const businessList = business.map(item => ({
    id: item.id,
    resfor_number: item.resfor_number,
    name: item.name,
    address: item.address,
    cnae: item.cnae,
    type: item.type.display_name
  }));

  const [filteredBusiness, setFilteredBusiness] = useState(businessList);

  const handleSearchClick = () => setFilteredBusiness(businessList
    .filter(obj => JSON.stringify(obj).toString().toLowerCase().includes(searchText.toLowerCase())));

  const handleOnTextChange = (ev) => setSearchText(ev);

  const BUSINESS_TYPE = {
    MOBLES: 'Mobles',
    SILVICULTURA: 'Silvicultura',
    PAPEL: 'Papel',
    ASSERRADERO: 'Aserradero',
    TABOLEIRO: 'Taboleiro',
    OUTROS: 'Outros'
  };

  const typeStyles = [
    {
      id: BUSINESS_TYPE.MOBLES,
      icon: <ChairIcon style={{ fill: 'white', fontSize: '22px', paddingLeft: '2px'}}/>,
      style: {backgroundColor: '#074F57', color: 'white', fontWeight: 900, fontSize: '12px'}
    },
    {
      id: BUSINESS_TYPE.SILVICULTURA,
      icon: <ForestIcon style={{ fill: 'white', fontSize: '22px', paddingLeft: '2px'}}/>,
      style: {backgroundColor: '#00C49A', color: 'white', fontWeight: 900}
    },
    {
      id: BUSINESS_TYPE.PAPEL,
      icon: <AutoStoriesIcon style={{ fill: 'white', fontSize: '22px', paddingLeft: '2px'}}/>,
      style: {backgroundColor: '#F7996E', color: 'white', fontWeight: 900}
    },
    {
      id: BUSINESS_TYPE.ASSERRADERO,
      icon: <CarpenterIcon style={{ fill: 'white', fontSize: '22px', paddingLeft: '2px'}}/>,
      style: {backgroundColor: '#0E9AAA', color: 'white', fontWeight: 900}
    },
    {
      id: BUSINESS_TYPE.TABOLEIRO,
      icon: <LayersIcon style={{ fill: 'white', fontSize: '22px', paddingLeft: '2px'}}/>,
      style: {backgroundColor: '#F45B69', color: 'white', fontWeight: 900}
    },
    {
      id: BUSINESS_TYPE.OUTROS,
      icon: <AutoAwesomeIcon style={{ fill: 'white', fontSize: '22px', paddingLeft: '2px'}}/>,
      style: {backgroundColor: '#976391', color: 'white', fontWeight: 900}
    }
  ];

  const getChipProps = (params) => {
    return {
      icon: typeStyles.find(type => type.id === params.value).icon,
      label: params.value.toUpperCase(),
      style: typeStyles.find(type => type.id === params.value).style,
    };
  };

  const renderBusinessType = params => <Chip variant='filled' size='small' {...getChipProps(params)} />;

  const columns = [
    { field: 'id', headerName: 'ID', width: widescreen && 1, editable: false, visible: false },
    { field: 'resfor_number', headerName: 'RESFOR', width: 100, editable: false, align: 'center' },
    { field: 'type', headerName: 'TIPO', width: 180, editable: false, align: 'center', headerAlign: 'center', renderCell: renderBusinessType},
    { field: 'name', headerName: 'NOMBRE', width: 180, flex: widescreen && 1, editable: false },
    { field: 'address', headerName: 'DIRECCIÓN', width: 180, flex: widescreen && 1, editable: false },
    { field: 'cnae', headerName: 'CNAE', width: 180, flex: widescreen && 1, editable: false }
  ];

  return <div style={{ height: '80vh', width: '100%'}}>
    <Box sx={{display: 'flex', flexDirection: widescreen ? 'row' : 'column', justifyContent: 'space-between', bgcolor: 'primary.main', alignItems: widescreen ? 'center' : 'flex-start'}}>
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
        <Typography sx={{m:1, fontWeight: 900, color: 'common.white'}} variant='h5'>LISTADO DE EMPRESAS</Typography>
        {
          !widescreen && <IconButton onClick={onBusinessListClose} sx={{color: 'common.white'}}>
            <CloseIcon/>
          </IconButton>
        }
      </Box>
      <SearchBox
        text={searchText}
        placeholder={'Buscar empresa'}
        AdvanceSearchIcon={KeyboardBackspaceIcon}
        onSearchClick={handleSearchClick}
        onTextChange={handleOnTextChange}
        dense
        sx={{
          '&.SearchBox-root': {
            height: '32px',
            width: widescreen ? '18%' : '90vw',
            marginBottom: 2,
            marginTop: widescreen ? 2 : 1,
            marginRight: widescreen ? 2 : 1,
            marginLeft: widescreen ? 0 : 1
          },
        }}
      />
    </Box>
    {business && <DataGrid
      initialState={{
        sorting: {
          sortModel: [{field: 'resfor_number', sort: 'asc'}]
        },
        columns: {
          columnVisibilityModel: {
            id: false
          }
        }
      }}
      density='compact'
      sx={listStyle}
      rows={filteredBusiness}
      columns={columns}
      localeText={esES.components.MuiDataGrid.defaultProps.localeText}
    />}
    {
      widescreen && <Box display="flex" justifyContent="flex-end" alignItems="center" m={2}>
        <Button variant="contained" size="medium" sx={button} onClick={onBusinessListClose}>SALIR</Button>
      </Box>
    }
  </div>;
}

BusinessList.propTypes = {
  business: business,
  onBusinessListClose: PropTypes.func,
};