import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

//BIKENTA
import BusinessList from '../components/BusinessList/BusinessList';

//UTILS
import {business} from '../components/proptypes/business';

//STYLES
import {useTheme} from '@mui/styles';
import {useMediaQuery} from '@mui/material';
import {MINI_SIDE_PANEL_WIDTH} from '../config';

const BusinessListView = ({business, onBusinessListClose}) => {
  const theme = useTheme();
  const widescreen = useMediaQuery(theme.breakpoints.up('lg'), {noSsr: true});
  return <Dialog
    elevation={0}
    fullScreen
    TransitionComponent={Slide}
    sx={{
      top: widescreen ? 62 : 0,
      left: widescreen ? MINI_SIDE_PANEL_WIDTH-2 : 0,
      right: widescreen ? -4 : 0,
      zIndex: 200
    }}
    open={true}>
    <BusinessList
      business={business}
      onBusinessListClose={onBusinessListClose}
    />
  </Dialog>;
};

BusinessListView.propTypes = {
  business: business,
  onBusinessListClose: PropTypes.func
};

export default BusinessListView;

