import ReactDOM from 'react-dom';
import React, {useState} from 'react';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import {Provider} from 'react-redux';
import store from './store';

import ThemeProvider from '@mui/material/styles/ThemeProvider';
import {StyledEngineProvider} from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';

import Routes from './routes/routes';
import PushNotification from './components/PushNotification';
import {activateOffline, deactivateOffline} from './actions';
import {APP_MODES} from './config';

if (process.env.NODE_ENV === 'production') {
  const NO_PERFORMANCE = 0;

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: NO_PERFORMANCE,
  });
}

window.isUpdateAvailable = new Promise((resolve) => {
  if (process.env.NODE_ENV !== 'development') {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('service-worker.js')
          .then(registration => {
            registration.onupdatefound = () => {
              const installingWorker = registration.installing;
              installingWorker.onstatechange = () => {
                switch (installingWorker.state) {
                case 'installed':
                  if (navigator.serviceWorker.controller) {
                    // new update available
                    resolve(true);
                  } else {
                    // no update available
                    resolve(false);
                  }
                  break;
                }
              };
            };
          }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
          });
      });
    }
  }
});


const App = () => {
  const [isUpdateAvaliable, setIsUpdateAvaliable] = useState(false);
  const [mode, setMode] = useState('default');


  store.subscribe(() =>
    setMode(store.getState().app.offline ? APP_MODES.OFFLINE : store.getState().app.selectedAnnualPlan ? APP_MODES.ANNUALPLAN : APP_MODES.DEFAULT)
  );

  window['isUpdateAvailable']
    .then(isAvailable => {
      if (isAvailable) {
        setIsUpdateAvaliable(true);
      }
    });
  
  window.addEventListener('offline', () => {
    store.dispatch(activateOffline());
  });

  window.addEventListener('online', () => {
    store.dispatch(deactivateOffline());
  });
  
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme(mode)}>
          <PushNotification
            visible={isUpdateAvaliable}
            message={'Nueva versión disponible'}
            actionText={'Refrescar'}
            onAction={() => location.reload()}
            onClose={() => setIsUpdateAvaliable(false)}
          />
          <CssBaseline/>
          <Routes/>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

const target = document.getElementById('app');
if (target) ReactDOM.render(<App />, target);

export default App;
