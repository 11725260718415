import React, {useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';

//BIKENTA
import AddButton from '../../AddButton';

//UTILS
import {getExecutionTypes} from '../../../utils/getExecutionTypes';
import {hasEditPermissions} from '../../../modules/useCases';
import IcBatch from '../../icons/IcBatch';
import {intervention} from '../../proptypes/intervention';
import {lighten} from '@mui/material/styles';
import {ROLES_LABEL, EXECUTION_TYPES} from '../../../config';
import {annualPlan} from '../../proptypes/annualPlan';
import {getYearsRangeExecutions} from '../../../utils/getYearsRangeExecutions';


const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const headCells = [
  {
    id: 'done',
    label: ' ',
    minWidth: '10px'
  },
  {
    id: 'planned_start_year',
    label: 'Plan',
    minWidth: '82px'
  },
  {
    id: 'batch',
    label: 'Lote',
    minWidth: '20px'
  },
  {
    id: 'name',
    label: 'Intervención',
    minWidth: '70px'
  },
  {
    id: 'executed_start_year',
    label: 'Ejecución',
    minWidth: '70px'
  }
];

const tableHeader = {
  m: 0,
  p: (0, 0, 0, 0),
  bgcolor: theme => lighten(theme.palette.primary.main, 0.70),
  borderBottom: theme => `1.5px solid ${theme.palette.primary.main}`
};

const MenuInterventionsList = ({interventions, isOffline, role, selectedAnnualPlan, onInterventionAdd, onInterventionSelect}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('1');

  const handleSelectIntervention = (intervention) => {
    onInterventionSelect && onInterventionSelect(intervention.id);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const formatInterventions = (interventions) => interventions.map(intervention => {
    return {
      id: intervention.id,
      batch: intervention.batch,
      never_do: intervention.never_do,
      done: intervention.done,
      name: intervention.type.name,
      planned_start_year: intervention.planned_start_year,
      planned_end_year: intervention.planned_end_year,
      executed_start_year: intervention.executions.reduce((min, execution) => {
        const executedStartYear = parseInt(execution.executed_start_year); // Convertir a número entero
        return executedStartYear < min ? executedStartYear : min;
      }, 0),
      executed_end_year: intervention.executions.reduce((max, execution) => {
        const executedEndYear = parseInt(execution.executed_end_year); // Convertir a número entero
        return executedEndYear > max ? executedEndYear : max;
      }, 0),
      executions: intervention.executions,
      planned_assigned: intervention.planned_assigned?.map(p => p.year),
    };
  });

  const tableIntervention = (intervention) => {
    return {
      bgcolor: theme => intervention.planned_assigned && intervention?.planned_assigned.includes(selectedAnnualPlan?.year) ? lighten(theme.palette.primary.main, 0.85) : 'transparent',
      '&:last-child td': {
        borderBottom: 0,
      },
    };
  };

  const getExecutionTypeIcon = types => types.map(type => EXECUTION_TYPES[type].icon);

  const hasAnyBatchAssigned = intervention => intervention.batch;

  return <>
    <TableContainer sx={{height: 'calc(100% - 114px)', overflowY: 'auto', width: '100%', mb: 1}}>
      <Table size="small" stickyHeader sx={{border: theme => `1.5px solid ${theme.palette.primary.main}`}}>
        <TableHead sx={{height: '38px'}}>
          <TableRow>
            {
              headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={tableHeader}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    sx={{minWidth: headCell.minWidth}}
                  >
                    {headCell.label.toUpperCase()}
                  </TableSortLabel>
                </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        {
          interventions && <TableBody sx={{border: 2}} id='listado-intervenciones-body'>
            {formatInterventions(interventions)
              .sort(getComparator(order, orderBy))
              .map(intervention => {
                return <TableRow
                  hover
                  key={intervention.id}
                  onClick={() => handleSelectIntervention(intervention)}
                  sx={tableIntervention(intervention)}
                >
                  <TableCell sx={{py: 0.5}} width="5%">
                    <Box display='flex' flexDirection='row'>
                      {getExecutionTypeIcon(getExecutionTypes(intervention))}
                    </Box>
                  </TableCell>
                  <TableCell align="left" sx={{pl: 0}} width="70px">
                    {`${intervention.planned_start_year}-${intervention.planned_end_year}`}
                  </TableCell>
                  <TableCell align="left" sx={{pl: 0}} width="24px">
                    {
                      hasAnyBatchAssigned(intervention) && <IcBatch sx={{mt: 0.5, color: 'grey.600'}}/>
                    }
                  </TableCell>
                  <TableCell align="left" sx={{pl: 0, textOverflow: 'ellipsis'}} width="65%">
                    {intervention.name}
                  </TableCell>
                  <TableCell align='center' sx={{pl: 0}} width="5%">
                    {
                      intervention.never_do || !intervention.executions.length ? '-' :
                        getYearsRangeExecutions(intervention.executions)
                    }
                  </TableCell>
                </TableRow>;
              })}
          </TableBody>}
      </Table>
    </TableContainer>
    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems: 'center'}}>
      {
        selectedAnnualPlan && <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', mt: 1, mb: 2}}>
          <Box sx={{width: 36, height: 24, bgcolor: theme => lighten(theme.palette.primary.main, 0.85), border: theme => `1px solid ${theme.palette.primary.main}`, borderRadius: 1}}/>
          <Typography variant='body2'>Intervenciones incluidas en el Plan Annual {selectedAnnualPlan.year}</Typography>
        </Box>
      }
      {
        hasEditPermissions(role) && <AddButton isOffline={isOffline} onAddButtonClick={onInterventionAdd}/>
      }
    </Box>
  </>;
};

MenuInterventionsList.propTypes = {
  interventions: PropTypes.arrayOf(intervention),
  isOffline: PropTypes.bool,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)),
  selectedAnnualPlan: annualPlan,
  onInterventionSelect: PropTypes.func,
  onInterventionAdd: PropTypes.func,
};

export default MenuInterventionsList;
