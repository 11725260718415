import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import grey from '@mui/material/colors/grey';

import {STATUS_COLORS} from '../../config';
import StatusChip from '../StatusChip';

const CommunityCard = ({
  id,
  status,
  get_status_display,
  owner,
  get_certification_type_display,
  image,
  province,
  onClick,
  onHover
}) => {
  const handleClick = () => onClick();

  //STYLES
  const card = {
    width: '200px',
    height: '135px',
    background: `linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), ${grey[600]} url(${image}) no-repeat center`,
    backgroundSize: '200px auto',
    marginRight: 0.5,
    cursor: 'pointer',
    '&:hover': {
      outline: '3px solid yellow'
    }
  };
  const content = {
    width: '100%',
    height: '100%',
    p: 0.5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:last-child': {
      p: 0.5
    }
  };
  const certification = {
    color: 'white',
    m: 0,
    fontWeight: 'bolder',
  };

  const communityName = {
    color: 'white',
    fontWeight: '700',
    textTransform: 'uppercase',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
  };
  const provinceName = {
    color: 'white',
  };

  return (
    <Card elevation={3} sx={card} key={id}
      onClick={handleClick}
      onMouseEnter={() => onHover(id)}
      onMouseLeave={() => onHover()}
    >
      <CardContent sx={content}>
        <Box>
          {
            (status === 'completed') &&
              <Typography variant='body1' sx={certification}>{get_certification_type_display}</Typography>
          }
          <StatusChip label={get_status_display} status={status}/>
        </Box>
        <Box>
          <Typography variant='h2' sx={communityName}>{owner}</Typography>
          <Typography variant='body1' sx={provinceName}>{province}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

CommunityCard.propTypes = {
  id: PropTypes.number.isRequired,
  status: PropTypes.oneOf(Object.keys(STATUS_COLORS)).isRequired,
  get_status_display: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  get_certification_type_display: PropTypes.string,
  image: PropTypes.string.isRequired,
  province: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onHover: PropTypes.func.isRequired,
};

export default CommunityCard;
