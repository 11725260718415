export const getCredentials = () => {
  return {
    username: localStorage.getItem('bikenta.username'),
    token: localStorage.getItem('bikenta.token'),
  };
};

export const setCredentials = (username, token) => {
  localStorage.setItem('bikenta.username', username);
  localStorage.setItem('bikenta.token', token);
};

export const removeCredentials = () => {
  localStorage.removeItem('bikenta.username');
  localStorage.removeItem('bikenta.token');
};

export const getActiveFilters = () => JSON.parse(localStorage.getItem('bikenta.activeFilters'));

export const setActiveFilters = (activeFilters) => {
  localStorage.setItem('bikenta.activeFilters', JSON.stringify(activeFilters));
};