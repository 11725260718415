import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import DialogPanel from '../DialogPanel';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import PersonIcon from '@mui/icons-material/Person';

import StepSlider from './StepSlider';
import {MAP_DETAIL_STYLES, MAP_DETAILS, MAP_STYLES, PNOA_YEARS} from '../../config';
import {PNOA_MAX, PNOA_MIN} from '../../utils/pnoa';

import BaseMapList from '@geomatico/geocomponents/BaseMapList';
import ColorSwitch from '@geomatico/geocomponents/ColorSwitch';
import LayerSwitcher from '@geomatico/geocomponents/LayerSwitcher';
import {labelStyle} from '../Cards/InterventionZoneCard';

const title = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  mt: 1,
  mb: 1,
  ml: 1,
};

const NavigationMenu = ({
  isNavigationMenuOpen,
  isCommunitySelected,
  selectedStyleId,
  selectedDetailMap,
  onClose,
  onStyleChange,
  onValueChange,
  pnoaYear,
  profile,
  onDetailMapChange,
  handleGeolocateUser,
  isGeolocatingUser
}) => {
  const handleMainMenuClose = () => onClose && onClose();
  const handleStyleMapChange = (basemap) => onStyleChange && onStyleChange(basemap);
  const handlePNOAYearChange = (year) => onValueChange(year);
  const handleDetailMapChange = (detailMap) => onDetailMapChange && onDetailMapChange(detailMap);

  const detailMaps = useMemo(() => {
    return isCommunitySelected ?
      MAP_DETAIL_STYLES.filter(style => style.id !== MAP_DETAILS.annualPlan) :
      MAP_DETAIL_STYLES.filter(style => style.id !== MAP_DETAILS.annualPlan && style.id !== MAP_DETAILS.especies );
  }, [isCommunitySelected]);

  return (
    <DialogPanel
      isOpen={isNavigationMenuOpen}
      onClose={handleMainMenuClose}
      label='OPCIONES DE NAVEGACIÓN'
      fullWidth
    >
      <Box sx={{display: 'flex', flexDirection: 'column', mt: 1, mb: 2, ml: 1}}>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <PersonIcon fontSize="medium" color='primary' sx={{mr: 1, pb: 0.25}}/>
          <Typography color='primary' sx={{fontWeight: 900}}>{profile.roles[0]}</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1}}>
          <Typography sx={{mr: 2}}>{profile.username.toUpperCase()}</Typography>
          <Typography sx={{color: 'grey.600'}}>{profile.email}</Typography>
        </Box>
      </Box>
      <Divider/>
      <Box sx={title}>
        <Typography sx={labelStyle}>Activar GPS</Typography>
        <ColorSwitch color={'#86B54D'} checked={isGeolocatingUser} onChange={handleGeolocateUser}/>
      </Box>
      <Divider/>
      <Box sx={title}>
        <Typography sx={labelStyle}>Tipo de mapa</Typography>
      </Box>
      <BaseMapList
        thumbnailWidth={50}
        thumbnailHeight={50}
        styles={MAP_STYLES}
        selectedStyleId={selectedStyleId?.name === 'PNOA' ? 'pnoa-historico': selectedStyleId}
        onStyleChange={handleStyleMapChange}
      />
      {
        selectedStyleId?.name === 'PNOA' ?
          <>
            <Box sx={{my: 0, mx: 2}}>
              <Typography component='div' variant='body1' noWrap>Elige un año</Typography>
            </Box>
            <Box sx={{my: 0, mx: 2}}>
              <StepSlider
                min={PNOA_MIN}
                max={PNOA_MAX}
                defaultValue={PNOA_MAX}
                marks={PNOA_YEARS}
                value={pnoaYear}
                onValueChange={handlePNOAYearChange}
              />
            </Box>
          </>
          : undefined
      }
      <Divider/>
      <Box sx={title}>
        <Typography sx={labelStyle}>Mapa de detalle</Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <LayerSwitcher
          thumbnailWidth={50}
          thumbnailHeight={50}
          exclusive={false}
          selectedLayerIds={selectedDetailMap}
          layers={detailMaps}
          onLayerChange={handleDetailMapChange}
          allowEmptyValue={true}
        />
      </Box>
      <Divider/>
      <Chip label={
        <>
          <Typography variant='caption' sx={{fontWeight: 'bolder'}}>Version: </Typography>
          {/* eslint-disable-next-line no-undef */}
          <Typography variant='caption' sx={{mr: 1}}>{VERSION}</Typography>
          <Typography variant='caption' sx={{fontWeight: 'bolder'}}>Hash: </Typography>
          {/* eslint-disable-next-line no-undef */}
          <Typography variant='caption'>{HASH}</Typography>
        </>
      } size='small' sx={{mt: 1}}/>
    </DialogPanel>
  );
};

NavigationMenu.propTypes = {
  isNavigationMenuOpen: PropTypes.bool,
  isCommunitySelected: PropTypes.bool,
  selectedDetailMap: PropTypes.array,
  selectedStyleId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func.isRequired,
  onStyleChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  pnoaYear: PropTypes.number.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
    groups: PropTypes.array,
    roles: PropTypes.array
  }),
  onDetailMapChange: PropTypes.func.isRequired,
  handleGeolocateUser: PropTypes.func.isRequired,
  isGeolocatingUser: PropTypes.bool.isRequired,
};

NavigationMenu.defaultProps = {
  isMainMenuOpen: false
};

export default NavigationMenu;