import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Fab from '@mui/material/Fab';

//MUI-ICONS
import AddIcon from '@mui/icons-material/Add';

//STYLES
const fab = {
  '&.MuiButtonBase-root': {
    borderRadius: '100%',
    backgroundColor: 'secondary.main',
    mr: 1,
    ml: 'auto',
    '&:hover': {
      backgroundColor: 'primary.main',
    }
  },
  '&.Mui-disabled': {
    backgroundColor: '#d7d7d7'
  }
};

const AddButton = ({isOffline, disabled=false, onAddButtonClick}) => {

  return <Fab id="add-intervention-button" sx={fab} size='small' onClick={onAddButtonClick} disabled={isOffline || disabled}>
    <AddIcon sx={{color: 'white'}}/>
  </Fab>;
};

AddButton.propTypes = {
  isOffline: PropTypes.bool,
  disabled: PropTypes.bool,
  onAddButtonClick: PropTypes.func
};

export default AddButton;