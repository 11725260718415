import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

const InvestIncome = ({executed_invest, executed_income, isOffline, onInvestChange, onIncomeChange, disabled}) => {

  const subTitleColor = (isOffline || disabled) ? 'grey.500' : 'secondary';

  const handleInvestUpdate = (e) => onInvestChange({executed_invest: e.target.value});
  const handleIncomeUpdate = (e) => onIncomeChange({executed_income: e.target.value});

  return <Box display='flex' flexDirection='column'>
    <Typography variant='overline' component='p' color={subTitleColor}
      sx={{fontWeight: 'bolder', mb: 0.5, ml: 0.5}}>COSTES</Typography>
    <Box display='flex' alignItems='flex-end' justifyContent='space-between'>
      <TextField
        sx={{ml: 2, mb: 2, flexGrow: 1}}
        label='Inversión'
        type='number'
        InputLabelProps={{shrink: true}}
        variant='outlined'
        value={executed_invest}
        onChange={handleInvestUpdate}
        disabled={isOffline || disabled}
      />
      <TextField
        sx={{ml: 2, mb: 2, flexGrow: 1}}
        label='Beneficio'
        type='number'
        InputLabelProps={{shrink: true}}
        variant='outlined'
        value={executed_income}
        onChange={handleIncomeUpdate}
        disabled={isOffline || disabled}
      />
    </Box>
  </Box>;
};

InvestIncome.propTypes = {
  executed_invest: PropTypes.string.isRequired,
  executed_income: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool,
  onInvestChange: PropTypes.func,
  onIncomeChange: PropTypes.func,
};

export default InvestIncome;