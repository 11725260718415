import React from 'react';
import PropTypes from 'prop-types';

//MUI-ICONS
import WifiOffIcon from '@mui/icons-material/WifiOff';

const Mask = ({width, height, opacity}) => {
  return <>
    <WifiOffIcon
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: `${width}px`,
        height: `${height}px`,
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
        pointerEvents: 'none',
        opacity: opacity,
        color: 'white'
      }}/>
  </>;
};

Mask.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  opacity: PropTypes.number,
};

export default Mask;