import React, {useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';

//MUI-ICONS
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';

//GEOCOMPONENTS
import SearchBox from '@geomatico/geocomponents/SearchBox';

const TopControls = ({
  children,
  isCommunitySelected,
  isInterventionZoneSelected,
  searchedText,
  placeholder,
  onLogout,
  onNavigationMenuClicked,
  onGoBack,
  onSearchClick,
}) => {

  //STYLES
  const mainContainerStyle = {
    zIndex: 50,
    position: 'absolute',
    top: isInterventionZoneSelected ? 21 : 10,
    left: isInterventionZoneSelected ? 18 : 10,
    right: isInterventionZoneSelected ? 18 : 0,
    color: 'white',
    display: isInterventionZoneSelected && 'flex',
    justifyContent: isInterventionZoneSelected && 'space-between',
    alignItems: 'center',
  };

  const handleGoBack = () => onGoBack();

  const [searchText, setSearchText] = useState(searchedText);

  const handleSearchClick = () => {
    if (!isCommunitySelected) onSearchClick(searchText);
  };

  const handleOnTextChange = (ev) => {
    if (!isCommunitySelected) setSearchText(ev);
  };


  return <>
    {
      isInterventionZoneSelected ?
        <Box sx={mainContainerStyle}>
          <MenuIcon onClick={onNavigationMenuClicked}/>
          <Box>
            <KeyboardBackspaceIcon  onClick={handleGoBack} sx={{mr: 1}}/>
            <LogoutIcon sx={{cursor: 'pointer'}} onClick={onLogout}/>
          </Box>
        </Box>
        : <Box sx={mainContainerStyle}>
          <Box sx={{m: 0, p: 0, display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center'}}>
            <SearchBox
              text={searchText}
              placeholder={placeholder || ''}
              AdvanceSearchIcon={KeyboardBackspaceIcon}
              onMenuClick={onNavigationMenuClicked}
              onSearchClick={handleSearchClick}
              onTextChange={!isCommunitySelected ? handleOnTextChange : ()=>{}}
              onAdvanceSearchClick={onGoBack ? handleGoBack : null}
              dense
            />
            <LogoutIcon sx={{cursor: 'pointer', width: '10%', ml: 1}} onClick={onLogout}/>
          </Box>
          {children}
        </Box>
    }
  </>;
  // }, [placeholder, searchedText, children, isInterventionZoneSelected]);
};

TopControls.propTypes = {
  children: PropTypes.node,
  isCommunitySelected: PropTypes.bool,
  isInterventionZoneSelected: PropTypes.bool,
  placeholder: PropTypes.string,
  searchedText: PropTypes.string,
  onLogout: PropTypes.func,
  onNavigationMenuClicked: PropTypes.func,
  onGoBack: PropTypes.func,
  onSearchClick: PropTypes.func,
};

export default TopControls;