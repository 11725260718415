import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const IconWrapperBox = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 4px;
  margin-bottom: 4px;
`;

const IconWrapper = ({children}) => {
  return (
    <IconWrapperBox>
      {children}
    </IconWrapperBox>
  );
};

IconWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default IconWrapper;