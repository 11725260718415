import React from 'react';
import PropTypes from 'prop-types';

//MUI
import {useTheme} from '@mui/styles';
import {useMediaQuery} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

//BIKENTA
import CommonersList from '../components/CommonersList/CommonersList';

//UTILS
import {commoners} from '../components/proptypes/commoners';
import {MINI_SIDE_PANEL_WIDTH, ROLES_LABEL} from '../config';

const CommonersListView = ({
  board_members, 
  commoners, 
  role, 
  selectedCommunity, 
  onCommonerAdd, 
  onCommonerActivate,
  onCommonerDeactivate,
  onCommonerDelete,
  onCommonerInvite, 
  onCommonersListClose, 
  onCommonerUpdate
}) => {
  const theme = useTheme();
  const widescreen = useMediaQuery(theme.breakpoints.up('lg'), {noSsr: true});
  return <Dialog
    elevation={0}
    fullScreen
    TransitionComponent={Slide}
    sx={{
      top: widescreen ? 62 : 0,
      left: widescreen ? MINI_SIDE_PANEL_WIDTH-2 : 0,
      right: widescreen ? -4 : 0,
      zIndex: 200
    }}
    open={true}>
    <CommonersList
      board_members={board_members}
      commoners={commoners}
      role={role}
      selectedCommunity={selectedCommunity}
      onCommonerAdd={onCommonerAdd}
      onCommonerActivate={onCommonerActivate}
      onCommonerDeactivate={onCommonerDeactivate}
      onCommonerDelete={onCommonerDelete}
      onCommonerInvite={onCommonerInvite}
      onCommonersListClose={onCommonersListClose}
      onCommonerUpdate={onCommonerUpdate}
    />
  </Dialog>;
};

CommonersListView.propTypes = {
  board_members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    label: PropTypes.string,
  })),
  commoners: commoners,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)),
  selectedCommunity: PropTypes.object,
  onCommonerAdd: PropTypes.func,
  onCommonerActivate: PropTypes.func,
  onCommonerDeactivate: PropTypes.func,
  onCommonerDelete: PropTypes.func,
  onCommonerInvite: PropTypes.func,
  onCommonersListClose: PropTypes.func,
  onCommonerUpdate: PropTypes.func
};

export default CommonersListView;