import React from 'react';
import PropTypes from 'prop-types';

//BIKENTA
import AlertDeleteDialog from './AlertDeleteDialog';

const DeleteFiltersDialog = ({description, onAccept}) => {
  return <AlertDeleteDialog
    title='No hay comunidades disponibles'
    description={description}
    isOpen={!!description}
    onAccept={onAccept}
  />;
};

DeleteFiltersDialog.propTypes = {
  description: PropTypes.string,
  onAccept: PropTypes.func,
};

export default DeleteFiltersDialog;