import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

//BIKENTA
import CommentsInput from './CommentsInput';
import DialogPanel from '../../../DialogPanel';
import EditExecutionGeometryMenu from '../../EditGeometryMenu/EditExecutionGeometryMenu';
import InvestIncome from './InvestIncome';
import StartEndYear from './StartEndYear';
import ToggleExecutionType from './ToggleExecutionType';
import WhoMadeItInput from './WhoMadeItInput';

//UTILS
import {execution} from '../../../proptypes/execution';
import {basemap} from '../../../proptypes/basemap';
import {viewport} from '../../../proptypes/viewport';
import {EXECUTION_TYPES} from '../../../../config';
  
const DetailsExecutionPanel = ({
  basemap,
  execution,
  title,
  interventionZone,
  interventionZoneDetail,
  hasPartialExecution,
  isEditing,
  isOffline,
  multiselect,
  viewport,
  onClose,
  onCreateExecution,
  onUpdateExecution,
  onInputInterventionZoneGeometryStarts,
  onInputInterventionZoneGeometryEnds,
}) => {

  const currentYear = new Date().getFullYear();
  const initialExecution = useMemo(() => (
    execution || {
      geom: null,
      who_made_it: '',
      executed_end_year: currentYear.toString(),
      executed_start_year: currentYear.toString(),
      executed_invest: '0',
      executed_income: '0',
      comments: '',
    }), []);

  const [editingExecution, setEditingExecution] = useState(initialExecution);

  const validYears = () => editingExecution.executed_end_year >= editingExecution.executed_start_year;

  const handleExecutionMenuClose = () => () => onClose && onClose();
    
  const handleProperty = (property) => {
    setEditingExecution(
      {
        ...editingExecution,
        ...property
      });
  };

  const handleCancel = () => {
    onClose();
    setEditingExecution(initialExecution);
  };
  
  const handleUpdateExecution = () => {
    onClose();
    onUpdateExecution(editingExecution);
  };
  
  const handleCreateExecution = () => {
    onClose();
    onCreateExecution(editingExecution);
  };

  //EDITING GEOMETRY
  const [isExecutionEditingGeometry, setIsExecutionEditingGeometry] = useState(false);

  const handleExecutionType = (type) => {
    if(type === EXECUTION_TYPES.PARTIAL.id) {
      onInputInterventionZoneGeometryStarts(interventionZone && interventionZone.id);
      setIsExecutionEditingGeometry(true);
    } else {
      setEditingExecution({
        ...editingExecution,
        geom: null
      }
      );
    }
  };
  
  const handleGeometryCancel = () => {
    setIsExecutionEditingGeometry(false);
    onInputInterventionZoneGeometryEnds();
  };

  const handleGeometryAccept = (feature) => {
    handleProperty({geom: feature.geometry});
    setIsExecutionEditingGeometry(false);
    onInputInterventionZoneGeometryEnds();
  };

  return <>
    <DialogPanel
      isOpen={true}
      onClose={handleExecutionMenuClose}
      label={title}
      fullWidth
    >
      <Box display='flex' flexDirection='column' mx={1} mb={1} px={1}>
        {
          !execution && <ToggleExecutionType isOffline={isOffline} execution={editingExecution} multiselect={multiselect} hasPartialExecution={hasPartialExecution} onExecutionTypeChange={handleExecutionType}/>
        }
        <WhoMadeItInput isOffline={isOffline} who_made_it={editingExecution.who_made_it} disabled={!isEditing}
          onWhoMadeItChange={(who_made_it) => handleProperty(who_made_it)}/>
        <StartEndYear isOffline={isOffline} executed_start_year={editingExecution.executed_start_year} executed_end_year={editingExecution.executed_end_year} disabled={!isEditing}
          onStartYearChange={(executed_start_year) => handleProperty(executed_start_year)} onEndYearChange={(executed_end_year) => handleProperty(executed_end_year)}/>
        <InvestIncome isOffline={isOffline} executed_invest={editingExecution.executed_invest} executed_income={editingExecution.executed_income} disabled={!isEditing}
          onIncomeChange={(executed_income) => handleProperty(executed_income)} onInvestChange={(executed_invest) => handleProperty(executed_invest)}/>
        <CommentsInput isOffline={isOffline} comments={editingExecution.comments} disabled={!isEditing}
          onCommentsChange={(comments) => handleProperty(comments)}/>
      </Box>
    
      <Box display='flex' flexDirection='row' justifyContent='flex-end' mt={4} mb={1} px={2} sx={{gap: 2}}>
        <Button variant='outlined' sx={{color: 'grey.400'}} onClick={handleCancel}
          disabled={isOffline}>CANCELAR</Button>
        <Button variant={(isOffline || !validYears()) ? 'outlined' : 'contained'} color='secondary' onClick={execution ? handleUpdateExecution : handleCreateExecution}
          disabled={isOffline || !validYears()}>GUARDAR</Button>
      </Box>
    </DialogPanel>
    {/*EDITOR DE GEOMETRÍA*/}
    {
      isExecutionEditingGeometry && interventionZoneDetail &&
      <EditExecutionGeometryMenu
        initialViewport={viewport}
        basemap={basemap}
        baseGeometry={interventionZoneDetail.geom}
        onAcceptFeature={handleGeometryAccept}
        onCancel={handleGeometryCancel}
        isOffline={isOffline}
      />
    }
  </>;
};

DetailsExecutionPanel.propTypes = {
  basemap: basemap,
  execution: execution,
  title: PropTypes.string.isRequired,
  hasPartialExecution: PropTypes.bool,
  interventionZone: PropTypes.object,
  interventionZoneDetail: PropTypes.object,
  isEditing: PropTypes.bool,
  isOffline: PropTypes.bool.isRequired,
  idPartialGeometryExecutionViewer: PropTypes.bool,
  multiselect: PropTypes.bool.isRequired,
  viewport: viewport,
  onClose: PropTypes.func.isRequired,
  onCreateExecution: PropTypes.func,
  onUpdateExecution: PropTypes.func,
  onInputInterventionZoneGeometryEnds: PropTypes.func,
  onInputInterventionZoneGeometryStarts: PropTypes.func,
  onPartialGeometryExecutionViewer: PropTypes.func
};

export default DetailsExecutionPanel;