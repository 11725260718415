import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogPanel from '../DialogPanel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

//UTILS
import {FILE_TYPES, ROLES_LABEL} from '../../config';
import {roles} from '../proptypes/roles';

const AcknowledgmentMenu = ({
  fileType,
  role,
  rolesList,
  isAcknowledgmentMenuOpen,
  onCancel,
  onUploadFile
}) => {

  const initialValues = {
    read_receipt: 'no_confirmation',
    permission: 'restricted',
    roles: role === ROLES_LABEL.ADMIN ?
      [] : [rolesList.find(rol => rol.name === role).id]
  };

  const availableRoleList = rolesList.filter(role => role.id !== 6);

  const [activeStep, setActiveStep] = useState(0);

  const [values, setValues] = useState(initialValues);

  const handleCancel = () => {
    onCancel();
    setValues(initialValues);
    setActiveStep(0);
  };
  const handleBack = () => {
    activeStep === 1 ?
      setValues(
        {
          ...values,
          read_receipt: 'no_confirmation'
        }
      )
      : undefined;
    setActiveStep(activeStep - 1);
  };
  const handleNext = () => setActiveStep(activeStep + 1);

  const handleAcknowledgmentChange = (e) => setValues({
    ...values,
    read_receipt: JSON.parse(e.target.value) ? 'with_confirmation' : 'no_confirmation'
  });

  const handleSendRoleChange = (roleId) => setValues({
    ...values,
    roles: values.roles.includes(roleId)
      ? values.roles.filter(role => role !== roleId)
      : values.roles.concat(roleId)
  });

  const recipientsStep = [{
    id: 1,
    sublabel: 'Destinatarios',
    sublabelWithSelectedOption: 'Destinatarios',
    label: '¿Para quién quieres que esté disponible la documentación?',
    content: <><FormGroup row>
      {
        availableRoleList && availableRoleList.map(role => <FormControlLabel
          key={role.id}
          control={<Checkbox checked={values.roles.includes(role.id)} onChange={() => handleSendRoleChange(role.id)}/>}
          label={role.localized_name}
        />)
      }
    </FormGroup>
    {
      values.roles.length === 0
        ? <Typography variant='body2' color='error'>Es necesario elegir al menos un grupo.</Typography>
        : undefined
    }
    </>
  }];
  
  const confirmationStep = [{
    id: 2,
    sublabel: 'Acuse de recibo',
    sublabelWithSelectedOption: `Acuse de recibo: ${values.read_receipt ? 'SÍ' : 'NO'}`,
    label: '¿Quieres subir la documentación con acuse de recibo?',
    content: <FormControl>
      <RadioGroup defaultValue={initialValues.read_receipt === 'with_confirmation'} row onChange={handleAcknowledgmentChange}>
        <FormControlLabel value={true} control={<Radio />} label='Sí' />
        <FormControlLabel value={false} control={<Radio />} label='No' />
      </RadioGroup>
    </FormControl>
  }];

  const steps = fileType === FILE_TYPES.DOCUMENT ? recipientsStep.concat(confirmationStep) : recipientsStep;

  const buttonLabel = useMemo(() => {
    return fileType === FILE_TYPES.DOCUMENT ? activeStep === 1 ? 'SUBIR': 'SIGUIENTE' : 'SUBIR';
  }, [activeStep, values, fileType]);

  return <DialogPanel
    isOpen={isAcknowledgmentMenuOpen}
    onClose={handleCancel}
    label="SUBIR DOCUMENTACIÓN"
    fullWidth
  >
    <Stepper activeStep={activeStep} orientation="vertical" sx={{ml: 2}}>
      {
        steps.map(({label, sublabel, sublabelWithSelectedOption, content, id}) => (
          <Step key={id}>
            <StepLabel>{activeStep + 1 > id ? sublabelWithSelectedOption : sublabel}</StepLabel>
            <StepContent>
              <Typography>{label}</Typography>
              {content}
              <Box mt={1}>
                <Button disabled={activeStep === 0} onClick={handleBack}>Atrás</Button>
                <Button
                  variant='contained'
                  color='secondary'
                  component={fileType === FILE_TYPES.DOCUMENT ? activeStep === 1 ? 'label': undefined : 'label'}
                  onClick={fileType === FILE_TYPES.DOCUMENT ? activeStep === 1 ? undefined : handleNext : undefined}
                  disabled={values.roles.length === 0}
                >
                  <Typography variant='button'>{buttonLabel}</Typography>
                  <input
                    type='file'
                    hidden
                    accept={fileType === FILE_TYPES.DOCUMENT ? '.doc,.pdf' : '.jpg,.jpeg,.png'}
                    onChange={(e) => {
                      handleCancel();
                      onUploadFile({
                        ...values,
                        file: e.target.files[0]});
                    }}
                  />
                </Button>
              </Box>
            </StepContent>
          </Step>
        ))
      }
    </Stepper>
  </DialogPanel>;
};

AcknowledgmentMenu.propTypes = {
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)).isRequired,
  rolesList: PropTypes.arrayOf(roles),
  fileType: PropTypes.oneOf(Object.values(FILE_TYPES)).isRequired,
  isAcknowledgmentMenuOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onUploadFile: PropTypes.func,
};

export default AcknowledgmentMenu;