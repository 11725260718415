import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

const WhoMadeItInput = ({who_made_it, isOffline, onWhoMadeItChange, disabled}) => {

  const [whoMadeIt, setWhoMadeIt] = useState(who_made_it);
  const subTitleColor = (isOffline || disabled) ? 'grey.500' : 'secondary';

  const handleWhoMadeItUpdate = () => onWhoMadeItChange({who_made_it: whoMadeIt});

  return <Box display='flex' flexDirection='column'>
    <Typography variant='overline' component='p' color={subTitleColor}
      sx={{fontWeight: 'bolder', mb: 0.5, ml: 0.5}}>EMPRESA EJECUTORA</Typography>
    <Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='center' ml={2}>
      <TextField
        inputProps={{onBlur: handleWhoMadeItUpdate}}
        sx={{flexGrow: 2, mb: 2}}
        type='text'
        InputLabelProps={{shrink: true}}
        variant='outlined'
        value={whoMadeIt}
        onChange={(e) => setWhoMadeIt(e.target.value)}
        disabled={isOffline || disabled}
      />
    </Box>
  </Box>;
};

WhoMadeItInput.propTypes = {
  who_made_it: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  onWhoMadeItChange: PropTypes.func,
};

export default WhoMadeItInput;