import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import CategoryList from './CategoryList';

const AdvancedFilters = ({filters, selectedFilters, onClose, onSaveFilter}) => {
  const [newSelectedFilters, setNewSelectedFilters] = useState(selectedFilters);
  const handleClose = () => onClose && onClose();
  const handleFilter = (filters) => setNewSelectedFilters(filters);
  const handleSaveFilter = () => onSaveFilter && onSaveFilter(newSelectedFilters);
  const header = {
    fontWeight: 900,
    margin: '2px, 2px, 3px, 4px'
  };
  const filtersStyle = {
    height: '90vh',
    overflow: 'auto',
  };
  const button = {
    backgroundColor: 'secondary.main',
    color: 'primary.contrastText'
  };

  return <>
    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{m:1}}>
      <Typography variant="body1" sx={header}>FILTRO AVANZADO</Typography>
      <IconButton onClick={handleClose} size="small" sx={{mr:0.5}}>
        <CloseIcon/>
      </IconButton>
    </Box>
    <Box sx={filtersStyle}>
      <CategoryList filters={filters} selectedFilters={newSelectedFilters} onSelectedFilter={(filters) => handleFilter(filters)}/>
    </Box>
    <Box display="flex" justifyContent="flex-end" alignItems="center" m={2}>
      <Button variant="contained" size="medium" sx={button} onClick={(filters) => handleSaveFilter(filters)}>APLICAR</Button>
    </Box>
  </>;
};

AdvancedFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      label: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.number,
          label: PropTypes.string
        })
      ),
    })
  ).isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.number),
  onClose: PropTypes.func.isRequired,
  onSaveFilter: PropTypes.func.isRequired,
};

export default AdvancedFilters;