import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

//BIKENTA
import DialogPanel from '../DialogPanel';

//UTILS
import {annualPlan} from '../proptypes/annualPlan';

const CommunityDownloadAlert = ({
  isCommunityDownloadDialogOpen,
  onClose,
}) => {
  const handleDownloadAlertClose = () => onClose && onClose();

  return (
    <DialogPanel
      isOpen={isCommunityDownloadDialogOpen}
      onClose={handleDownloadAlertClose}
      label='DESCARGAR COMUNIDAD'
      fullWidth
    >
      <Typography gutterBottom>Estamos generando los archivos de la Comunidad.</Typography>
      <Typography gutterBottom>Cuando acabe el proceso, podrás descargarlo haciendo click en la carpeta de Documentos del menú lateral izquierdo.</Typography>
      <Box mt={2}>
        <img
          src={'assets/documentation_panel.png'}
          alt='panel-documentación'
          loading="lazy"
          style={{opacity: 0.75}}
        />
      </Box>
      <Button onClick={handleDownloadAlertClose} color="secondary" variant='contained' sx={{mt: 2, float: 'right'}}>CERRAR</Button>
    </DialogPanel>
  );
};

CommunityDownloadAlert.propTypes = {
  selectedAnnualPlan: annualPlan,
  isCommunityDownloadDialogOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

export default CommunityDownloadAlert;