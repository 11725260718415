import React from 'react';
import PropTypes from 'prop-types';
import {intervention} from '../../proptypes/intervention';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';

import InterventionProperty from './InterventionProperty';
import {PRIORITIES, ROLES_LABEL} from '../../../config';
import {hasEditPermissions} from '../../../modules/useCases';

const planWrapper = {
  borderRadius: 0.5,
  mt: 1.25,
  backgroundColor: 'grey.200',
};
const accordion = {
  pt: 0,
  backgroundColor: 'grey.200'
};
const nonEditableTitle = {
  fontWeight: 'bold',
  color: 'grey.600',
  mr: 2
};
const titleStyle = {
  fontWeight: 'bold',
  color: 'grey.600',
};

const PlanningPanel = ({intervention, interventionZone, isOffline, role, onEditPlannedIntervention}) => {
  const handleEditPlannedIntervention = (intervention) => onEditPlannedIntervention && onEditPlannedIntervention(intervention);

  return (
    <Box sx={planWrapper}>
      <Accordion sx={accordion} elevation={0} disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} id='plan-panel'>
          <Typography
            color="secondary"
            sx={intervention.is_annual_planned ? nonEditableTitle : titleStyle}
          >
            PLANIFICACIÓN
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            {
              intervention.is_annual_planned && <InterventionProperty title='Superficie' value={`${interventionZone.area.toFixed(2)} Ha`}/>
            }
            {
              intervention.mid_value ?
                <InterventionProperty title='Valor medio' value={`${intervention.mid_value} ${intervention.uom}`}/> :
                <InterventionProperty title='Valor medio' value='-'/>
            }
            <InterventionProperty title='Año de inicio' value={intervention.planned_start_year}/>
            <InterventionProperty title='Año de finalización' value={intervention.planned_end_year}/>
            <InterventionProperty title='Inversión' value={`${intervention.planned_invest} €`}/>
            <InterventionProperty title='Beneficio' value={`${intervention.planned_income} €`}/>
            {
              intervention.priority && <InterventionProperty
                title='Prioridad' value={PRIORITIES.find(priority => priority.id === intervention.priority)?.label}/>
            }
            {
              (hasEditPermissions(role) && !intervention.is_annual_planned) ?
                <Box display='flex' flexDirection='row' justifyContent='flex-end' mt={1}>
                  <Button variant='text' color="secondary" onClick={() => handleEditPlannedIntervention(intervention)} disabled={isOffline}>EDITAR</Button>
                </Box> : undefined
            }
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

PlanningPanel.propTypes = {
  intervention: intervention,
  interventionZone: PropTypes.object,
  isOffline: PropTypes.bool,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)),
  onEditPlannedIntervention: PropTypes.func.isRequired,
};

export default PlanningPanel;
