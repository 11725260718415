import {useMediaQuery} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import {useTheme} from '@mui/styles';
import React from 'react';
import PropTypes from 'prop-types';
import {VegaLite} from 'react-vega';

//MUI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const PieChart = ({data, indicator, title, aproveitamento}) => {

  const theme = useTheme();
  const widescreen = useMediaQuery(theme.breakpoints.up('md'), {noSsr: true});

  //STYLES
  const cardHeaderSx = {
    padding: 1,
    //bgcolor: PRIMARY_LIGHT_GREEN,
    outline: theme => `1px solid ${theme.palette.primary.main}`,
    '& .MuiTypography-root': {
      maxWidth: 350,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      color: 'primary.main',
      fontWeight: 'bold',
      /*ml: 1,
      mb: 1*/
    }
  };

  const parseData =  indicator ? 
    data.species.map(spe => (
      {
        id: spe.id,
        label: spe.label,
        value: spe.existences[indicator]
      }
    )) 
    : data.species.map(spe => (
      {
        label: spe.label,
        value: spe.area
      }
    ));

  const spec = {
    width: 'container',
    data: {
      values: parseData
    },
    title: {
      text: null,
    },
    encoding: {
      theta: {
        field: 'value',
        type: 'quantitative',
        stack: true
      },
      color: {
        field: 'label',
        type: 'nominal',
      },
      tooltip: [
        {
          field: 'label',
          title: 'Especie',
          type: 'nominal',
        },
        {
          field: 'value',
          title: title,
          type: 'quantitative'
        }
      ]
    },
    layer: [
      {
        mark: {
          type: 'arc',
          outerRadius: 100,
          innerRadius: aproveitamento ? 50 : 0
        },
        encoding: {
          color: {
            scale: {
              scheme: aproveitamento ? 'inferno' : 'viridis',
            },
            field: 'label',
            type: 'nominal',
            legend: {
              title: false,
              rowPadding: 8,
              labelFontSize: 12,
              labelColor: 'grey',
              type: 'symbol',
              orient: widescreen ? 'bottom' : 'right',
              columns: widescreen ? aproveitamento ? 2 : 1 : 1
            }
          },
        },
      },
      
      //eslint-disable  
      // esto no se puede quitar, porque no acepta que le ponga comillas simples
      /*{transform: [{filter: "datum.label == 'Otras'"}],
        mark: 'arc',
        data: {values: dataToChart},
        encoding: {
          color: {
            value: 'lightgrey'
          },
        }
      },*/
      /*{ //ETIQUETAS DE VALOR
        data: {values: dataToChartTreeFeet},
        width: 'container',
        height: 50,
        mark: {
          type: 'text',
          radius: 75,
          fill: 'white',
          fontSize: 16,
          fontWeight: 'bold'
        },
        encoding: {
          text: {
            field: 'value',
            type: 'quantitative'
          }
        }
      },*/
    ],
  };

  return <Card sx={{width: {xs: '100%', sm: '45%', md: '22%'}, flexGrow: 1}}>
    <CardHeader
      avatar ={ <Avatar sx={{bgcolor: 'common.white'}}> <RadioButtonCheckedIcon sx={{color: 'primary.main'}}/> </Avatar>}
      sx={cardHeaderSx}
      title={title.toUpperCase()}
    />
    <CardContent sx={{m: 0, display: 'flex', flexDirection: 'column'}}>
      {
        data.species.length !== 0 && <VegaLite spec={spec} data={parseData} actions={false}/>
      }
    </CardContent>
  </Card>;
};

PieChart.propTypes = {
  title: PropTypes.string.isRequired,
  indicator: PropTypes.oneOf(['treeFeet', 'volumeWithBark', 'volumeWithoutBark']),
  aproveitamento: PropTypes.bool,
  data: PropTypes.shape({
    total: PropTypes.shape({
      area: PropTypes.number,
      volumeWithBark: PropTypes.number,
      volumeWithoutBark: PropTypes.number,
      treeFeet: PropTypes.number,
    }),
    species: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string || PropTypes.array,
      area: PropTypes.number,
      treeFeet: PropTypes.number,
      volumeWithBark: PropTypes.number,
      volumeWithoutBark: PropTypes.number,
    }))
  })
};

PieChart.defaultProps = {
  aproveitamento: false,
};


export default PieChart;