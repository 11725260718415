import React, {useMemo, useState, useEffect} from 'react';
import PropTypes from 'prop-types';

//MUI
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';

//BIKENTA
import NeverDoExecutionChangeAlert from './NeverDoChangeAlert';
import NeverDoExecutionAlert from './NeverDoExecutionAlert';

//UTILS
import IcBatch from '../../../icons/IcBatch';

import {ROLES_LABEL, EXECUTION_TYPES} from '../../../../config';
import {getSpeciesAvailableToExecuteUseCase, hasEditPermissions} from '../../../../modules/useCases';
import {intervention} from '../../../proptypes/intervention';
import {basemap} from '../../../proptypes/basemap';
import {viewport} from '../../../proptypes/viewport';
import round from '../../../../utils/round';
import AddButton from '../../../AddButton';
import {lighten} from '@mui/material/styles';
import DetailsExecutionPanel from './DetailsExecutionPanel';
import PartialGeometryViewer from '../../EditGeometryMenu/PartialGeometryViewer';


//STYLES
const editablePlanWrapper = {
  border: theme => `1px solid ${theme.palette.grey[400]}`,
  borderRadius: 0.5,
  mt: 1.25,
};
const accordionEditable = {
  backgroundColor: 'white',
};
const titleStyle = {
  fontWeight: 'bold'
};
const noExecutableButton = {
  borderRadius: 10,
  bgcolor: lighten('#de0606', 0.35),
  '&:hover': {
    bgcolor: 'error.main',
  },
  '&.Mui-disabled': {
    bgcolor: '#d7d7d7',
    color: 'common.white'
  }
};


const ExecutionPanel = ({
  basemap,
  intervention,
  interventionZone,
  interventionZoneDetail,
  isOffline,
  multiselect,
  role,
  viewport,
  onCreateExecution,
  onUpdateExecution,
  onDeleteExecution,
  onInputInterventionZoneGeometryStarts,
  onInputInterventionZoneGeometryEnds,
  onNeverDo
}) => {
  const currentYear = new Date().getFullYear();

  const initialIntervention = useMemo(() => ({
    id: intervention.id,
    is_annual_planned: intervention.is_annual_planned,
    done: intervention.done,
    who_made_it: intervention.who_made_it || '',
    executed_start_year: intervention.executed_start_year ? parseInt(intervention.executed_start_year) : currentYear,
    executed_end_year: intervention.executed_end_year ? parseInt(intervention.executed_end_year) : currentYear,
    executed_invest: intervention.executed_invest || 0,
    executed_income: intervention.executed_income || 0,
    comments: intervention.comments || '',
    never_do: intervention.never_do || false,
    executions: intervention.executions,
  }), [intervention]);

  //NEVER_DO EXECUTION CHANGE ALERT
  const [isOpenNeverDoExecutionChangeMenu, setOpenNeverDoExecutionChangeMenu] = useState(false);
  const handleNeverDoExecutionChangeAccept = () => setOpenNeverDoExecutionChangeMenu(false);
  
  //DELETE EXECUTION
  const handleExecutionDelete = (id) => onDeleteExecution(id);

  //EDITING INTERVENTION
  const [editingIntervention, setEditingIntervention] = useState(initialIntervention);

  //NEVER_DO EXECUTION ALERT
  const [isOpenNeverDoExecutionMenu, setNeverDoExecutionMenuOpen] = useState(false);
  const handleNeverDoExecutionCancel = () => setNeverDoExecutionMenuOpen(false);

  const handleNeverDoAccept = () => {
    onNeverDo(intervention.id);
    setNeverDoExecutionMenuOpen(false);
  };

  //SELECTED EXECUTION
  const [selectedExecutionId, setSelectedExecutionId] = useState(undefined);
  const selectedExecution = useMemo(() => intervention.executions.find(ex => ex.id === selectedExecutionId), [selectedExecutionId]);

  useEffect(() => {
    setEditingIntervention(initialIntervention);
  }, [initialIntervention]);

  //INTERVENTION
  const handleNeverDoExecution = () => setNeverDoExecutionMenuOpen(true);
  const handleExecutionClick = (id) => {
    setSelectedExecutionId(selectedExecutionId === id ? undefined : id);
    setEditExecutionMenuOpen(true);
  };
  const hasPartialExecution = intervention.executions.length && !intervention.done;

  //PARTIAL GEOMETRY
  const [idPartialGeometryExecutionViewer, setIdPartialGeometryExecutionViewer] = useState(undefined);
  const handlePartialGeometryViewerOpen = (executionId) => {
    setIdPartialGeometryExecutionViewer(executionId);
    onInputInterventionZoneGeometryStarts(interventionZone && interventionZone.id);
  };
  
  const handlePartialGeometryViewerClose = () => {
    onInputInterventionZoneGeometryEnds();
    /*onPartialGeometryExecutionViewer(undefined);*/
  };

  //ADD EXECUTION PANEL
  const [isCreateExecutionMenuOpen, setCreateExecutionMenuOpen] = useState(false);

  //EDIT EXECUTION PANEL
  const [isEditExecutionMenuOpen, setEditExecutionMenuOpen] = useState(false);

  const CUT_DOWN_CATEGORY = 1;
  const isCutDownIntervention = intervention.categories.includes(CUT_DOWN_CATEGORY);

  const executionPanelContent = <>
    {
      <Stack>
        {
          !multiselect && <Box sx={{mr: 0, mb: 1, display: 'flex', flexDirection: 'column'}}>
            {
              intervention.never_do ?
                <Typography sx={{ml: 1, mb: 1, color: 'error.main', fontWeight: 'bold'}}>No ejecutable.</Typography> :
                intervention.executions.length === 0 ?
                  <Typography sx={{ml: 1, mb: 1, color: 'error.main'}}>No hay ninguna ejecución todavía.</Typography> :
                  <Typography sx={{ml: 1, mb: 1, fontStyle: 'italic', color: 'grey.500'}}>(Haz click en una ejecución para <span>{hasEditPermissions(role) ? 'editar' : 'conocer'}</span> sus detalles.)</Typography>
            }
            <Table sx={{p: 0}} dense='true'>
              <TableBody>
                {
                  intervention.executions && intervention.executions.map(execution => {
                    const isExecutionCompleted = execution.area_percentage === 100;
                    return <TableRow sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      p: 0,
                      '&:hover': {bgcolor: 'ghostwhite'}
                    }}
                    key={execution.id}
                    >
                      <TableCell sx={{p: 0, border: 'none', width: 24}}>
                        {execution.batch ? <IcBatch fontSize='small' sx={{mr: 1, color: 'grey.600'}}/> : undefined}
                      </TableCell>
                      <TableCell sx={{p: 0, border: 'none'}}>
                        {isExecutionCompleted ? EXECUTION_TYPES.COMPLETE.icon : EXECUTION_TYPES.PARTIAL.icon}
                      </TableCell>
                      <TableCell sx={{p: 0, border: 'none', width: 150}} onClick={() => handleExecutionClick(execution.id)}>
                        <Typography variant='body2' sx={{pl: 1, pr: 2}}>
                          {isExecutionCompleted ? EXECUTION_TYPES.COMPLETE.label : EXECUTION_TYPES.PARTIAL.label}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{p: 0, border: 'none'}}>
                        <Typography variant='body2' sx={{pr: 3}}>
                          {execution.executed_start_year}-{execution.executed_end_year}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{p: 0, border: 'none'}}>
                        <Typography variant='body2' sx={{pr: 3, width: 80}}>
                          {round(execution.area)} Ha
                        </Typography>
                      </TableCell>
                      <TableCell sx={{p: 0, border: 'none', width: 75}}>
                        <Typography variant='body2' sx={{pr: 3}}>
                          {execution.area_percentage} %
                        </Typography>
                      </TableCell>
                      <TableCell sx={{p: 0, border: 'none'}}
                        onClick={() => handlePartialGeometryViewerOpen(execution.id)}
                      >
                        {
                          !isExecutionCompleted &&
                            <ImageSearchIcon color={isExecutionCompleted ? 'disabled' : 'secondary'}
                              sx={{mr: 1, cursor: isExecutionCompleted ? undefined : 'pointer'}}/>
                        }
                      </TableCell>
                      <TableCell sx={{p: 0, border: 'none', align: 'right'}}
                        onClick={() => handleExecutionDelete(execution.id)}>
                        <DeleteIcon color='secondary' sx={{cursor: isExecutionCompleted ? undefined : 'pointer'}}/>
                      </TableCell>
                    </TableRow>;
                  })
                }
              </TableBody>
            </Table>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', mt: intervention.executions.length > 0 ? 2 : 0}}>
              <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: intervention.executions.length > 0 ? 2 : 0}}>
                {
                  !(intervention.done || intervention.never_do) && <AddButton isOffline={isOffline} onAddButtonClick={() => setCreateExecutionMenuOpen(true)} 
                    disabled={isCutDownIntervention && getSpeciesAvailableToExecuteUseCase(interventionZone, intervention).length === 0}/>
                }
                {
                  !(intervention.done || intervention.never_do) && <Button variant='contained' size='medium' startIcon={<ClearIcon/>} sx={noExecutableButton}
                    onClick={handleNeverDoExecution}>
                    NO EJECUTABLE
                  </Button>
                }
              </Box>
              <>
                {
                  isCutDownIntervention && getSpeciesAvailableToExecuteUseCase(interventionZone, intervention).length === 0 && <Typography sx={{color: 'error.main', textAlign: 'end', mt: 1}}>No se puede ejecutar porque no hay especies plantadas.</Typography>
                }
              </>
              
            </Box>
          </Box>
        }
      </Stack>
    }
  </>;

  return <>
    <Box sx={editablePlanWrapper}>
      <Accordion sx={accordionEditable} elevation={0} disableGutters defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography color='secondary' sx={titleStyle}>LISTADO DE EJECUCIONES</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{pt: 0}}>
          {executionPanelContent}
        </AccordionDetails>
      </Accordion>
    </Box>
    <NeverDoExecutionAlert
      isOpenNeverDoExecutionMenu={isOpenNeverDoExecutionMenu}
      onCancelNeverDoExecution={handleNeverDoExecutionCancel}
      onClose={handleNeverDoExecutionCancel}
      onNeverDoAccept={handleNeverDoAccept}
    />
    <NeverDoExecutionChangeAlert
      isOpenNeverDoExecutionChangeMenu={isOpenNeverDoExecutionChangeMenu}
      onClose={handleNeverDoExecutionCancel}
      onNeverDoExecutionChangeAccept={handleNeverDoExecutionChangeAccept}
    />
    {
      isCreateExecutionMenuOpen && <DetailsExecutionPanel
        hasPartialExecution={hasPartialExecution}
        multiselect={multiselect}
        basemap={basemap}
        title='AÑADIR EJECUCIÓN'
        interventionZone={interventionZone}
        interventionZoneDetail={interventionZoneDetail}
        isEditing={true}
        isOffline={isOffline}
        idPartialGeometryExecutionViewer={idPartialGeometryExecutionViewer}
        viewport={viewport}
        onClose={() => setCreateExecutionMenuOpen(false)}
        onCreateExecution={onCreateExecution}
        onInputInterventionZoneGeometryEnds={onInputInterventionZoneGeometryEnds}
        onInputInterventionZoneGeometryStarts={onInputInterventionZoneGeometryStarts}
      />
    }
    {
      isEditExecutionMenuOpen && <DetailsExecutionPanel
        multiselect={multiselect}
        isEditing={hasEditPermissions(role)}
        execution={selectedExecution}
        title='EDITAR EJECUCIÓN'
        isOffline={isOffline}
        onUpdateExecution={onUpdateExecution}
        onClose={() => setEditExecutionMenuOpen(false)}
      />
    }
    {/*VISOR DE GEOMETRÍA*/}
    {
      idPartialGeometryExecutionViewer && interventionZoneDetail &&
      <PartialGeometryViewer
        baseGeometry={interventionZoneDetail.geom}
        basemap={basemap}
        initialViewport={viewport}
        finalGeometry={
          editingIntervention.executions.find(execution => execution.id === idPartialGeometryExecutionViewer)?.geom
        }
        onClose={handlePartialGeometryViewerClose}
      />
    }
  </>;
};

ExecutionPanel.propTypes = {
  basemap: basemap,
  intervention: intervention,
  interventionZone: PropTypes.object,
  interventionZoneDetail: PropTypes.object,
  isOffline: PropTypes.bool,
  multiselect: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)),
  viewport: viewport,
  onCreateExecution: PropTypes.func,
  onUpdateExecution: PropTypes.func,
  onActualExecutionType: PropTypes.func,
  onEditChange: PropTypes.func,
  onDeleteExecution: PropTypes.func,
  onInputInterventionZoneGeometryStarts: PropTypes.func,
  onInputInterventionZoneGeometryEnds: PropTypes.func,
  onNeverDo: PropTypes.func
};

ExecutionPanel.defaultProps = {
  basemap: {},
  intervention: PropTypes.shape({
    who_made_it: '',
    executed_start_year: new Date().getFullYear(),
    executed_end_year: new Date().getFullYear(),
    executed_invest: '',
    executed_income: '',
    images: [],
    documents: [],
    comments: '',
  }),
  multiselect: false
};

export default ExecutionPanel;