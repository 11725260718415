import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {grey} from '@mui/material/colors';

//STYLES
const buttonStyle = (isFilterSelected) => ({
  mr: 0.5,
  width: {
    xs: 'auto',
    lg: '100%',
  },
  borderRadius: {
    xs: '100px',
    lg: '4px',
  },
  height: 32,
  backgroundColor: isFilterSelected ? 'primary.main' : 'white',
  '&:hover': {
    backgroundColor: isFilterSelected ? 'primary.main' : 'white',
  },
  '& .MuiSvgIcon-root': {
    color: isFilterSelected ? 'white' : 'text.secondary',
  }
});

const MultiSelectFilter = ({title, values, onSelectionChange, selectedIds}) => {
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const handleChange = (e) => {
    onSelectionChange(e.target.value.filter(x => !selectedIds.includes(x)));
  };
  useEffect(() => {
    selectedIds.length !== 0 && values.some(({key}) => selectedIds.includes(key)) ?
      setIsFilterSelected(true) :
      setIsFilterSelected(false);
  }, [selectedIds]);

  const button = useMemo(() => buttonStyle(isFilterSelected), [isFilterSelected]);

  const MenuProps = {
    anchorOrigin: {vertical: 'bottom', horizontal: 'left'},
    transformOrigin: {vertical: 'top', horizontal: 'left'},
    getcontentanchorel: null,
    PaperProps: {
      style: {
        marginTop: 4,
        marginLeft: -10,
        borderBottom: theme => `4px solid ${theme.palette.secondary.main}`,
        borderRadius: 5,
      }
    },
    MenuListProps: {
      style: {
        padding: 0,
      }
    }
  };
  const inputBaseStyle = {
    color: isFilterSelected ? 'white' : 'text.secondary',
    width: {
      xs: 'auto',
      sm: '100%',
    },
    ml: 0,
    mt: 0,
    '& .MuiTypography-root': {
      fontWeight: isFilterSelected ? 'bolder' : undefined,
      textAlign: {
        xs: 'center',
        sm: 'left',
      },
    }
  };
  const menuItem = {
    color: 'text.secondary',
    borderBottom: `1px solid ${grey[400]}`,
    padding: '0px 0px 0px 4px',
    height: 35,
  };
  const checkbox = {
    padding: 0,
    color: grey[400],
  };
  const listItemText = {
    padding: '0px 16px 0px 8px',
  };

  return (
    <Button variant="contained" sx={button}>
      <Select
        multiple
        displayEmpty
        value={selectedIds}
        name={title}
        onChange={handleChange}
        input={<InputBase sx={inputBaseStyle}/>}
        MenuProps={MenuProps}
        renderValue={(selected) =>
          selectedIds.length !== 0 && values.find(value => value.key === selected[0]) ?
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre'}}>
              <Typography sx={{textTransform: 'capitalize'}}>{title.toUpperCase()}: {values.find(value => value.key === selected[0]).label}</Typography>
            </div>
            : <Typography sx={{textTransform: 'capitalize'}}>{title}</Typography>
        }
      >
        {values.map(({key, label}) => (
          <MenuItem key={key} value={key} sx={menuItem}>
            <Checkbox
              checked={selectedIds.includes(key)}
              icon={<CheckBoxOutlineBlankIcon/>}
              checkedIcon={<CheckIcon/>}
              sx={checkbox}
            />
            <ListItemText primary={label} sx={listItemText}/>

          </MenuItem>
        ))}
      </Select>
    </Button>
  );
};

MultiSelectFilter.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired
  ),
  selectedIds: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string
    ]
    )),
  onSelectionChange: PropTypes.func.isRequired,
};

MultiSelectFilter.defaultProps = {
  selectedIds: [],
};

export default MultiSelectFilter;