import {DONE_TYPES, FILTERS, PERMISSIONS, ROLES_LABEL, rolesWithEditPermissions} from '../config';

const roleExits = role => Object.values(ROLES_LABEL).includes(role);

const hasEditPermissions = (role) => {
  if (!roleExits(role)) {
    throw `Role ${role} does not exists!`;
  }
  return rolesWithEditPermissions.includes(role);
};

const hasViewPermissions = (permission, role) => {
  if (!roleExits(role)) {
    throw `Role ${role} does not exists!`;
  }

  return PERMISSIONS[role].includes(permission);
};

const getFiltersDescriptionsUseCase = (years, manifest, i18n, communitiesFiltersValues) => {
  const filters = manifest && communitiesFiltersValues ?
    Object.keys(communitiesFiltersValues)
      .filter(filter => filter === FILTERS.PRIORITIES || filter === FILTERS.SPECIES)
      .map(filter => ({
        key: filter,
        label: manifest.filters.find(filterManifest => filterManifest.key === filter).label[i18n],
        values: communitiesFiltersValues[filter]
          .map(value => {
            const specie = manifest.filters
              .find(filterManifest => filterManifest.key === filter).values
              .find(({key}) => key === value);
            if (!specie) console.error('Error: especie no existe en manifest. id:-->', value);
            return {
              key: value,
              label: specie.label[i18n]
            };
          })
      })
      )
    : [];

  if (communitiesFiltersValues) {
    if (years.length) {
      filters.push({
        key: FILTERS.YEARS,
        label: 'Años',
        values: years.map(value => ({key: value, label: value}))
      });
    }
    if (Object.prototype.hasOwnProperty.call(communitiesFiltersValues, FILTERS.DONE)) {
      filters.push({
        key: FILTERS.DONE,
        label: 'Ejecutada',
        values: [{
          label: 'Sí',
          key: DONE_TYPES.COMPLETE
        }, {
          label: 'Parcialmente',
          key: DONE_TYPES.PARTIAL
        }, {
          label: 'No',
          key: DONE_TYPES.NONE
        }].filter(({key}) => typeof communitiesFiltersValues[FILTERS.DONE] === 'object' ? communitiesFiltersValues[FILTERS.DONE].includes(key) : key === [communitiesFiltersValues[FILTERS.DONE]])
      });
    }
    if (Object.prototype.hasOwnProperty.call(communitiesFiltersValues, FILTERS.SXPUBLICA)) {
      filters.push({
        key: FILTERS.SXPUBLICA,
        label: 'Gestión Pública',
        values: [{
          label: 'Sí',
          key: true
        }, {
          label: 'No',
          key: false
        }].filter(({key}) => typeof communitiesFiltersValues[FILTERS.SXPUBLICA] === 'object' ? communitiesFiltersValues[FILTERS.SXPUBLICA].includes(key) : key === [communitiesFiltersValues[FILTERS.SXPUBLICA]])
      });
    }
  }
  return filters;
};

const getValidFilterValuesUseCase = (selectedCommunityId, communities, interventionZones, activeFilters) => {
  const filterTypes = Object.values(FILTERS);
  const filters =
    communities.length ?
      selectedCommunityId ?
        (interventionZones.length || activeFilters.length) &&
        filterTypes
          .reduce((filterValues, filterType) => {
            const getValueByName = (filterType) => {
              switch (filterType) {
              case FILTERS.SPECIES:
                return Array.from(
                  new Set(
                    []
                      .concat(
                        interventionZones
                          .flatMap(({species}) => species
                            .flatMap(({id}) => id)))
                      .concat(
                        activeFilters.filter(({key}) => key === FILTERS.SPECIES).length ?
                          activeFilters.find(({key}) => key === FILTERS.SPECIES).values :
                          []
                      )
                  )
                );
              case FILTERS.PRIORITIES:
                return Array.from(
                  new Set(
                    []
                      .concat(
                        interventionZones
                          .flatMap(({interventions}) => interventions
                            .filter(intervention => interventionMeetsFiltersUseCase(intervention, activeFilters))
                            .filter(({priority}) => !!priority)
                            .flatMap(({priority}) => priority))
                      )
                      .concat(
                        activeFilters.filter(({key}) => key === FILTERS.PRIORITIES).length ?
                          activeFilters.find(({key}) => key === FILTERS.PRIORITIES).values :
                          []
                      )
                  )
                );
              case FILTERS.CATEGORIES:
                return Array.from(
                  new Set(
                    []
                      .concat(
                        interventionZones
                          .flatMap(({interventions}) => interventions
                            .filter(intervention => interventionMeetsFiltersUseCase(intervention, activeFilters))
                            .flatMap(({categories}) => categories))
                      )
                      .concat(
                        activeFilters.filter(({key}) => key === FILTERS.CATEGORIES).length ?
                          activeFilters.find(({key}) => key === FILTERS.CATEGORIES).values :
                          []
                      )
                  )
                );
              case FILTERS.YEARS:
                return undefined;
              case FILTERS.SXPUBLICA:
                return Array.from(
                  new Set(
                    []
                      .concat(
                        interventionZones
                          .flatMap(({sxpublica}) => sxpublica)
                          .concat(
                            activeFilters.filter(({key}) => key === FILTERS.SXPUBLICA).length ?
                              activeFilters.find(({key}) => key === FILTERS.SXPUBLICA).values :
                              []
                          )
                      )
                  )
                );
              case FILTERS.DONE:
                return Array.from(
                  new Set(
                    []
                      .concat(
                        interventionZones
                          .flatMap(({interventions}) => interventions
                            .filter(intervention => interventionMeetsFiltersUseCase(intervention, activeFilters))
                            .flatMap(({executions}) =>
                              executions.some(execution => execution.area_percentage < 100) ? DONE_TYPES.PARTIAL :
                                executions.some(execution => execution.area_percentage === 100) ? DONE_TYPES.COMPLETE :
                                  DONE_TYPES.NONE
                            ))
                      )
                      .concat(
                        activeFilters.filter(({key}) => key === FILTERS.DONE).length ?
                          activeFilters.find(({key}) => key === FILTERS.DONE).values :
                          []
                      )
                  )
                );
              }
            };
            const value = getValueByName(filterType);
            if (value){
              filterValues[filterType] = value;
            }
            return filterValues;
          }, {})
        :
        filterTypes
          .reduce((filterValues, filterType) => ({
            ...filterValues,
            [filterType]: Array.from(
              new Set(
                communities
                  .flatMap(({interventions}) => interventions[filterType])
              )
            )
          }), {})
      : activeFilters ?
        filterTypes
          .reduce((filterValues, filterType) => ({
            ...filterValues,
            [filterType]: activeFilters.find(({key}) => key === filterType) ?
              activeFilters.find(({key}) => key === filterType).values : []
          }), {})
        : undefined;
  return filters ? {
    ...filters,
    done: typeof filters.done === 'boolean' ? [filters.done] : filters.done
  } : undefined;
};


/**
 * Comprueba si una zona de intervención coincide con los filtros aplicados
 * @param interventionZone Object state.api.communities
 * @param filters Array state.app.filters
 */
const interventionZoneMeetsFiltersUseCase = (interventionZone, filters) =>
  filters.every(({key, values}) =>
    values.every(value =>
      key === FILTERS.SPECIES ? interventionZone.species.some(specie => specie.id === value) :
        key === FILTERS.SXPUBLICA ? interventionZone.sxpublica === value :
          interventionZone.interventions.some(intervention =>
            interventionMeetsFiltersUseCase(intervention, filters)
          )
    )
  );

/**
 * Comprueba si una zona de intervención tiene alguna intervención incluida en el plan anual
 * @param interventionZone Object state.api.communities
 * @param annualPlanId Number state.app.selectedAnnualPlan.id
 */
const interventionZoneMeetsAnnualPlanUseCase = (interventionZone, annualPlanId) =>
  interventionZone => interventionZone.interventions
    .some(intervention => intervention.planned_assigned
      .some(anual_plan => anual_plan.id === annualPlanId)
    );


/**
 * Comprueba si una intervención coincide con los filtros aplicados
 * @param intervention Object state.api.communities
 * @param filters Array state.app.filters
 */
const interventionMeetsFiltersUseCase = (intervention, filters) =>
  filters
    .filter(({key}) => key !== FILTERS.SPECIES)
    .filter(({key}) => key !== FILTERS.SXPUBLICA)
    .every(({key, values}) =>
      values.every(value =>
        key === FILTERS.PRIORITIES ? intervention.priority === value :
          key === FILTERS.CATEGORIES ? intervention.categories.includes(value) :
            key === FILTERS.YEARS ? parseInt(intervention.planned_start_year) <= value && parseInt(intervention.planned_end_year) >= value :
              key === FILTERS.DONE ?
                value === DONE_TYPES.COMPLETE ? intervention.executions.some(execution => execution.area_percentage === 100) :
                  value === DONE_TYPES.PARTIAL ? intervention.executions.some(execution => execution.area_percentage < 100) :
                    intervention.executions.length === 0
                : false
      )
    );

/**
 * Comprueba si una intervención está incluida en un annual plan
 * @param intervention Object state.api.communities
 * @param annualPlanId Number state.app.selectedAnnualPlan.id
 */
const interventionMeetsAnnualPlanUseCase = (intervention, annualPlanId) =>
  intervention.planned_assigned
    .some(anual_plan => anual_plan.id === annualPlanId);

const getIconByCategory = (categories, categoryId) => {
  for (let i = 0; i < categories.length; i++) {
    // Busca el objeto que contenga la clave proporcionada
    const cat = categories[i];
    const values = cat.values;
    for (let j = 0; j < values.length; j++) {
      if (values[j].key === categoryId) {
        return cat.key;
      }
    }
  }
  return null;
};

const getSpeciesAvailableToExecuteUseCase = (interventionZone, intervention) => {
  const CUT_DOWN_CATEGORY = 1;
  const IS_CUT_DOWN_INTERVENTION = intervention.categories.includes(CUT_DOWN_CATEGORY);
  const SPECIES_PLANTED = interventionZone.species.filter(specie => specie.end_date === null);
  const ARE_SPECIES_PLANTED = SPECIES_PLANTED.length !== 0;

  return IS_CUT_DOWN_INTERVENTION ?
    ARE_SPECIES_PLANTED ? SPECIES_PLANTED : []
    : SPECIES_PLANTED;

};

export {
  hasEditPermissions,
  hasViewPermissions,
  getIconByCategory,
  getFiltersDescriptionsUseCase,
  getValidFilterValuesUseCase,
  interventionZoneMeetsFiltersUseCase,
  interventionMeetsFiltersUseCase,
  interventionZoneMeetsAnnualPlanUseCase,
  interventionMeetsAnnualPlanUseCase,
  getSpeciesAvailableToExecuteUseCase
};
