import PropTypes from 'prop-types';

export const categoryFilters = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.number,
    label: PropTypes.string,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string
      })
    ),
  })
).isRequired;