import React from 'react';
import PropTypes from 'prop-types';
import {VegaLite} from 'react-vega';

//MUI
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

//MUI-ICONS
import ParkIcon from '@mui/icons-material/Park';

const AreaAgePerSpecieIndicator = ({data}) => {
  const getMaxArea = data && data
    .flatMap(group => group.map(specie => specie.area))
    .reduce((max, current) => Math.max(max, current), 0);

  const getAgeGroupSortedLegend = (dat) => {
    const valoresUnicosAgeGroup = [...new Set(dat.map(item => item.ageGroup))];

    // Ordenamos los valores utilizando una función personalizada
    return valoresUnicosAgeGroup.sort((a, b) => {
      const numeroA = parseInt(a.split('-')[0]); // Extraemos el número antes del guión
      const numeroB = parseInt(b.split('-')[0]);
      return numeroA - numeroB; // Ordenamos de menor a mayor
    });

  };

  //STYLES
  const cardHeaderSx = {
    padding: 1,
    outline: theme => `1px solid ${theme.palette.primary.main}`,
    '& .MuiTypography-root': {
      maxWidth: 350,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      color: 'primary.main',
      fontWeight: 'bold'
    }
  };

  const generateSpec = (dataSpecie) => ({
    $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
    width: {
      step: 20
    },
    data: {
      values: dataSpecie
    },
    mark: 'bar',
    encoding: {
      x: {
        field: 'ageGroup',
        type: 'ordinal',
        title: null,
        sort: getAgeGroupSortedLegend(dataSpecie),
      },
      y: {
        field: 'area',
        type: 'quantitative',
        title: null,
        scale: {
          domain: [0, getMaxArea+5]
        }
      },
      color: {
        sort: getAgeGroupSortedLegend(dataSpecie),
        field: 'ageGroup',
        type: 'nominal',
        scale: {scheme: 'viridis'},
        legend: {
          direction: 'vertical',
          title: 'Edades',
          titlePadding: 20,
          symbolType: 'circle',
          rowPadding: 12,
          labelFontSize: 12,
          labelColor: 'grey',
          type: 'symbol',
          orient: 'right',
          columns: dataSpecie.length < 9 ? 1 : 2
        }
      },
      tooltip: [
        {field: 'label', title: 'Especie'},
        {field: 'area', title: 'Superficie (Ha)'},
        {field: 'age', title: 'Edad media (años)'},
        {field: 'ageGroup', title: 'Grupo de edad'}
      ],
    },
    config: {
      scale: {bandPadding: 0.5, bandPaddingOuter: 2, color: {scheme: 'blues'}},
      axisX: {
        ticks: false,
        labels: false
      },
      axisY: {
        labelPadding: 4,
        /* eslint-disable */ // esto no se puede quitar, porque no acepta que le ponga comillas simples
        labelExpr: "datum.value + ' (Ha)'",
      }
    }
  });

  return <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, }}>
    {
      data.map((dataSpecie, index) => {
        const valor = generateSpec(dataSpecie);
        return <Card key={index} sx={{flexGrow: 1}}>
      <CardHeader
        sx={cardHeaderSx}
        avatar ={ <Avatar sx={{bgcolor: 'common.white'}} alt={dataSpecie[0].label} ><ParkIcon sx={{color: 'primary.main'}}/> </Avatar>}
        title={dataSpecie[0].label.startsWith('Mixta') ? `${dataSpecie[0].label.split(' ')[0].toUpperCase()} :` : dataSpecie[0].label.toUpperCase()}
        subheader={dataSpecie[0].label.startsWith('Mixta') ? dataSpecie[0].label.slice(6) : null}
      />
      <CardContent>
        {
          data.length !== 0 && <VegaLite spec={valor} data={dataSpecie} actions={false}/>
        }
      </CardContent>
    </Card>;
      })}
  </Box>;
};


AreaAgePerSpecieIndicator.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    area: PropTypes.number.isRequired,
    age: PropTypes.number.isRequired,
    ageGroup: PropTypes.string.isRequired
  })))
};

export default AreaAgePerSpecieIndicator;
