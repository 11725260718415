import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const SubTitle = ({title, children}) => {
  return <Box mt={2} mb={1}>
    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
      <Typography
        variant="subtitle2"
        sx={{fontWeight: 'bold', color: 'primary.main'}}
      >
        {title}
      </Typography>
      {children}
    </Box>
  </Box>;
};

SubTitle.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element
};

export default SubTitle;