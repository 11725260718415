class Intervention {
  constructor(intervention) {
    this.id = intervention.id;
    this.batch = intervention.batch;
    this.intervention_zone = intervention.intervention_zone;
    this.type = intervention.type;
    this.uom = intervention.uom;
    this.mid_value = intervention.mid_value;
    this.planned_start_year = parseInt(intervention.planned_start_year) || null;
    this.planned_end_year = parseInt(intervention.planned_end_year) || null;
    this.planned_invest = parseFloat(intervention.planned_invest) || 0;
    this.planned_income = parseFloat(intervention.planned_income) || 0;
    this.planned_assigned = intervention.planned_assigned;
    this.priority = intervention.priority;
    this.geoua = intervention.geoua;
    this.never_do = intervention.never_do;
    this.done = intervention.done;
    this.geometry = intervention.geometry;
    this.categories = intervention.categories;
    this.categoryGroups = intervention.categoryGroups;
    this.is_annual_planned = intervention.is_annual_planned;
    this.who_made_it = intervention.who_made_it;
    this.comments = intervention.comments;
    this.stratum = intervention.stratum;
    this.files = intervention.files;
    this.executions = intervention.executions;
  }

  toApiRequest() {
    return {
      ...this,
      batch: this.batch.id,
      type: this.type.id,
    };
  }
  
}
export default Intervention;

