import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

//MUI
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//BIKENTA
import UploadFile from '../../UploadFile';

//UTILS
import {grey} from '@mui/material/colors';
import {FILE_CONTENT_TYPES, FILE_TYPES, ROLES_LABEL} from '../../../config';
import {roles} from '../../proptypes/roles';
import {commoners} from '../../proptypes/commoners';
import {fileDetail} from '../../proptypes/fileDetail';

const DocumentationPanel = ({
  commoners,
  files,
  fileDetail,
  isOffline, 
  role,
  rolesList,
  onUploadFile, 
  onDeleteFile, 
  onDownloadFile,
  onFileDetailOpen
}) => {
  const images = useMemo(() => files ? files.filter(file => file.content_type === FILE_CONTENT_TYPES.IMAGE_JPEG || file.content_type === FILE_CONTENT_TYPES.IMAGE_PNG) : [], [files]);
  const documents = useMemo(() => files ? files.filter(file => file.content_type === FILE_CONTENT_TYPES.DOCUMENT) : [], [files]);

  const editablePlanWrapper = {
    border: `1px solid ${grey[400]}`,
    borderRadius: 0.5,
    mt: 1.25,
  };
  const titleStyle = {
    fontWeight: 'bold'
  };
  const accordionEditable = {
    backgroundColor: 'white',
  };

  const uploadFileComponent = (fileType, files) => <UploadFile
    commoners={commoners}
    fileDetail={fileDetail}
    fileType={fileType}
    files={files}
    isOffline={isOffline}
    role={role}
    rolesList={rolesList}
    onDeleteFile={onDeleteFile}
    onDownloadFile={onDownloadFile}
    onUploadFile={onUploadFile}
    onFileDetailOpen={onFileDetailOpen}
  />;

  return <Box sx={editablePlanWrapper}>
    <Accordion sx={accordionEditable} elevation={0} disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>} id={'pestanaDocumentacion'}>
        <Typography color="secondary" sx={titleStyle}>DOCUMENTACIÓN</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column">
          {uploadFileComponent(FILE_TYPES.DOCUMENT, documents)}
        </Box>
        <Box display="flex" flexDirection="column">
          {uploadFileComponent(FILE_TYPES.IMAGE, images)}
        </Box>
      </AccordionDetails>
    </Accordion>
  </Box>;
};

DocumentationPanel.propTypes = {
  commoners: commoners,
  isOffline: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    content_type: PropTypes.string,
    extension: PropTypes.string,
    download_url: PropTypes.string,
    thumbnail_url: PropTypes.string
  })).isRequired,
  fileDetail: fileDetail,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)).isRequired,
  rolesList: PropTypes.arrayOf(roles),
  onUploadFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  onDownloadFile: PropTypes.func.isRequired,
  onFileDetailOpen: PropTypes.func,
};

export default DocumentationPanel;