import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import CommunityCard from '../Cards/CommunityCard';
import Hidden from '@mui/material/Hidden';
import Stack from '@mui/material/Stack';
import Carousel from '../Carousel';

const defaultCommunityImage = 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Montes_Aquilanos_II.jpg/240px-Montes_Aquilanos_II.jpg';

//STYLES
const carouselHorizontal = {
  zIndex: 40,
  position: 'absolute',
  bottom: 0,
  left: -34,
  right: 0
};
const scrollVertical = {
  zIndex: 40,
  mt: 6,
  bottom: 0,
  position: 'absolute',
  left: 0,
  right: 0,
  width: '230px',
  height: '100%',
  overflow: 'auto',
  pt: '8px'
};
const communityStyle = {
  display: 'inline-block',
  ml: {
    xs: 5,
    lg: 1
  },
  mb: {
    xs: 8,
    lg: 1
  }
};

const CommunitiesGroup = ({communities, communityId, onCommunitySelect, onCommunityPreselect, onCommunityCardHover}) => {
  const handleSelectCommunity = (id) => onCommunitySelect(id);
  const index = useMemo(() => {
    let newIndex = 0;
    if (communities && communityId) {
      if (communityId) {
        newIndex = communities.findIndex(com => com.id === communityId);
      }
    }
    return newIndex;
  }, [communities, communityId]);

  const handleIndexChange = (idx) => {
    onCommunityPreselect(communities[idx]?.id);
  };

  const communityCards = useMemo(() => {
    return (communities.map(
      community => <Box key={community.id} sx={communityStyle}>
        <CommunityCard
          id={community.id}
          key={community.id}
          get_certification_type_display={community.get_certification_type_display}
          get_status_display={community.get_status_display}
          image={community.card_image || defaultCommunityImage}
          owner={community.owner}
          onClick={() => handleSelectCommunity(community.id)}
          onHover={onCommunityCardHover}
          province={community.province}
          status={community.status}/>
      </Box>
    ));
  }, [communities]);

  return useMemo(() => {
    return <>
      <Hidden lgUp>{/*MOBILE*/}
        <Stack sx={carouselHorizontal}>
          <Carousel
            activeIndex={index}
            onActiveIndexChange={handleIndexChange}
          >
            {communityCards}
          </Carousel>
        </Stack>
      </Hidden>
      <Hidden lgDown>{/*DESKTOP*/}
        <Stack id="community-carousel" direction="column" sx={scrollVertical}>
          {communityCards}
        </Stack>
      </Hidden>
    </>;
  }, [communities]);
};

CommunitiesGroup.propTypes = {
  communities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      get_certification_type_display: PropTypes.string,
      get_status_display: PropTypes.string.isRequired,
      card_image: PropTypes.string,
      owner: PropTypes.string.isRequired,
      province: PropTypes.string,
      status: PropTypes.string.isRequired
    })
  ).isRequired,
  onCommunitySelect: PropTypes.func.isRequired,
  onCommunityCardHover: PropTypes.func.isRequired,
  onCommunityPreselect: PropTypes.func,
  communityId: PropTypes.number
};

export default CommunitiesGroup;