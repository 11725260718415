import React, {useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

//BIKENTA
import BiomasSection from './BiomasSection';
import MassDescription from './MassDescription';
import PerformanceTable from './PerformanceTable';
import AbsorptionPlanSeccion from './AbsorptionPlanSection';

//UTILS
import {MACHINING_DIFFICULTY, SPECIES_INCLUDED_BIOMASCO2} from '../../../../config';
import round from '../../../../utils/round';
import EditableData from './EditableData';
import Typography from '@mui/material/Typography';

//STYLES
const containerSx = {
  display: 'flex',
  flexDirection: 'column',
  margin: '10px',
  width: '98%'
};

const BiomasCO2Form = ({
  selectedInterventionZone,
  absorptionBaseLine,
  absorptionProject,
  isOffline,
  onBiomasCO2Close,
  onCalculateCO2
}) => {
  
  const {id, area, species} = selectedInterventionZone;

  const getXorforId = (bbddId) => SPECIES_INCLUDED_BIOMASCO2.find(species => species.bbddId === bbddId)?.xorforId;

  const initialCommonParams = {
    year_data: new Date().getFullYear(),
    rodal_id: selectedInterventionZone.id,
    species_id: getXorforId(species[0].id),
    area: area,
    number_per_ha: Math.trunc(species[0].existences.treeFeet),
    dominant_height: Math.trunc(species[0].existences.height),
    basimetric_area: species[0].existences.basometricArea,
    site_index: 0,
    wildfire_risk: 'Bajo',
    machining_difficulty_id: MACHINING_DIFFICULTY[0].id,
    initial_year: new Date().getFullYear(),
    final_year: new Date().getFullYear() + 1,
    initial_age: species[0].age,
  };

  const initialBaseLineParams = {
    type: 'BASELINE',
    has_weeding: false,
    has_pruning: false,
    commercial_purpose: 'PAPER'
  };

  const initialProjectParams = {
    type: 'PROJECT',
    has_weeding: false,
    has_pruning: false,
    commercial_purpose: 'PAPER'
  };

  const [commonParams, setCommonParams] = useState(initialCommonParams);
  const [baseLine, setBaseLine] = useState(initialBaseLineParams);
  const [project, setProject] = useState(initialProjectParams);

  const paramsToApi = {
    //area: commonParams.area,
    basimetric_area: commonParams.basimetric_area,
    dominant_height: commonParams.dominant_height,
    initial_year: commonParams.initial_year,
    machining_difficulty_id: commonParams.machining_difficulty_id,
    number_per_ha: commonParams.number_per_ha,
    rodal_id: commonParams.rodal_id,
    site_index: commonParams.site_index,
    species_id: commonParams.species_id,
    time_frame: commonParams.final_year - commonParams.initial_year,
    wildfire_risk: commonParams.wildfire_risk,
    year_data: commonParams.year_data,
    initial_age: commonParams.initial_age
  };

  const baseLineToApi = {...paramsToApi, ...baseLine};

  const handleCalculateBaseLine = () => onCalculateCO2(baseLineToApi);
  const handleCalculateProject = () => onCalculateCO2({...paramsToApi, ...project});

  const handleBiomasCO2Close = () => {
    setBaseLine(initialBaseLineParams);
    setProject(initialProjectParams);
    onBiomasCO2Close();
  };

  const hasRequiredValues = commonParams.dominant_height && commonParams.number_per_ha;

  return <Box sx={containerSx}>
    {/*DESCRIPCIÓN DE LA MASA----------------------------------------------------------------------------------------*/}
    <BiomasSection number={1} label='Descripción de la masa'
      aditionalProperty={
        <EditableData onChange={(value) => setCommonParams({...commonParams, year_data: value})} label='Año de captura de datos' value={commonParams.year_data} sx={{ml: 4, mb: 1}} max={new Date().getFullYear()}/>
      }>
      <>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 4}}>
          <MassDescription
            id_rodal={id}
            species_id={commonParams.species_id.toString()}
            age={commonParams.initial_age}
            area={round(commonParams.area)}
            number_per_ha={Math.trunc(commonParams.number_per_ha)}
            dominant_height={commonParams.dominant_height}
            basimetric_area={commonParams.basimetric_area}
            site_index={commonParams.site_index}
            wildfire_risk={'Bajo'}
            machining_difficulty_id={commonParams.machining_difficulty_id}
            onMassChange={(value) => setCommonParams({...commonParams, ...value})} />
        </Box>
      </>
    </BiomasSection>

    {/*PROYECTO DE ABSORCIÓN-----------------------------------------------------------------------------------------*/}
    <BiomasSection number={2} label='Proyecto de absorción' sx={{display: 'flex', flexDirection: 'column', mt: 2}}
      aditionalProperty={
        <Box sx={{display: 'flex', flexDirection: 'row', ml: 4, mb: 1, gap: 2}}>
          <EditableData onChange={(value) => setCommonParams({...commonParams, initial_year: value})} label='Año de inicio' value={commonParams.initial_year} min={commonParams.year_data} max={commonParams.final_year-1}/>
          <EditableData onChange={(value) => setCommonParams({...commonParams, final_year: value})} label='Año de fin' value={commonParams.final_year} min={commonParams.initial_year+1}/>
        </Box>
      }
    >
      <Box>
        <Typography variant='body2' sx={{fontWeight: 900, bgcolor: '#ededed', py: 0.5, px: 1, mb: 1}}>A. LINEA BASE</Typography>
        <PerformanceTable
          performances={{...commonParams, ...baseLine, type: 'BASELINE'}}
          disabledProjectButtons={!hasRequiredValues || isOffline}
          mode='BASELINE'
          onPerformancesChange={setBaseLine}
          onCalculateCO2={handleCalculateBaseLine}
        />
        <Typography variant='body2' sx={{fontWeight: 900, bgcolor: '#ededed', py: 0.5, px: 1, mb: 1}}>B. PROYECTO SELVÍCOLA</Typography>
        <PerformanceTable
          performances={{...commonParams, ...project, type: 'PROJECT'}}
          disabledProjectButtons={!absorptionBaseLine.prediction || isOffline}
          mode='PROJECT'
          onPerformancesChange={setProject}
          onCalculateCO2={handleCalculateProject}
        />
        
        <Box>
          <Typography variant='body2' sx={{fontWeight: 900, bgcolor: '#ededed', py: 0.5, px: 1, mb: 1}}>C. PLAN DE ABSORCIÓN</Typography>
          <AbsorptionPlanSeccion baselineData={absorptionBaseLine} projectData={absorptionProject}/>
        </Box>
      </Box>
    </BiomasSection>
    <Button variant='contained' color='secondary' sx={{ml: 4, my: 2, width: '250px'}} onClick={handleBiomasCO2Close}>SALIR</Button>
  </Box>;
};

export default BiomasCO2Form;

BiomasCO2Form.propTypes = {
  isOffline: PropTypes.bool,
  selectedInterventionZone: PropTypes.object.isRequired,
  absorptionBaseLine: PropTypes.object,
  absorptionProject: PropTypes.object,
  onBiomasCO2Close: PropTypes.func.isRequired,
  onCalculateCO2: PropTypes.func.isRequired
};