import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';

//MUI-ICONS
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import {ListItemIcon, MenuList} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';

//STYLES
const navigationStyle = {
  width: '100%',
};

const actionStyle = (disabled) => ({
  zIndex: 200,
  color: disabled ? 'grey.300' : undefined,
  '& .MuiBottomNavigationAction-label': {
    fontSize: '9px',
    '&.Mui-selected': {
      fontSize: '9px',
    }
  }
});

const BottomMenu = ({actions}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (action) => {
    setAnchorEl(null);
    action();
  };
  const handleMoreClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 2000}} elevation={3}>
    <BottomNavigation
      showLabels
      sx={navigationStyle}
    >
      {
        actions.filter((action, index) => index < 4).map(({id, content, label, disabled, action}) => {
          const actionStyleMemo = useMemo(() => actionStyle(disabled), [disabled]);
          return <BottomNavigationAction
            disabled={disabled}
            sx={actionStyleMemo}
            key={id}
            label={label}
            icon={content}
            onClick={action}
          />;
        })
      }
      <BottomNavigationAction
        label={<Typography variant='caption'>Más</Typography>}
        icon={<MoreVertIcon/>}
        onClick={handleMoreClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{zIndex: 2500}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <MenuList dense>
          {
            actions.filter((action, index) => index >= 4).map(({id, content, label, action}) => {
              return <MenuItem key={id} onClick={() => handleClick(action)} id={`bottom-menu-${id}-button`}>
                <ListItemIcon>{content}</ListItemIcon>
                <ListItemText>{label}</ListItemText>
              </MenuItem>;
            })
          }
        </MenuList>
      </Menu>

    </BottomNavigation>
  </Paper>;
};

BottomMenu.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    content: PropTypes.element,
    disabled: PropTypes.bool,
    action: PropTypes.func
  })),
  selectedActionIndex: PropTypes.string,
  onActionClick: PropTypes.func
};

export default BottomMenu;