import * as React from 'react';

function SvgIcPassword1X(props) {
  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M10.25 4.75c.525 0 .963.175 1.313.612l.612.613 1.313-1.137-.613-.7C12.175 3.438 11.213 3 10.25 3a3.51 3.51 0 00-3.5 3.5v1.313A5.346 5.346 0 005 11.75C5 14.637 7.362 17 10.25 17c2.887 0 5.25-2.363 5.25-5.25S13.137 6.5 10.25 6.5c-.613 0-1.225.088-1.75.35V6.5c0-.963.787-1.75 1.75-1.75zm0 4.375c.963 0 1.75.787 1.75 1.75 0 .613-.35 1.225-.875 1.488v2.012h-1.75v-2.012c-.525-.263-.875-.875-.875-1.488 0-.963.787-1.75 1.75-1.75z"
          fill="#DAD9E2"
          fillRule="nonzero"
        />
        <path d="M0 0h20v20H0z" />
      </g>
    </svg>
  );
}

export default SvgIcPassword1X;
