import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import Carousel from '../../Carousel';
import Thumbnail from '../../Thumbnail';
import Box from '@mui/material/Box';

const ThumbnailCarousel = ({images, thumbnailHeight, isLoading, isDeletable, onDelete, onDownloadFile}) => {
  const [index, setIndex] = useState(images.id ? images.findIndex(image => image.id === image.id) : 0);
  const thumb = {
    width: (thumbnailHeight*4/3)+18,
    mr: 10
  };
  return useMemo(() => {
    return <Carousel activeIndex={index} onActiveIndexChange={setIndex} infinite={false}>
      {
        images.map(image =>
          <Box key={image.id} sx={thumb}>
            <Thumbnail
              thumbnail_url={image.thumbnail_url}
              id={image.id}
              name={image.name}
              content_type={image.content_type}
              thumbnailHeight={thumbnailHeight}
              isDeletable={isDeletable}
              isLoading={isLoading}
              onDelete={onDelete}
              onDownloadFile={onDownloadFile}
            />
          </Box>
        )
      }
    </Carousel>;
  }, [images]);
};

ThumbnailCarousel.propTypes = {
  images: PropTypes.arrayOf (PropTypes.shape({
    id: PropTypes.number,
    thumbnail_url: PropTypes.string
  })).isRequired,
  thumbnailHeight: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
  onDownloadFile: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDeletable: PropTypes.bool
};

Thumbnail.defaultProps = {
  thumbnailHeight: 60,
  isLoading: false,
  isDeletable: false,
};

export default ThumbnailCarousel;