import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


//MUI-ICONS
import ForestIcon from '@mui/icons-material/Forest';

const TotalIndicator = ({title, value}) => {
  return <Card sx={{bgcolor: 'primary.main', minHeight: 45, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: 1}}>
    <Stack direction='row' alignItems='center' sx={{flexGrow: 1}}>
      <ForestIcon sx={{color: 'white', width: 30, ml: 1.5}} fontSize='medium'/>
      <Typography variant='body1' sx={{mx:1, mt: 1, fontWeight: 'bold', color: '#ffffff', textTransform: 'uppercase'}}>{title}</Typography>
    </Stack>
    {
      value && <Typography variant='h5' sx={{ px: 1.5, color: '#ffffff', fontWeight: 'bold', minWidth: value ? 120 : 0, textAlign: 'right' }}>{value}</Typography>
    }
  </Card>;
};

TotalIndicator.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default TotalIndicator;