import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//BIKENTA
import DialogPanel from '../../../DialogPanel';

const NeverDoExecutionAlert = ({
  isOpenNeverDoExecutionMenu, 
  onCancelNeverDoExecution, 
  onClose, 
  onNeverDoAccept
}) => {
  return <DialogPanel isOpen={isOpenNeverDoExecutionMenu} label="INTERVENCIÓN NO EJECUTABLE" onClose={onClose}>
    <Typography>¿Estás seguro de que no se ejecutará NUNCA esta intervención?</Typography>
    <Typography gutterBottom>No se podrá deshacer.</Typography>
    {/*<Typography sx={{fontWeight: 900}}>
      Si quieres incluir algún comentario, hazlo ANTES de marcar la Intervención como No ejecutable.
    </Typography>*/}
    <Stack direction='row' justifyContent='flex-end' sx={{mt:2}}>
      <Button color="secondary" onClick={onCancelNeverDoExecution}>CANCELAR</Button>
      <Button variant="contained" color="secondary" onClick={onNeverDoAccept}>ACEPTAR</Button>
    </Stack>
  </DialogPanel>;
};

NeverDoExecutionAlert.propTypes = {
  isOpenNeverDoExecutionMenu: PropTypes.bool,
  onCancelNeverDoExecution: PropTypes.func,
  onClose: PropTypes.func,
  onNeverDoAccept: PropTypes.func
};

NeverDoExecutionAlert.defaultProps = {
  isOpenNeverDoMenu: false
};

export default NeverDoExecutionAlert;