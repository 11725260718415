import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import {specie} from '../../proptypes/specie';
import TextField from '@mui/material/TextField';
const InputSpecies = ({species, onSpecieChange}) => {

  const autocompleteSx = {
    width: 300,
    '& + .MuiAutocomplete-popper': {
      zIndex: 10000,
    }
  };

  return <Box display='flex' flexDirection='column' justifyContent='flex-start'>
    <Autocomplete
      disablePortal
      options={species.map(specie => ({key: specie.key, label: specie.label.es}))}
      sx={autocompleteSx}
      onChange={(e, value) => onSpecieChange(value)}
      renderInput={(params) => <TextField {...params} label="Especie"/>}
    />
  </Box>;
};

InputSpecies.propTypes = {
  species: PropTypes.arrayOf(specie).isRequired,
  onSpecieChange: PropTypes.func.isRequired
};

export default InputSpecies;
