import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

const CommentsInput = ({comments, isOffline, onCommentsChange, disabled}) => {

  const [editingComments, setEditingComments] = useState(comments);
  const subTitleColor = (isOffline || disabled) ? 'grey.500' : 'secondary';

  const handleCommentsUpdate = () => onCommentsChange({comments: editingComments});

  return <Box display='flex' flexDirection='column'>
    <Typography variant='overline' component='p' color={subTitleColor} 
      sx={{fontWeight: 'bolder', mb: 0.5, ml: 0.5}}>COMENTARIOS</Typography>
    <TextField
      inputProps={{onBlur: handleCommentsUpdate}}
      sx={{mb: 2, pl: 2}}
      fullWidth
      multiline
      rows={2}
      type='text'
      InputLabelProps={{shrink: true}}
      variant='outlined'
      value={editingComments}
      onChange={(e) => setEditingComments(e.target.value)}
      disabled={isOffline || disabled}
    />
  </Box>;
};

CommentsInput.propTypes = {
  comments: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  onCommentsChange: PropTypes.func
};

export default CommentsInput;
