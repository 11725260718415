import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';

//BIKENTA
import AreaIndicator from './AreaIndicator';

//UTILS
import {CATEGORY_GROUPS} from '../../config';

//STYLE
const getStyle = (id) => CATEGORY_GROUPS.find(cat => cat.id === id);

const AreaGroup = ({data}) => {
  return <Box sx={{display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2}}>
    {
      data.categoryGroups.map(group => <AreaIndicator
        key={group.id}
        label={group.label}
        style={getStyle(group.id)}
        sublabels={group.categories}
      />
      )
    }
  </Box>;
};

AreaGroup.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number,
    categoryGroups: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      categories: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired
      }))
    }))
  })
};

export default AreaGroup;