import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

//UTILS
import {EXECUTION_TYPES} from '../../../../config';
import {execution} from '../../../proptypes/execution';

//STYLES
const completeIconStyle = {
  color: 'secondary.main'
};

const completeTextStyle = {
  ml: 1,
  color: 'common.black'
};

const partialIconStyle = {
  color: 'secondary.main'
};

const partialTextStyle = {
  ml: 1,
  color: 'common.black'
};

const ToggleExecutionType = ({execution, hasPartialExecution, isOffline, multiselect, onExecutionTypeChange}) => {
  
  const subTitleColor = isOffline ? 'grey.500' : 'secondary';
    
  const handleExecutionType = (e, type) => onExecutionTypeChange(type);
  
  return <Box display='flex' flexDirection='column'>
    <Typography variant='overline' component='p' color={subTitleColor}
      sx={{fontWeight: 'bolder', mb: 0.5, ml: 0.5}}>TIPO DE EJECUCIÓN*</Typography>
    <ToggleButtonGroup
      value={execution.geom === null ? EXECUTION_TYPES.COMPLETE.id : EXECUTION_TYPES.PARTIAL.id }
      exclusive
      onChange={handleExecutionType}
      size='medium'
      sx={{mb: 2, ml: 2}}
    >
      {
        !hasPartialExecution && <ToggleButton value={EXECUTION_TYPES.COMPLETE.id}>
          <>
            <CheckCircleIcon sx={completeIconStyle}/>
            <Typography variant='body2' sx={completeTextStyle}>{EXECUTION_TYPES.COMPLETE.label}</Typography>
          </>
        </ToggleButton>
      }

      {
        !multiselect && <ToggleButton value={EXECUTION_TYPES.PARTIAL.id}>
          <>
            <CheckCircleOutlineIcon sx={partialIconStyle}/>
            <Typography variant='body2' sx={partialTextStyle}>{EXECUTION_TYPES.PARTIAL.label}</Typography>
          </>
        </ToggleButton>
      }
    </ToggleButtonGroup>
  </Box>;
};

ToggleExecutionType.propTypes = {
  execution: execution,
  hasPartialExecution: PropTypes.bool,
  isOffline: PropTypes.bool.isRequired,
  multiselect: PropTypes.bool.isRequired,
  onExecutionTypeChange: PropTypes.func.isRequired
};

export default ToggleExecutionType;

