import React from 'react';
import PropTypes from 'prop-types';

//MUI
import {useTheme} from '@mui/styles';
import {useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {DataGrid, GridActionsCellItem, GridToolbarContainer, esES} from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import MessageIcon from '@mui/icons-material/Message';

//UTILS
import {ACCOUNT_STATES, ROLES_LABEL} from '../../config';
import {hasEditPermissions} from '../../modules/useCases';
import {useMemo} from 'react';
import {commoners} from '../proptypes/commoners';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment/moment';

//STYLES
const listStyle = {
  fontSize: '14px',
  boxShadow: 2,
  '& .MuiDataGrid-columnHeaders': {
    border: theme => `1.5px solid ${theme.palette.primary.main}`,
    bgcolor: '#E3E9D5',
    fontSize: '14px'
  },
  '&.MuiPaper-root': {
    border: 2,
    zIndex: 10000
  },
  '&.MuiDataGridPanel-root': {
    border: 2,
    zIndex: 10000
  }
};

const INVITATION_STATUS = {
  CREATED: 'created',
  SENT: 'sent',
  SMS_SENDING_FAILED: 'sms_sending_failed',
  COMPLETED: 'completed'
};

export default function CommonersList({
  board_members,
  commoners,
  role,
  onCommonerAdd,
  onCommonerActivate,
  onCommonerDeactivate,
  onCommonerDelete,
  onCommonerInvite,
  onCommonersListClose,
  onCommonerUpdate
}) {
  const theme = useTheme();
  const widescreen = useMediaQuery(theme.breakpoints.up('md'), {noSsr: true});
  const board_members_label = useMemo(() => board_members.map(({key, label}) => ({value: key, label: label})), [board_members]);

  const button = {
    backgroundColor: 'secondary.main',
    color: 'primary.contrastText',
    mb: widescreen ? undefined : 1,
    ml: widescreen ? undefined : 1,
    mr: widescreen ? 1 : 1
  };

  const commonersFormatedToList = useMemo(() => commoners.map(commoner => (
    {
      ...commoner,
      status: commoner.invitations.length > 0 ? commoner.invitations[commoner.invitations.length-1].status : null,
      status_label: commoner.invitations.length > 0 ? commoner.invitations[commoner.invitations.length-1].get_status_display : null,
      time_status: commoner.invitations.length > 0 ? commoner.invitations[commoner.invitations.length-1].sent_at.slice(0,10) : null,
    }
  )), [commoners]);

  const HeaderList = () => {
    const handleAdd = () => onCommonerAdd();
    return <GridToolbarContainer
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        bgcolor: 'primary.main',
        color: 'primary.contrastText',
      }}
    >
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: {sm: '50%', lg: '85%'}}}>
        <Typography sx={{m:1, fontWeight: 900}} variant='h5' id='commoners-list-title'>LISTADO DE COMUNEROS</Typography>
        {
          !widescreen && <IconButton onClick={onCommonersListClose} sx={{color: 'common.white'}}>
            <CloseIcon/>
          </IconButton>
        }
      </Box>
      <Button color="secondary" sx={button} startIcon={<AddIcon/>} onClick={handleAdd}>AÑADIR COMUNERO</Button>
    </GridToolbarContainer>;
  };

  const handleDeleteClick = (id) => (e) => {
    e.stopPropagation();
    onCommonerDelete(id);
  };

  const handleInviteClick = (id) => (e) => {
    const commonerToInvite = commoners.find(commoner => commoner.id === id);
    e.stopPropagation();
    onCommonerInvite(commonerToInvite.id);
  };

  const handleEditStop = (params, e) => {
    const commonerId = params.row.id;
    const paramToUpdate = params.field;
    const valueToUpdate = e.target.value;

    handleCommonerUpdate(commonerId, paramToUpdate, valueToUpdate);
  };

  const handleSingleSelectSelection = (value, params) => {
    const commonerId = params.row.id;
    const paramToUpdate = params.field;
    const valueToUpdate = board_members.find(({key}) => key === value)?.id;

    handleCommonerUpdate(commonerId, paramToUpdate, valueToUpdate);
    return board_members.find(({id}) => id === valueToUpdate)?.key;
  };

  const handleCommonerUpdate = (commonerId, param, value) => {
    const commonerToUpdate = commoners.find(user => user.id === commonerId);

    if (value && commonerToUpdate[param] !== value) {
      onCommonerUpdate(
        {
          ...commonerToUpdate,
          position: board_members.find(({key}) => key === commonerToUpdate.position)?.id,
          [param]: (param === 'deactivation_date' || param === 'activation_date') ? value.slice(0, 10) : value,
        }
      );
    }
  };

  const handleActivation = (id) => (e) => {
    e.stopPropagation();
    onCommonerActivate(id);
  };
  const handleDeactivation = (id) => (e) => {
    e.stopPropagation();
    onCommonerDeactivate(id);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 60, flex: widescreen && 0, editable: false, align: 'center', headerAlign: 'center' },
    { field: 'name', headerName: 'NOMBRE', width: 180, flex: widescreen && 1, editable: true },
    { field: 'last_name', headerName: 'APELLIDOS', width: 180, flex: widescreen && 1, editable: true },
    { field: 'place', headerName: 'LUGAR', width: 150, flex: widescreen && 1.25, editable: true },
    { field: 'phone_number', headerName: 'TELÉFONO', width: 180, flex: widescreen &&  1, editable: true },
    { field: 'email', headerName: 'E-MAIL', width: 200, flex: widescreen &&  1.25, editable: true },
    {
      field: 'position',
      headerName: 'CARGO',
      width: 150,
      type: 'singleSelect',
      valueOptions: board_members_label,
      flex: widescreen && 1,
      editable: true,
      valueGetter: ({value}) => board_members.find(({key}) => key === value)?.label,
      valueParser: handleSingleSelectSelection
    },
    {
      field: 'activation_date',
      type: 'dateTime',
      headerName: 'FECHA ALTA',
      width: 120,
      editable: true,
      align: 'right',
      preProcessEditCellProps: (params) => {
        return params.row.account_status !== ACCOUNT_STATES.disabled ? undefined : params.props.value;
      },
      valueFormatter: param => moment(param.value).format('DD-MM-YYYY')

    },
    { field: 'deactivation_date',
      type: 'dateTime',
      headerName: 'FECHA BAJA',
      width: 120,
      editable: true,
      align: 'right',
      preProcessEditCellProps: (params) => {
        return params.row.account_status !== ACCOUNT_STATES.activated ? undefined : params.props.value;
      },
      valueFormatter: param => param.value && moment(param.value).format('DD-MM-YYYY')
    },
    {
      field: 'REGISTRATION_ACTIONS',
      type: 'actions',
      headerName: 'ACTIVAR/DESACTIVAR',
      width: 150,
      align: 'left',
      flex: widescreen && 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const comm = commoners.find(commoner => commoner.id === id);
        return [
          comm.account_status === ACCOUNT_STATES.disabled || comm.account_status === ACCOUNT_STATES.activated ?
            <GridActionsCellItem
              key={comm.account_status === ACCOUNT_STATES.disabled ? 'activate' : 'deactivate'}
              icon={
                comm.account_status === ACCOUNT_STATES.disabled ?
                  <><AddCircleIcon color='secondary'/><Typography variant='body2' m={1}> Activar</Typography></> :
                  <><CancelIcon color='error'/><Typography variant='body2' m={1}> Desactivar</Typography></>
              }
              label={comm.account_status === ACCOUNT_STATES.disabled ? 'Activar' : 'Desactivar'}
              className='registrationIcon'
              onClick={comm.account_status === ACCOUNT_STATES.disabled ? handleActivation(id) : handleDeactivation(id)}
              color='inherit'
              showInMenu={false}
              disabled={!hasEditPermissions(role)}
            /> : <></>
        ];
      },
    },
    { field: 'invitations',
      headerName: 'ESTADO',
      width: 100,
      flex: widescreen &&  1,
      editable: false,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => {
        return (
          params.row.time_status && <Tooltip title={params.row.time_status} placement='left'>
            <Typography
              sx={{
                color: params.row.status === INVITATION_STATUS.SMS_SENDING_FAILED ? '#d70f0f' :
                  params.row.status === INVITATION_STATUS.COMPLETED ?  '#107540' : undefined,
                fontWeight: (params.row.status === INVITATION_STATUS.SMS_SENDING_FAILED || params.row.status === INVITATION_STATUS.COMPLETED) ? 'bold' : undefined
              }}
            >
              {params.row.status_label}
            </Typography>
          </Tooltip>
        );
      }
    },
    {
      field: 'USER_ACTIONS',
      type: 'actions',
      headerName: '',
      width: 70,
      flex: widescreen && 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <Tooltip key='invitar' title='Invitar' placement='bottom'>
            <GridActionsCellItem
              key='edit'
              icon={<MessageIcon color={hasEditPermissions(role) ? 'secondary' : 'disabled'}/>}
              label='Edit'
              className='editIcon'
              onClick={handleInviteClick(id)}
              color='inherit'
              showInMenu={false}
              disabled={!hasEditPermissions(role)}
            />
          </Tooltip>,
          <Tooltip key='eliminar' title='Eliminar' placement='bottom'>
            <GridActionsCellItem
              key='delete'
              icon={<DeleteIcon color={hasEditPermissions(role) ? 'secondary' : 'disabled'}/>}
              label='Delete'
              onClick={handleDeleteClick(id)}
              color='inherit'
              showInMenu={false}
              disabled={!hasEditPermissions(role)}
            />
          </Tooltip>
        ];
      },
    }
  ];

  return (
    <div style={{ height: '85vh', width: '100%' }}>
      {commoners && <DataGrid
        density='compact'
        sx={listStyle}
        rows={commonersFormatedToList}
        columns={columns}
        onCellEditStop={handleEditStop}
        experimentalFeatures={{newEditingApi: true}}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: HeaderList,
        }}
      />}
      {
        widescreen && <Box display="flex" justifyContent="flex-end" alignItems="center" m={2}>
          <Button variant="contained" size="medium" sx={button} onClick={onCommonersListClose}>SALIR</Button>
        </Box>
      }
    </div>
  );
}


CommonersList.propTypes = {
  board_members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    label: PropTypes.string,
  })),
  commoners: commoners,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)),
  selectedCommunity: PropTypes.object,
  onCommonerAdd: PropTypes.func,
  onCommonerActivate: PropTypes.func,
  onCommonerDeactivate: PropTypes.func,
  onCommonerDelete: PropTypes.func,
  onCommonerInvite: PropTypes.func,
  onCommonersListClose: PropTypes.func,
  onCommonerUpdate: PropTypes.func
};