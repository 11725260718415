import PropTypes from 'prop-types';

export const fileDetail = PropTypes.shape({
  basename: PropTypes.string,
  confirmations: PropTypes.arrayOf(PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
      email: PropTypes.string
    }),
    read_at: PropTypes.string
  })),
  content_type: PropTypes.string,
  created_by: PropTypes.shape({
    company: PropTypes.string,
    email: PropTypes.string,
    groups: PropTypes.array,
    id: PropTypes.number,
    roles: PropTypes.array,
    username: PropTypes.string
  }),
  created_on: PropTypes.string,
  download_url: PropTypes.string,
  extension: PropTypes.string,
  file: PropTypes.string,
  formatted_size: PropTypes.string,
  get_permission_display: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  permission: PropTypes.string,
  readonly: PropTypes.bool,
  recipients: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    role: PropTypes.shape({
      id: PropTypes.number,
      localized_name: PropTypes.string,
      name: PropTypes.string,
      number: PropTypes.number
    }),
    user: PropTypes.shape({
      email: PropTypes.string,
      id: PropTypes.number,
      username: PropTypes.string
    })
  })),
  roles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    localized_name: PropTypes.string,
    name: PropTypes.string,
    number: PropTypes.number
  })),
  size: PropTypes.number,
  thumbnail_url: PropTypes.string
});