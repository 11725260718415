import PropTypes from 'prop-types';

export const prospectingPoints = PropTypes.arrayOf(PropTypes.shape({
  type: PropTypes.string,
  id: PropTypes.number,
  geometry: PropTypes.shape({
    type: PropTypes.string,
    coordinates: PropTypes.arrayOf(PropTypes.number)
  }),
  properties: PropTypes.object
}));