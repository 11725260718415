import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

//MUI
import {useTheme} from '@mui/styles';
import {useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {DataGrid, GridActionsCellItem, GridToolbarContainer, esES} from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

//UTILS
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';


//STYLES
const listStyle = {
  fontSize: '14px',
  boxShadow: 2,
  '& .MuiDataGrid-columnHeaders': {
    border: theme => `1.5px solid ${theme.palette.primary.main}`,
    bgcolor: '#E3E9D5',
    fontSize: '14px'
  },
  '&.MuiPaper-root': {
    border: 2,
    zIndex: 10000
  },
  '&.MuiDataGridPanel-root': {
    border: 2,
    zIndex: 10000
  }
};

export default function AccountBook({
  accountEntries,
  onAccountEntryAdd,
  onAccountBookClose,
  onAccountEntryDelete,
  onAccountEntryUpdate
}) {
  const theme = useTheme();
  const widescreen = useMediaQuery(theme.breakpoints.up('md'), {noSsr: true});
  const sortModel = [
    {
      field: 'date',
      sort: 'asc',
    },
  ];
  
  const button = {
    backgroundColor: 'secondary.main',
    color: 'primary.contrastText',
    mb: widescreen ? undefined : 1,
    ml: widescreen ? undefined : 1,
    mr: widescreen ? 1 : 1
  };

  const accountEntriesOrderByDate = useMemo (() => accountEntries.sort(
    (a, b) => new Date (a.date) - new Date (b.date)
  ), [accountEntries]);

  const accountEntriesWithTotal = useMemo(() => accountEntriesOrderByDate.reduce((acumulator, entry, index) => {
    let previousTotal = 0;
    if (index > 0) {
      previousTotal = acumulator[index - 1].total;
    }

    const total = entry.payment - entry.deposit + previousTotal;
    entry.total = total;
    return [...acumulator, entry];
  }, []), [accountEntries]);
  
  const HeaderList = () => {

    const handleAdd = () => onAccountEntryAdd(
      {
        description: '',
        payment: 0.0,
        deposit: 0.0,
        date: moment().format('YYYY-MM-DD')
      }
    );

    return <GridToolbarContainer
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        bgcolor: 'primary.main',
        color: 'primary.contrastText',
      }}
    >
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: {sm: '50%', lg: '85%'}}}>
        <Typography sx={{m:1, fontWeight: 900}} variant='h5' id='accountBook-title'>LIBRO DE CUENTAS</Typography>
        {
          !widescreen && <IconButton onClick={onAccountBookClose} sx={{color: 'common.white'}}>
            <CloseIcon/>
          </IconButton>
        }
      </Box>
      <Button color="secondary" sx={button} startIcon={<AddIcon/>} onClick={handleAdd}>AÑADIR APUNTE</Button>
    </GridToolbarContainer>;
  };

  const handleDeleteClick = (id) => (e) => {
    e.stopPropagation();
    onAccountEntryDelete(id);
  };

  const handleEditStop = (params, e) => {
    const accountEntryId = params.row.id;
    const paramToUpdate = params.field;
    const valueToUpdate = e.target.value;
    handleAccountEntryUpdate(accountEntryId, paramToUpdate, valueToUpdate);
  };

  const handleAccountEntryUpdate = (accountEntryId, param, value) => {
    
    const entryToUpdate = accountEntries
      .find(entry => entry.id === accountEntryId);
    
    const formattedEntryToUpdate = {};
    const keysToUpdate = Object.keys(entryToUpdate).filter(key => key !== 'total');

    keysToUpdate.forEach(key => {
      formattedEntryToUpdate[key] = entryToUpdate[key];
    });

    if (value && entryToUpdate[param] !== value) {
      if (param === 'date'){
        onAccountEntryUpdate(
          {
            ...formattedEntryToUpdate,
            [param]: moment(value).format('DD-MM-YYYY'),
          }
        );
      } else {
        onAccountEntryUpdate(
          {
            ...formattedEntryToUpdate,
            [param]: value,
          }
        );
      }
      
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: widescreen ? 60 : 30, flex: widescreen && 0, editable: false, align: 'center', headerAlign: 'center', disableColumnMenu: true, sortable: false },
    { field: 'date',
      headerName: 'FECHA',
      type: 'date',
      align: 'right',
      width: widescreen ? 120 : 100,
      editable: true,
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: param => moment(param.value).format('DD-MM-YYYY')
    },
    { field: 'description', headerName: 'DESCRIPCIÓN', width: widescreen ? 280 : 175, flex: widescreen && 1.75, editable: true, disableColumnMenu: true, sortable: false },
    { field: 'payment', headerName: 'INGRESOS', type: 'number', width: widescreen ? 150 : 75, flex: widescreen && 0, editable: true, align: 'right', headerAlign: 'center', disableColumnMenu: true, sortable: false },
    { field: 'deposit', headerName: 'GASTOS', type: 'number', width: widescreen ? 150 : 75, flex: widescreen && 0, editable: true, align: 'right', headerAlign: 'center', disableColumnMenu: true, sortable: false },
    { field: 'total',
      headerName: 'TOTAL',
      type: 'number',
      width: widescreen ? 150 : 75,
      flex: widescreen && 0,
      editable: false,
      align: 'right',
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'ACCOUNT_BOOK_ACTIONS',
      type: 'actions',
      headerName: '',
      width: 60,
      flex: widescreen && 0.15,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <Tooltip key='eliminar' title='Eliminar' placement='bottom'>
            <GridActionsCellItem
              key='delete'
              icon={<DeleteIcon color='secondary'/>}
              label='Delete'
              onClick={handleDeleteClick(id)}
              color='inherit'
              showInMenu={false}
            />
          </Tooltip>
        ];
      },
    }
  ];

  return (
    <div style={{ height: '85vh', width: '100%' }}>
      {accountEntries && <DataGrid
        sortModel={sortModel}
        density='compact'
        sx={listStyle}
        rows={accountEntriesWithTotal}
        columns={columns}
        onCellEditStop={handleEditStop}
        experimentalFeatures={{newEditingApi: true}}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: HeaderList,
        }}
      />}
      {
        widescreen && <Box display="flex" justifyContent="flex-end" alignItems="center" m={2}>
          <Button variant="contained" size="medium" sx={button} onClick={onAccountBookClose}>SALIR</Button>
        </Box>
      }
    </div>
  );
}

AccountBook.propTypes = {
  accountEntries: PropTypes.array,
  onAccountEntryAdd: PropTypes.func,
  onAccountBookClose: PropTypes.func,
  onAccountEntryDelete: PropTypes.func,
  onAccountEntryUpdate: PropTypes.func
};