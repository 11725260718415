import round from '../../../utils/round';

const existencesAproveitamento = (interventionZones, options) => {

  if (options.totalUnfiltered === undefined) {
    throw new Error('Total interventions zones unfiltered not passed as parameter!!');
  }

  const formatUnfiltered = options.totalUnfiltered
    //filtra las interventionZones que tienen intervenciones de Aproveitamento (1)
    .filter(interventionZone => interventionZone.categoryGroups.includes(1))
    .map(iz => iz.species.map(specie => ({
      area: iz.area,
      ...specie
    }))).flat();

  const calculation = [];

  formatUnfiltered.forEach(specie => {
    const index = calculation.findIndex(el => el.id === specie.id);

    if (index === -1) {
      calculation.push({
        id: specie.id,
        quantity: 1,
        label: specie.label,
        existences: {
          treeFeet: round(specie.existences.treeFeet*specie.area),
          volumeWithBark: round(specie.existences.volumeWithBark*specie.area),
          volumeWithoutBark: round(specie.existences.volumeWithoutBark*specie.area),
        }
      });
    } else {
      calculation[index] = {
        id: specie.id,
        label: specie.label,
        quantity: calculation[index].quantity + 1,
        existences: {
          treeFeet: round(calculation[index].existences.treeFeet + (specie.existences.treeFeet*specie.area)),
          volumeWithBark: round(calculation[index].existences.volumeWithBark + (specie.existences.volumeWithBark*specie.area)),
          volumeWithoutBark: round(calculation[index].existences.volumeWithoutBark + (specie.existences.volumeWithoutBark*specie.area))
        }
      };
    }
  });

  return {
    species: calculation,
  };

};

export default existencesAproveitamento;