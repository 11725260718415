export const getCredentials = (state) => ({username: getUsername(state), token: getToken(state)});
export const getProfile = (state) => state.api.profile;
export const getToken = (state) => state.api.token;
export const getUsername = (state) => state.api.username;
export const isLoggedIn = (state) => !!state.api.token && !!state.api.profile;
export const getInterventionZoneDetail = (state) => state.api.interventionZoneDetail;
export const getBusiness = (state) => state.api.business;
export const getBusinessWithLocation = (state) => {
  if(state.api.businessWithLocation) {
    return {
      type: 'FeatureCollection',
      features:  state.api.businessWithLocation.features.filter(feature => feature.geometry)
    }; 
  } else {
    return {
      type: 'FeatureCollection',
      features: []
    };
  }
};
export const getSpecies = (state) => state.api.manifest?.filters[1].values;