import PropTypes from 'prop-types';

export const annualPlanSummary = PropTypes.shape({
  interventions: PropTypes.number,
  planned_income: PropTypes.number,
  planned_invest: PropTypes.number,
  done: PropTypes.number,
  executed_invest: PropTypes.number,
  executed_income: PropTypes.number
});
